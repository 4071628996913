import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Row, Col, DatePicker, Drawer, Divider, Modal } from 'antd';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import { _newLine, isValidDateForm, customDataFilesUpload, getFirstLetters } from '../../utils/helpers';
import DraggerFile from './DraggerFile';
import CurrentFiles from './CurrentFiles';
import { getDocumentCategoriesActive } from '../../redux/actions/DocumentCategories';
import { createDocument, updateDocument, getDocumentsByCategory } from '../../redux/actions/Documents';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

class DocumentForm extends Component {
    constructor() {
        super();
        this.state = {
            originalDocuments: [],
            signedDocuments: [],
            currentOriginalDocuments: [],
            currentSignedDocuments: [],
            isResetOriginalDocument: true,
            isResetSignedDocument: true,
            loadingForm: false,
            catLabel: null,
            category_id: null,
            serial_number: null,
            showDocumentNumber: true,
            selectedYear: null
        };
    }

    componentDidMount() {
        this.props.getDocumentCategoriesActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentData != this.props.currentData) {
            if (nextProps?.currentData?.original_documents && Array.isArray(nextProps?.currentData?.original_documents)) {
                this.setState({ currentOriginalDocuments: nextProps?.currentData?.original_documents })
            } else {
                this.setState({ currentOriginalDocuments: [] })
            }
            if (nextProps?.currentData?.signed_documents && Array.isArray(nextProps?.currentData?.signed_documents)) {
                this.setState({ currentSignedDocuments: nextProps?.currentData?.signed_documents })
            } else {
                this.setState({ currentSignedDocuments: [] })
            }
            this.setState({
                catLabel: nextProps?.currentData?.category?.name,
                category_id: nextProps?.currentData?.categories_id,
                serial_number: nextProps?.currentData?.serial,
                year: nextProps?.currentData?.year
            })
            if (nextProps?.currentData?.categories_id == 2) {
                this.setState({ showDocumentNumber: false });
            }
        }
    }

    async handleSave(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.yearMonth;

            values.issue_date = values.issue_date ? values.issue_date.format("YYYY-MM-DD") : null;
            values.expiry_date = values.expiry_date ? values.expiry_date.format("YYYY-MM-DD") : null;

            if (!err) {
                try {
                    this.setState({ loadingForm: true })
                    const { serial_number, selectedYear, originalDocuments, signedDocuments, currentOriginalDocuments, currentSignedDocuments } = this.state;

                    values.original_document = customDataFilesUpload(originalDocuments, currentOriginalDocuments);
                    values.signed_document = customDataFilesUpload(signedDocuments, currentSignedDocuments);
                    values.serial = serial_number;
                    values.year = selectedYear;

                    if (this.props.currentData) {
                        await this.props.updateDocument(this.props.currentData.id, values);
                        this.onClose();
                    } else {
                        await this.props.createDocument(values);
                        this.onClose();
                    }
                    this.props.reloadData();
                    console.log('values: ', values)
                } catch (error) {
                    this.setState({ loadingForm: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            originalDocuments: [],
            signedDocuments: [],
            isResetOriginalDocument: !this.state.isResetOriginalDocument,
            isResetSignedDocument: !this.state.isResetSignedDocument,
            loadingForm: false,
        })
    }

    onChangeOriginalDocument = (data) => {
        this.setState({
            ...this.state,
            originalDocuments: data,
        });
    }

    onChangeSignedDocument = (data) => {
        this.setState({
            ...this.state,
            signedDocuments: data
        });
    }

    getOriginalDocuments(data) {
        this.setState({ currentOriginalDocuments: data })
    }

    getSignedDocuments(data) {
        this.setState({ currentSignedDocuments: data })
    }

    handleChangeCategory(value, label) {
        if (!value) return; // Kiểm tra nếu value không hợp lệ

        this.setState({ catLabel: label, category_id: value }, () => {
            // Sau khi cập nhật state, đặt lại trường document_number
            this.props.form.setFieldsValue({ document_number: '' });

            if (value == 2) { // Công văn đến
                this.setState({ showDocumentNumber: false });
            } else {
                this.setState({ showDocumentNumber: true });
            }
        });
    }

    handleDocumentNumber = async (e) => {
        e.preventDefault();

        const { catLabel, category_id } = this.state;

        if (catLabel && category_id) {
            this.props.form.validateFields(['yearMonth'], async (err, values) => {
                if (!err) {
                    try {
                        const yearMonth = values.yearMonth ? values.yearMonth.format("YYYY/MM") : null;
                        const selectedYear = values.yearMonth ? values.yearMonth.format("YYYY") : null; // Lấy năm từ yearMonth
                        this.setState({ selectedYear })

                        const res = await this.props.getDocumentsByCategory(category_id, { year: selectedYear });

                        let countFormatted = '001'; // Số thứ tự mặc định

                        if (this.props.currentData) {
                            // Trường hợp là cập nhật
                            countFormatted = res.data || '001'; // Giữ nguyên giá trị hiện có
                            this.setState({ serial_number: countFormatted });
                        } else {
                            // Trường hợp là tạo mới
                            let count = res.data ? parseInt(res.data, 10) + 1 : 1;
                            countFormatted = count.toString().padStart(3, '0');
                            this.setState({ serial_number: countFormatted });
                        }

                        const normalizedCatLabel = catLabel.toUpperCase();
                        const code = getFirstLetters(normalizedCatLabel)

                        this.props.form.setFieldsValue({
                            document_number: `${code}-${countFormatted}/${yearMonth}`
                        });

                        this.setState({ isOpenModal: false });
                    } catch (error) {
                        console.error("Error fetching documents:", error);
                        NotificationManager.error('Đã xảy ra lỗi khi lấy số tài liệu.');
                    }
                }
            });
        } else {
            NotificationManager.warning('Vui lòng chọn loại văn bản trước khi lấy số!');
        }
    };

    onOpenModal = () => {
        const { catLabel, category_id } = this.state;
        if (catLabel && category_id) {
            this.setState({ isOpenModal: true });
        } else {
            NotificationManager.warning('Vui lòng chọn loại văn bản trước khi lấy số!');
        }
    }

    render() {
        var { isOpenModal, showDocumentNumber, isResetSignedDocument, isResetOriginalDocument, currentSignedDocuments, currentOriginalDocuments, loadingForm } = this.state;
        var { visible, currentData, document_categories_active } = this.props;

        const { getFieldDecorator } = this.props.form;
        const currentYearMonth = moment();

        return (
            <Drawer
                visible={visible}
                title={currentData ? "Sửa bản ghi" : "Thêm mới bản ghi"}
                onOk={(e) => this.handleSave(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Huỷ
                        </Button>
                        <Divider type="vertical" />
                        <Button loading={loadingForm} key="submit" type="primary" onClick={(e) => this.handleSave(e)}>
                            {currentData ? "Cập nhật" : "Thêm mới"}
                        </Button>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Loại văn bản">
                                    {getFieldDecorator("categories_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.categories_id : null,
                                        normalize: value => value !== undefined ? value : null
                                    })(
                                        <BaseSelect
                                            options={document_categories_active || []}
                                            optionValue="id"
                                            optionLabel="name"
                                            placeholder="Chọn một bản ghi"
                                            className="w-100"
                                            onChange={(value, lable) => this.handleChangeCategory(value, lable)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                showDocumentNumber && (
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Số văn bản">
                                            <Row gutter={8}>
                                                <Col span={18}>
                                                    <Form.Item noStyle>
                                                        {getFieldDecorator('document_number', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: 'Bấm vào lấy số để tự động tạo số văn bản!',
                                                                },
                                                            ],
                                                            initialValue: currentData ? currentData.document_number : null
                                                        })(<Input />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Button onClick={() => this.onOpenModal()} type='primary'>Lấy số</Button>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                )
                            }

                            <Col xs={24} md={24}>
                                <Form.Item label="Tên văn bản">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.name : null
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày ban hành">
                                    {getFieldDecorator('issue_date', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? isValidDateForm(currentData.issue_date, false) : null
                                    })(<DatePicker
                                        format="DD/MM/YYYY"
                                        style={{ width: '100%' }}
                                        placeholder="Chọn ngày"
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ngày hết hạn">
                                    {getFieldDecorator('expiry_date', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? isValidDateForm(currentData.expiry_date, false) : null
                                    })(<DatePicker
                                        format="DD/MM/YYYY"
                                        style={{ width: '100%' }}
                                        placeholder="Chọn ngày"
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Tệp gốc">
                                    <DraggerFile onChangeData={this.onChangeOriginalDocument} select={isResetOriginalDocument} />
                                    <CurrentFiles currentFiles={currentOriginalDocuments} getData={(data) => this.getOriginalDocuments(data)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Tệp đã ký">
                                    <DraggerFile onChangeData={this.onChangeSignedDocument} select={isResetSignedDocument} />
                                    <CurrentFiles currentFiles={currentSignedDocuments} getData={(data) => this.getSignedDocuments(data)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Mô tả nội dung">
                                    {getFieldDecorator('description', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentData ? _newLine(currentData.description) : null
                                    })(
                                        <Input.TextArea rows={4} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Nơi lưu bản gốc">
                                    {getFieldDecorator('file_location', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentData ? currentData.file_location : null
                                    })(<Input.TextArea />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentData ? _newLine(currentData.note) : null
                                    })(
                                        <Input.TextArea />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.status : 1
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { label: "Active", value: 1 },
                                                { label: "Inactive", value: 0 }
                                            ]}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Modal
                            visible={isOpenModal}
                            title="Chọn năm tháng"
                            onCancel={() => this.setState({ isOpenModal: false })}
                            maskClosable={false}
                            width={isMobile ? '100%' : '25%'}
                            onOk={this.handleDocumentNumber}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Năm và Tháng"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        {getFieldDecorator('yearMonth', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn năm và tháng!',
                                                },
                                            ],
                                            initialValue: currentYearMonth,
                                        })(
                                            <DatePicker
                                                picker="month"
                                                style={{ width: '100%' }}
                                                format="YYYY-MM"
                                                placeholder="Chọn năm và tháng"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Modal>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        document_categories_active: state.DocumentCategories.document_categories_active
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createDocument: (data) => dispatch(createDocument(data)),
        updateDocument: (id, data) => dispatch(updateDocument(id, data)),
        getDocumentCategoriesActive: () => dispatch(getDocumentCategoriesActive()),
        getDocumentsByCategory: (category_id, filter) => dispatch(getDocumentsByCategory(category_id, filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'DocumentForm' })(DocumentForm));
