import React, { Component } from 'react';
import { BarcodeOutlined, GiftOutlined, HomeOutlined, PhoneOutlined, PictureOutlined, UserOutlined } from '@ant-design/icons';
import { Descriptions, Row, Space, Typography, Image, Alert, Empty } from 'antd';
import HtmlParser from 'react-html-parser';
import { checkPermission } from '../../utils/permission';

const { Paragraph } = Typography;

class CustomerInfo extends Component {

    render() {
        const { data } = this.props;

        return (
            <div>
                <Row gutter={16} className='pl-3 pr-3 pb-3'>
                    {checkPermission('messenger', 'customer_info') ? (
                        <>
                            <Descriptions column={24} size="small">
                                <Descriptions.Item span={24}>
                                    <UserOutlined /> <b>Tên:</b>&nbsp; {data?.full_name}
                                </Descriptions.Item>
                                <Descriptions.Item span={24}>
                                    <BarcodeOutlined /> <b>Mã:</b>&nbsp; {data?.code && (<Paragraph style={{ margin: 0, display: 'inline-block' }} copyable>{data?.code}</Paragraph>)}
                                </Descriptions.Item>
                                <Descriptions.Item span={24}>
                                    <PhoneOutlined /><b> Điện thoại:</b>&nbsp; {data?.phone && (<Paragraph style={{ margin: 0, display: 'inline-block' }} copyable>{data?.phone}</Paragraph>)}
                                </Descriptions.Item>
                                <Descriptions.Item span={24}>
                                    <GiftOutlined /> <b>Năm sinh:</b>&nbsp; {data?.birthday}
                                </Descriptions.Item>
                                <Descriptions.Item span={24}>
                                    <HomeOutlined /> <b>Địa chỉ:</b>&nbsp;
                                    {data?.province_name} -&nbsp;
                                    {data?.district_name} -&nbsp;
                                    {data?.wards_name} -&nbsp;
                                    {HtmlParser(data?.address)}
                                </Descriptions.Item>
                                <Descriptions.Item span={24}>
                                    <Alert
                                        message={<span style={{ fontWeight: '600' }}>Ghi chú</span>}
                                        description={HtmlParser(data?.note)}
                                        type="info"
                                        className='fix-alert-element'
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item span={24} className='mt-2'>
                                    <div className='mb-1'><PictureOutlined /><b> Media:</b></div>
                                    <Image.PreviewGroup>
                                        <Space wrap>
                                            {
                                                data?.customer_media.map((item, index) => {
                                                    return (
                                                        <Image
                                                            key={index}
                                                            height={50}
                                                            width={50}
                                                            src={item.source}
                                                            style={{
                                                                border: '1px solid #dedede',
                                                                objectFit: 'cover',
                                                                objectPosition: 'center',
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </Space>
                                    </Image.PreviewGroup>
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                    ) : (
                        <div className='fix-ant-spin-nested-loading'>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Bạn không có quyền xem nội dung này" />
                        </div>
                    )}
                </Row>
            </div>
        );
    }
}

export default CustomerInfo;