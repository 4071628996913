import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DownOutlined, ExportOutlined, ImportOutlined, ReloadOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Modal, Tooltip, Tag, DatePicker, Row, Typography, Avatar, Alert, Popover, Tabs, Button, Card } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import CustomerAddForm from './CustomerAddForm';
import CustomerDetail from './CustomerDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords, repeatPhoneNumber, isValidDate, CustomerCardStatus } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import CustomerScheduleDetail from './CustomerScheduleDetail';
import CustomerImportExcelForm from './CustomerImportExcelForm';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';

import { getListCustomer, getSpecificCustomer, removeCustomer, getStatistical, exportExcel } from '../../redux/actions/CustomerActions';
import { getListProvinces } from '../../redux/actions/RegionsActions';
import { getCustomerSourceActive } from '../../redux/actions/CustomerSourceActions';
import { getBranchActive } from '../../redux/actions/BranchActions';
import TelesaleAssignedToStaffForm from '../../components/TelesaleAssignedToStaffForm';
import FilterableMultiSelect from '../../components/FilterableMultiSelect';
import FilterableMultiTreeSelect from '../../components/FilterableMultiTreeSelect';
import DropdownActions from './DropdownActions';
import DynamicTable from '../../components/DynamicTable';
import { NotificationManager } from 'react-notifications';

const { RangePicker } = DatePicker;
const { Paragraph } = Typography;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/customer',
        breadcrumbName: 'Khách hàng',
    },
]
const { TabPane } = Tabs;

class CustomerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenAddForm: false,
            isOpenDetail: false,
            currentData: null,
            loadingDetail: false,
            visibleAssignedToStaff: false,
            importResponseMessage: null,
            importResponseType: null,
            isOpenFormImport: false,
            isLoadingExport: false
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.reloadData();
        this.props.getStatistical(query);
        this.props.getListProvinces();
        this.props.getCustomerSourceActive();
        this.props.getBranchActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListCustomer(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
            this.props.getStatistical(query);
        }
    }

    onChangeFilter(name, value) {
        let query = qs.parse(this.props.location.search.slice(1));

        const handleDateRange = (startKey, endKey, dateValue) => {
            if (dateValue && dateValue.length > 0) {
                query[startKey] = moment(dateValue[0]).format('YYYY-MM-DD');
                query[endKey] = moment(dateValue[1]).format('YYYY-MM-DD');
            } else {
                delete query[startKey];
                delete query[endKey];
            }
        };

        if (name === 'created_at') {
            handleDateRange('created_date_start', 'created_date_end', value);
        } else if (name === 'appointment_schedule') {
            handleDateRange('appointment_schedule_start', 'appointment_schedule_end', value);
        } else if (name === 'usage_date') {
            handleDateRange('usage_date_start', 'usage_date_end', value);
        } else {
            query[name] = value;
        }

        delete query.created_at;
        delete query.usage_date;
        delete query.appointment_schedule;

        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    openAddForm(isOpenAddForm) {
        this.setState({ isOpenAddForm: isOpenAddForm })
    }

    onRemove() {
        this.props.removeCustomer(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificCustomer(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenDetail(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCustomer(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({
            visibleAssignedToStaff: false,
            selectedRowKeys: []
        })
    }

    onSetMessage = (message, type) => {
        this.setState({ importResponseMessage: message, importResponseType: type })
    }

    openScheduleDetail(customer_id) {
        this.props.getSpecificCustomer(customer_id).then(res => {
            this.setState({ isOpenScheduleDetail: true, currentData: res });
        })
    }

    do_export() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoadingExport: true });
        this.props.exportExcel(query).catch(err => {
            this.setState({ isLoadingExport: false });
        });
        this.setState({ isLoadingExport: false });
        NotificationManager.success("Quá trình xuất file đang được thực hiện. Vui lòng kiểm tra email của bạn sau ít phút.", "Thông báo", 9000);
    }

    render() {
        var { customerList, pagination,
            customerClassActiveList,
            customerStatusActiveList,
            service_active_list,
            customerInteractiveTypeActiveList,
            customerInteractiveStatusActiveList,
            list_provinces,
            customer_source_active_list,
            branchActiveList,
            users,
            statistical
        } = this.props;
        var { isLoading, selectedRowKeys,
            isOpenAddForm,
            isOpenDetail,
            currentData, current_id,
            loadingDetail,
            isOpenScheduleDetail,
            visibleAssignedToStaff,
            importResponseMessage,
            importResponseType,
            isOpenFormImport,
            isLoadingExport
        } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: '#',
                align: 'center',
                width: '50px',
                dataIndex: 'actions',
                key: 'actions',
                render: (text, record) => {
                    return (<DropdownActions record={record} />);
                }
            },
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '250px',
                render: (text, record) => {
                    //if (record.full_name) {
                    if (checkPermission('customer', 'detail')) {
                        return (
                            <Tooltip title="Xem hồ sơ" placement="right">
                                <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                    {
                                        record.avatar ? (
                                            <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#31cdf5',
                                                    verticalAlign: 'middle'
                                                }}
                                                size="small">
                                                {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                            </Avatar>
                                        )
                                    }
                                    &nbsp;{record.full_name}
                                </div>
                            </Tooltip>
                        )
                    } else {
                        return (
                            <>
                                {
                                    record.avatar ? (
                                        <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                    ) : (
                                        <Avatar
                                            style={{
                                                backgroundColor: '#31cdf5',
                                                verticalAlign: 'middle'
                                            }}
                                            size="small">
                                            {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                        </Avatar>
                                    )
                                }
                                &nbsp;{record.full_name}
                            </>
                        )
                    }
                    // } else {
                    //     return <>N/A</>
                    // }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'code',
                key: 'code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Số sổ khám',
                dataIndex: 'medical_book_number',
                key: 'medical_book_number',
                width: '110px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.medical_book_number : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                width: '120px',
                key: 'phone',
                render: (text, record) => {
                    return (
                        <>
                            <HandlePhoneNumber
                                permission={checkPermission('customer', 'phonenumber')}
                                inputPinCode={checkPermission('customer', 'input_pin_code')}
                                customer_id={record?.id}
                                hiddenPhoneNumber={repeatPhoneNumber(text)}
                                fullPhoneNumber={text}
                            />
                            {record.customer_family?.length > 0 && record.customer_family.map((item, index) => {
                                return (
                                    <div>
                                        <HandlePhoneNumber
                                            permission={checkPermission('customer', 'phonenumber')}
                                            inputPinCode={checkPermission('customer', 'input_pin_code')}
                                            customer_id={item.related_customer.id}
                                            hiddenPhoneNumber={repeatPhoneNumber(item.related_customer.phone)}
                                            fullPhoneNumber={item.related_customer.phone}
                                            key={index}
                                        />
                                    </div>
                                )
                            })}
                        </>
                    )
                }
            },
            {
                title: 'Lịch hẹn',
                dataIndex: 'appointment_schedule',
                key: 'appointment_schedule',
                width: '180px',
                render: (text, record) => {
                    if (text && Array.isArray(text) && text.length) {
                        let content = text.map(item => {
                            return (
                                <div style={{ padding: '5px 0' }}>
                                    {moment(item.time).format('DD/MM/YYYY HH:mm')}
                                </div>
                            )
                        })
                        let first_item = text[0]?.time;
                        return (
                            <div className='item-action-btn' onClick={() => this.openScheduleDetail(record.id)}>
                                <Popover placement="right" content={content}>
                                    {moment(first_item).format('DD/MM/YYYY HH:mm')}&nbsp;
                                    <DownOutlined />
                                </Popover>
                            </div>
                        )
                    } else {
                        return (<></>)
                    }
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'customer_status',
                key: 'customer_status',
                width: '200px',
                render: (text, record) => {
                    if (record.customer_status_color) {
                        return (
                            <Tag color={record.customer_status_color}>{text}</Tag>
                        )
                    } else {
                        return (<Tag>{text}</Tag>)
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '250px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: (
                    <>
                        DS:&nbsp;
                        <NumberFormat
                            value={statistical?.total_revenue ?? 0}
                            displayType={'text'}
                            thousandSeparator={true}
                            suffix="đ"
                        />
                    </>
                ),
                dataIndex: "revenue",
                key: "revenue",
                width: "150px",
                render: (text, record) => {
                    if (text) {
                        return (
                            <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                        )
                    }
                }
            },
            {
                title: 'Cơ sở',
                dataIndex: 'branch_name',
                key: 'branch_name',
                width: '130px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Nguồn',
                dataIndex: 'customer_source',
                key: 'customer_source',
                width: '100px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text, false)}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            },
            {
                title: 'Ngày giao tele',
                dataIndex: 'telesale_create',
                key: 'telesale_create',
                width: '130px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{isValidDate(record.telesale?.created_at, true)}</div>
                    )
                }
            },
            {
                title: 'Nhân viên tele',
                dataIndex: 'telesale_user',
                key: 'telesale_user',
                width: '180px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{record.telesale?.telesale_user}</div>
                    )
                }
            },
            {
                title: 'Trạng thái tele',
                width: '140px',
                dataIndex: 'telesale_status',
                key: 'telesale_status',
                render: (text, record) => {
                    if (record.telesale) {
                        var bgcolor = "#f5b22d";
                        var text2 = "Chưa trả kết quả"
                        if (record.telesale?.status == 1) {
                            bgcolor = "green";
                            text2 = "Đã trả kết quả"
                        }
                        return (
                            <Tag color={bgcolor}>
                                {text2}
                            </Tag>
                        )
                    }
                }
            },
        ];

        const tables = <DynamicTable
            dataSource={customerList || []}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            rowSelection={rowSelection}
            moduleKey="customer_list"
            onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
        />

        return (
            <div>
                <PageTitle routes={routes} title="Khách hàng" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('customer', 'create')}
                        isShowDeleteButton={checkPermission('customer', 'remove')}
                        onAdd={() => this.openAddForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        placeholderSearch="Mã, tên, 3 số cuối sđt của khách hàng"
                        activeFilter={
                            query.created_date_start ||
                            query.appointment_schedule_start ||
                            query.customer_class_id ||
                            query.customer_status_id ||
                            query.interested_service_id ||
                            query.selected_service_id ||
                            query.fb_Interactive_type_id ||
                            query.fb_Interactive_status_id ||
                            query.province_code ||
                            query.branch_id ||
                            query.customer_source_id ||
                            query.mode ||
                            query.created_user_id ||
                            query.sales_staff ||
                            query.tele_staff ||
                            query.card_status ||
                            query.usage_date_start
                        }
                        filters={
                            [
                                <FilterableMultiSelect
                                    options={branchActiveList || []}
                                    placeholder="Chọn cơ sở"
                                    parameterName='branch_id'
                                    optionValue="id"
                                    optionLabel="name"
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày tạo', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Lịch hẹn', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('appointment_schedule', value)}
                                    defaultValue={[query.appointment_schedule_start ? moment(query.appointment_schedule_start, 'YYYY-MM-DD') : null, query.appointment_schedule_end ? moment(query.appointment_schedule_end, 'YYYY-MM-DD') : null]}
                                />,
                                <FilterableMultiTreeSelect
                                    options={service_active_list || []}
                                    placeholder="Dịch vụ quan tâm"
                                    parameterName='interested_service_id'
                                />,
                                <FilterableMultiTreeSelect
                                    options={service_active_list || []}
                                    placeholder="Dịch vụ chốt"
                                    parameterName='selected_service_id'
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày chốt', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('usage_date', value)}
                                    defaultValue={[query.usage_date_start ? moment(query.usage_date_start, 'YYYY-MM-DD') : null, query.usage_date_end ? moment(query.usage_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <FilterableMultiSelect
                                    options={customerClassActiveList || []}
                                    placeholder="Hạng khách hàng"
                                    parameterName='customer_class_id'
                                    optionValue="id"
                                    optionLabel="name"
                                />,
                                <FilterableMultiSelect
                                    options={customerStatusActiveList || []}
                                    placeholder="Trạng thái khách hàng"
                                    parameterName='customer_status_id'
                                    optionValue="id"
                                    optionLabel="name"
                                />,
                                <FilterableMultiSelect
                                    options={customerInteractiveTypeActiveList || []}
                                    placeholder="Loại tương tác"
                                    parameterName='fb_Interactive_type_id'
                                    optionValue="id"
                                    optionLabel="name"
                                />,
                                <FilterableMultiSelect
                                    options={customerInteractiveStatusActiveList || []}
                                    placeholder="Trạng thái tương tác"
                                    parameterName='fb_Interactive_status_id'
                                    optionValue="id"
                                    optionLabel="name"
                                />,
                                <FilterableMultiSelect
                                    options={list_provinces || []}
                                    placeholder="Tỉnh/Thành phố"
                                    parameterName='province_code'
                                    optionValue="code"
                                    optionLabel="name"
                                />,
                                <FilterableMultiSelect
                                    options={[
                                        { value: 'from_message', label: 'Message' },
                                        { value: 'from_manual', label: 'Manual' },
                                        { value: 'from_crm', label: 'CRM' },
                                        { value: 'from_potential', label: 'Potential' },
                                        { value: 'import', label: 'Import' },
                                        { value: 'import_receipt', label: 'Import receipt' },
                                        { value: 'from_family', label: 'From family' }
                                    ]}
                                    placeholder="Nguồn tạo"
                                    parameterName='mode'
                                    optionValue="value"
                                    optionLabel="label"
                                />,
                                <FilterableMultiSelect
                                    options={users || []}
                                    placeholder="Người tạo"
                                    parameterName='created_user_id'
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                />,
                                <FilterableMultiSelect
                                    options={users || []}
                                    placeholder="Nhân viên telesale"
                                    parameterName='tele_staff'
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                />,
                                <FilterableMultiSelect
                                    options={users || []}
                                    placeholder="Nhân viên chốt"
                                    parameterName='sales_staff'
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                />,
                                <FilterableMultiSelect
                                    options={customer_source_active_list || []}
                                    placeholder="Nguồn khách hàng"
                                    parameterName='customer_source_id'
                                    optionValue="id"
                                    optionLabel="name"
                                />,
                                <FilterableMultiSelect
                                    options={CustomerCardStatus || []}
                                    placeholder="Trạng thái thẻ"
                                    parameterName='card_status'
                                    optionValue="value"
                                    optionLabel="label"
                                />
                            ]
                        }
                    >
                        {
                            checkPermission('customer', 'transfer_to_staff') ? (
                                <Button icon={<UserSwitchOutlined />} className="table-button" type='primary' disabled={!selectedRowKeys.length ? true : false} onClick={() => this.setState({ visibleAssignedToStaff: true })}>Giao cho telesale</Button>
                            ) : null
                        }
                        {
                            checkPermission('customer', 'import_excel') ? (
                                <Button className="table-button" type="dashed" onClick={() => this.setState({ isOpenFormImport: true })} icon={<ImportOutlined />}>Import</Button>
                            ) : null
                        }
                        {
                            checkPermission('customer', 'export') ? (
                                <Button className="table-button" loading={isLoadingExport} type='default' onClick={() => this.do_export()} icon={<ExportOutlined />}>Export</Button>
                            ) : null
                        }
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                    </TableActionBar>
                    {
                        hasSelected ? <Row className="mb-2" align="middle">Đang chọn {selectedRowKeys.length} bản ghi </Row> : ''
                    }
                    {
                        importResponseMessage && importResponseType ? (
                            <Alert
                                message={importResponseMessage}
                                type={importResponseType}
                                showIcon
                                className="mb-2 mt-2"
                                closable
                                onClose={() => this.onSetMessage(null, null)}
                            />
                        ) : null
                    }

                </div>

                <Card size='small' bodyStyle={{ padding: 0 }} className='has-custom-column-table-btn'>
                    <Tabs onChange={(value) => this.onChangeFilter('tab', value)} defaultActiveKey={query.tab} type="card" size="small">
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Toàn bộ khách hàng</span>} key="all">
                            {tables}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Cho số điện thoại</span>} key="have_phone">
                            {tables}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Đặt được lịch hẹn</span>} key={3}>
                            {tables}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Hẹn không đến</span>} key={4}>
                            {tables}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Đã chốt</span>} key={10}>
                            {tables}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Chưa chốt</span>} key={9}>
                            {tables}
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Khách gặp vấn đề</span>} key={22}>
                            {tables}
                        </TabPane>
                    </Tabs>
                </Card>
                <CustomerAddForm
                    visible={isOpenAddForm}
                    onCancel={() => this.openAddForm(false)}
                />
                <CustomerDetail
                    visible={isOpenDetail}
                    currentItem={currentData}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                    loadingDetail={loadingDetail}
                />
                <CustomerScheduleDetail
                    visible={isOpenScheduleDetail}
                    currentItem={currentData}
                    onCancel={() => this.setState({ isOpenScheduleDetail: false })}
                />
                <CustomerImportExcelForm
                    visible={isOpenFormImport}
                    onCancel={() => this.setState({ isOpenFormImport: false })}
                    onSetMessage={this.onSetMessage}
                    reloadData={() => this.reloadData()}
                />
                <TelesaleAssignedToStaffForm
                    visible={visibleAssignedToStaff}
                    customer_ids={selectedRowKeys}
                    onCancel={() => this.onCancel()}
                />
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        customerList: state.CustomerReducer.customerList,
        pagination: state.CustomerReducer.pagination,
        customerClassActiveList: state.CustomerClassReducer.customerClassActiveList,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList,
        service_active_list: state.ServiceReducer.service_active_list,
        customerInteractiveTypeActiveList: state.CustomerInteractiveTypeReducer.customerInteractiveTypeActiveList,
        customerInteractiveStatusActiveList: state.CustomerInteractiveStatusReducer.customerInteractiveStatusActiveList,
        list_provinces: state.RegionsReducer.list_provinces,
        customer_source_active_list: state.CustomerSourceReducer.customer_source_active_list,
        branchActiveList: state.BranchReducer.branchActiveList,
        users: state.config.users,
        statistical: state.CustomerReducer.statistical
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomer: (filter) => dispatch(getListCustomer(filter)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
        removeCustomer: (ids) => dispatch(removeCustomer(ids)),
        getListProvinces: () => dispatch(getListProvinces()),
        getCustomerSourceActive: () => dispatch(getCustomerSourceActive()),
        getBranchActive: () => dispatch(getBranchActive()),
        getStatistical: filter => dispatch(getStatistical(filter)),
        exportExcel: (filter) => dispatch(exportExcel(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerList' })(CustomerList));

