import {
    GET_LIST_DOCUMENTS,
    REMOVE_DOCUMENTS
} from '../actions/types';

const INIT_STATE = {
    documents: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 30
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_DOCUMENTS:
            return {
                ...state,
                documents: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page)
                }
            };
        case REMOVE_DOCUMENTS: {
            let temp = state.documents.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, documents: temp }
        }
        default:
            return { ...state }
    }
};
