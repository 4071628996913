import React, { Component } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import moment from 'moment';

const { Dragger } = Upload;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
}

class DraggerFile extends Component {
    state = {
        fileList: [

        ],
    }
    static defaultProps = {
        onChangeData: () => { }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.select !== this.props.select) {
            this.setState({
                fileList: []
            });
        }

    }
    onRemove = async file => {
        let newFile = [...this.state.fileList];
        newFile = newFile.filter(item => item.uid !== file.uid);
        this.setState({
            ...this.state,
            fileList: newFile
        });
        let data = await this.setData(newFile)
        this.props.onChangeData(data);
    }

    async setData(list) {
        let listData = [];
        if (list.length) {
            for (let i = 0; i < list.length; i++) {
                let data = await getBase64(list[i].originFileObj);
                listData.push({
                    id: moment().valueOf() + Math.random(),
                    data: data,
                    name: list[i].originFileObj.name
                })
            }
        }
        return listData;
    }
    handleChange = async ({ fileList }) => {
        this.setState({ fileList });
        let data = await this.setData(this.state.fileList)
        this.props.onChangeData(data);
    }
    render() {
        const { fileList } = this.state;
        return (
            <div>
                <Dragger
                    customRequest={dummyRequest}
                    fileList={fileList}
                    multiple={true}
                    onChange={this.handleChange}
                    onRemove={this.onRemove}
                    accept=".pdf, .doc, .docx, .xls, .xlsx"
                    className='dragger-upload-store'
                >
                    <p className="ant-upload-drag-icon" style={{ marginBottom: 0 }}>
                        <InboxOutlined style={{ fontSize: '30px' }} />
                    </p>
                    <p style={{ fontSize: '14px' }} className="ant-upload-text">Click hoặc kéo thả file để upload</p>
                </Dragger>
            </div>
        );
    }
}

export default DraggerFile;
