import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Modal, Tooltip, Tag, DatePicker, TreeSelect, Avatar, Card, Tabs, Typography, Button, Row, Col, Alert } from 'antd';
import { ReloadOutlined, UserOutlined, EditOutlined, UserSwitchOutlined, DownloadOutlined, SwapOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import CustomerScheduleDetail from './CustomerScheduleDetail';
import CustomerDetail from '../customer/CustomerDetail';
import CustomerScheduleForm from './CustomerScheduleForm';
import CustomerScheduleUpdateStatusForm from './CustomerScheduleUpdateStatusForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, makeTree, isValidDate, repeatPhoneNumber, getFirstLetterOfWords, CustomerScheduleType, CustomerScheduleNaStatus, ConvertCustomerScheduleType, CustomerScheduleStatus, ConvertCustomerScheduleStatus, CustomerScheduleTeleStatus, isValidateTime } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import BaseSelect from '../../components/Elements/BaseSelect';
import { isMobile } from 'react-device-detect';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import html2canvas from 'html2canvas';
import moment from 'moment';
import TelesaleAssignedToStaffForm from '../../components/TelesaleAssignedToStaffForm';
import { getListCustomerSchedule, transferToStaff, getSpecificCustomerSchedule, removeCustomerSchedule, changeSchedule } from '../../redux/actions/CustomerScheduleActions';
import { getSpecificCustomer } from '../../redux/actions/CustomerActions';
import { getBranchActive } from '../../redux/actions/BranchActions';
import DynamicTable from '../../components/DynamicTable';
import FilterableMultiSelect from '../../components/FilterableMultiSelect';
const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Paragraph } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/customer-schedule',
        breadcrumbName: 'Lịch hẹn',
    },
]
const { TabPane } = Tabs;
class CustomerScheduleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            selectedRows: [],
            isOpenForm: false,
            currentItem: null,
            isOpenDetail: false,
            currentData: null,
            isOpenCustomerDetail: false,
            currentCustomerData: null,
            loadingDetail: false,
            customerQuery: '',
            isOpenStatusForm: false,
            visible_transfer_to_staff: false,
            loadingTransToStaff: false,
            visible_change_schedule: false,
            loadingChangeSchedule: false,
            loadingCapture: false,
            visibleAssignedToStaff: false,
        }

        this.tableRef = createRef();
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCustomerSchedule(query).then(() => {
            this.setState({ isLoading: false });
        })
        this.props.getBranchActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListCustomerSchedule(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        if (name === 'appointment_schedule') {
            if (value && value.length > 0) {
                query.appointment_schedule_start = moment(value[0]).format('YYYY-MM-DD');
                query.appointment_schedule_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.appointment_schedule_start;
                delete query.appointment_schedule_end;
            }
        }
        delete query.created_at;
        delete query.appointment_schedule;
        delete query.schedule_consultation;
        query = cleanObject(query);
        //console.log("query: ", query)
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeCustomerSchedule(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCustomerSchedule([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenCustomerDetail(isOpenCustomerDetail, data = null) {
        this.setState({ isOpenCustomerDetail: isOpenCustomerDetail, currentCustomerData: data });
    }

    onCustomerDetail(customer_id) {
        this.setState({ currentCustomerId: customer_id, loadingDetail: true })
        this.props.getSpecificCustomer(customer_id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenCustomerDetail(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCustomerSchedule(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onSearchCustomer(val) {
        this.setState({ customerQuery: val }, () => {
            this.props.getListCustomerActive({ keyword: val });
        })
    }

    handleScroll(e) {
        this.props.getListCustomerActive({ keyword: this.state.customerQuery, page: this.props.pagination_active.currentPage + 1 });
    }

    toggleOpenUpdateStatusForm(id, isOpenStatusForm) {
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.setState({ isOpenStatusForm: isOpenStatusForm, currentItem: res });
        })
    }

    async onTransferToStaff(e) {
        e.preventDefault();
        this.props.form.validateFields(['consultant_direct_staff_ids'], async (err, values) => {
            if (!err) {
                this.setState({ loadingTransToStaff: true })
                try {
                    values.rows = this.state.selectedRows;
                    //console.log(values)
                    await this.props.transferToStaff(values);
                    this.reloadData();
                    this.onCancel()
                } catch (error) {
                    this.setState({ loadingTransToStaff: false });
                }
            }
        });
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({
            visible_transfer_to_staff: false,
            loadingTransToStaff: false,
            selectedRowKeys: [],
            selectedRows: [],
            isOpenStatusForm: false,
            visible_change_schedule: false,
            loadingChangeSchedule: false,
            visibleAssignedToStaff: false,
        })
    }

    handleCaptureClick = async () => {
        this.setState({ loadingCapture: true });

        const table = this.tableRef.current;
        if (!table) return;

        // Tạo một bảng tạm thời với kích thước lớn
        const clonedTable = table.cloneNode(true);
        clonedTable.style.width = 'max-content';
        clonedTable.style.height = 'max-content';

        // Thêm bảng tạm thời vào body để tránh các ràng buộc CSS
        document.body.appendChild(clonedTable);

        // Chụp ảnh của bảng tạm thời
        await html2canvas(clonedTable).then(canvas => {
            // Chuyển canvas thành hình ảnh và tải xuống
            const imgData = canvas.toDataURL('image/jpg');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'lich-hen.jpg';
            link.click();

            // Loại bỏ bảng tạm thời sau khi đã chụp ảnh
            document.body.removeChild(clonedTable);
        });
        this.setState({ loadingCapture: false });
    };

    handleChangeSchedule(e) {
        e.preventDefault();
        this.props.form.validateFields(['time', 'name'], (err, values) => {
            if (!err) {
                this.setState({ loadingChangeSchedule: true });
                try {
                    const { selectedRowKeys } = this.state;
                    values.time = values.time ? values.time.format("YYYY-MM-DD HH:mm") : null;
                    values.schedule_ids = selectedRowKeys;
                    this.props.changeSchedule(values)
                        .catch(err => {
                            console.error("Error while changing schedule:", err);
                            this.onCancel();
                        })
                        .finally(() => {
                            this.setState({ loadingChangeSchedule: false });
                        });
                    this.reloadData();
                    this.onCancel();
                    console.log(values);
                } catch (error) {
                    console.error("Error while changing schedule:", error);
                    this.setState({ loadingChangeSchedule: false });
                }
            }
        });
    }

    changeName(value) {
        if (value == 'CHUADATDUOCLICH' || value == 'CHUAXACDINHLICH') {
            this.setState({ require_time: false })
            this.props.form.setFieldsValue({ time: null })
        } else {
            this.setState({ require_time: true })
        }
    }


    render() {
        var { schedule_list, pagination,
            service_active_list, users,
            branchActiveList, customerStatusActiveList,
            doctors, customer_source_active_list
        } = this.props;
        var { isLoading, selectedRowKeys,
            isOpenForm,
            currentItem, isOpenDetail,
            currentData, current_id,
            currentCustomerData,
            isOpenCustomerDetail,
            currentCustomerId,
            loadingDetail,
            isOpenStatusForm,
            visible_transfer_to_staff,
            loadingTransToStaff,
            visible_change_schedule,
            loadingChangeSchedule,
            loadingCapture,
            visibleAssignedToStaff,
            selectedRows,
            require_time
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        const { getFieldDecorator } = this.props.form;
        var query = qs.parse(this.props.location.search.slice(1));
        const service = makeTree(service_active_list || [], 0);

        const customerIds = selectedRows.map(item => item.customer_id);

        const columns = [
            {
                title: 'Kiểu lịch hẹn',
                dataIndex: 'name',
                key: 'name',
                //fixed: 'left',
                render: (text, record) => {
                    if (checkPermission('customer_schedule', 'detail')) {
                        return (<Tooltip title="Xem chi tiết lịch hẹn" placement="right">
                            <div className='item-action-btn' onClick={() => this.onDetail(record.id)}>
                                <strong>{ConvertCustomerScheduleType(text)}</strong>
                            </div>
                        </Tooltip>
                        )
                    } else {
                        return (
                            <strong>{ConvertCustomerScheduleType(text)}</strong>
                        )
                    }
                }
            },
            {
                title: 'Khách hàng',
                dataIndex: 'customer_name',
                key: 'customer_name',
                render: (text, record) => {
                    //console.log("record.customer_id: ", record.customer_id)
                    var class_name = ''
                    var tooltip_title = 'Xem hồ sơ khách hàng';
                    if (record.customer_tasks == 'delete') {
                        class_name = 'is_delete_item';
                        tooltip_title = "Đã xóa"
                    }
                    if (record.customer_name) {
                        if (checkPermission('customer', 'detail')) {
                            return (
                                <Tooltip title={tooltip_title} placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onCustomerDetail(record.customer_id)}>
                                        {
                                            record.avatar ? (
                                                <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#31cdf5',
                                                        verticalAlign: 'middle'
                                                    }}
                                                    size="small">
                                                    {record.customer_name ? getFirstLetterOfWords(record.customer_name) : ''}
                                                </Avatar>
                                            )
                                        }
                                        &nbsp;<span className={class_name}>{record.customer_name}</span>
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    {
                                        record.avatar ? (
                                            <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#31cdf5',
                                                    verticalAlign: 'middle'
                                                }}
                                                size="small">
                                                {record.customer_name ? getFirstLetterOfWords(record.customer_name) : ''}
                                            </Avatar>
                                        )
                                    }
                                    &nbsp;{record.customer_name}
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'customer_code',
                key: 'customer_code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.customer_code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Số sổ khám',
                dataIndex: 'medical_book_number',
                key: 'medical_book_number',
                width: '120px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.medical_book_number : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Điện thoại',
                dataIndex: 'customer_phone',
                width: '120px',
                key: 'customer_phone',
                render: (text, record) => {
                    return (
                        <>
                            <HandlePhoneNumber
                                permission={checkPermission('customer', 'phonenumber')}
                                inputPinCode={checkPermission('customer', 'input_pin_code')}
                                customer_id={record?.customer_id}
                                hiddenPhoneNumber={repeatPhoneNumber(text)}
                                fullPhoneNumber={text}
                            />
                            {record.customer_family?.length > 0 && record.customer_family.map((item, index) => {
                                return (
                                    <div>
                                        <HandlePhoneNumber
                                            permission={checkPermission('customer', 'phonenumber')}
                                            inputPinCode={checkPermission('customer', 'input_pin_code')}
                                            customer_id={item.related_customer.id}
                                            hiddenPhoneNumber={repeatPhoneNumber(item.related_customer.phone)}
                                            fullPhoneNumber={item.related_customer.phone}
                                            key={index}
                                        />
                                    </div>
                                )
                            })}
                        </>
                    )
                }
            },
            {
                title: 'Giờ',
                dataIndex: 'time',
                key: 'time',
                width: '50px',
                render: (text, record) => {
                    return (
                        <div>{isValidateTime(text)}</div>
                    )
                }
            },
            {
                title: 'Ngày',
                dataIndex: 'date',
                key: 'date',
                width: '100px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(record.time, false)}</div>
                    )
                }
            },
            {
                title: '#',
                dataIndex: 'time_diff ',
                key: 'time_diff',
                width: '120px',
                render: (text, record) => {
                    var diffDate = record.time ? moment(record.time, "YYYY-MM-DD").diff(moment().startOf('day'), 'days') : 0;
                    return (
                        <div>
                            {
                                diffDate ? (
                                    <div>
                                        {
                                            diffDate > 0 ? <Tag color="#f50">Còn lại {diffDate} ngày</Tag> : <Tag color="red">Cách đây {-1 * diffDate} ngày</Tag>
                                        }
                                    </div>
                                ) : (
                                    <>
                                        {
                                            record.time != null ? (
                                                <Tag color="#096dd9">Hôm nay</Tag>
                                            ) : null
                                        }
                                    </>
                                )
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                render: (text, record) => {
                    var bgcolor = "#f5b22d";
                    var disabled = false;
                    if (text == 'daden' || text == 'dagoi') {
                        bgcolor = "green";
                    } else if (text == 'dachuyenlich') {
                        bgcolor = "#c5c5c5";
                        disabled = true;
                    }
                    if (checkPermission('customer_schedule', 'update_status')) {
                        return (
                            <div>
                                <Tooltip title={!disabled ? 'Click để cập nhật' : ''} placement="right">
                                    <Button disabled={disabled} icon={<EditOutlined />} style={{ background: bgcolor, color: '#fff', border: 'none' }} onClick={() => this.toggleOpenUpdateStatusForm(record.id, true)} size='small'>
                                        {ConvertCustomerScheduleStatus(text, true)}
                                    </Button>
                                </Tooltip>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <Button disabled={disabled} style={{ background: bgcolor, color: '#fff', border: 'none' }} size='small'>
                                    {ConvertCustomerScheduleStatus(text, true)}
                                </Button>
                            </div>
                        )
                    }

                }
            },
            {
                title: 'Trạng thái KH',
                dataIndex: 'customer_status',
                key: 'customer_status',
                width: '180px',
                render: (text, record) => {
                    if (record.customer_status_color) {
                        return (
                            <Tag color={record.customer_status_color}>{text}</Tag>
                        )
                    } else {
                        return (<Tag>{text}</Tag>)
                    }
                }
            },
            {
                title: 'Bác sĩ thực hiện',
                dataIndex: 'doctors',
                key: 'doctors',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            {/* <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip> */}
                            {stripHtml(text)}
                        </div>
                    )
                }
            },
            {
                title: 'Tình trạng hiện tại của khách',
                dataIndex: 'customer_note',
                key: 'customer_note',
                width: '250px',
                render: (text, record) => {
                    return (<>{HtmlParser(text)}</>)
                }
            },
            {
                title: 'KQ telesale',
                dataIndex: 'telesale',
                key: 'telesale',
                width: '250px',
                render: (text, record) => {
                    return (<>{HtmlParser(record.telesale?.result_content)}</>)
                }
            },
            {
                title: 'KQ tư vấn trực tiếp',
                dataIndex: 'consultant_direct',
                key: 'consultant_direct',
                width: '250px',
                render: (text, record) => {
                    return (<>{HtmlParser(record.consultant_direct?.result_content)}</>)
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '250px',
                render: (text, record) => {
                    return (<>{HtmlParser(text)}</>)
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text)}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            },
            {
                title: 'Nguồn',
                dataIndex: 'customer_source',
                key: 'customer_source',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{text}</div>
                        )
                    }
                }
            },
            {
                title: 'Cơ sở',
                dataIndex: 'branch_name',
                key: 'branch_name',
                width: '130px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Ngày giao tele',
                dataIndex: 'telesale_create',
                key: 'telesale_create',
                width: '130px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{isValidDate(record.telesale?.created_at, true)}</div>
                    )
                }
            },
            {
                title: 'Nhân viên tele',
                dataIndex: 'telesale_user',
                key: 'telesale_user',
                width: '180px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{record.telesale?.telesale_user}</div>
                    )
                }
            },
            {
                title: 'Trạng thái tele',
                width: '140px',
                dataIndex: 'telesale_status',
                key: 'telesale_status',
                render: (text, record) => {
                    if (record.telesale) {
                        var bgcolor = "#f5b22d";
                        var text2 = "Chưa trả kết quả"
                        if (record.telesale?.status == 1) {
                            bgcolor = "green";
                            text2 = "Đã trả kết quả"
                        }
                        return (
                            <Tag color={bgcolor}>
                                {text2}
                            </Tag>
                        )
                    }
                }
            },
        ];

        const tables = <DynamicTable
            dataSource={schedule_list || []}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            rowSelection={rowSelection}
            ref={this.tableRef}
            moduleKey="customer_schedule"
            onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
        />

        return (
            <div>
                <PageTitle routes={routes} title="Lịch hẹn" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('customer_schedule', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        placeholderSearch="Mã, tên, 3 số cuối sđt của khách hàng"
                        activeFilter={
                            query.name ||
                            query.created_date_start ||
                            query.appointment_schedule_start ||
                            query.schedule_consultation_start ||
                            query.status ||
                            query.customer_id ||
                            query.branch_id ||
                            query.service_id ||
                            query.customer_status_id ||
                            query.mode ||
                            query.sales_staff ||
                            query.created_user_id ||
                            query.doctor_id ||
                            query.customer_source_id
                        }
                        filters={
                            [
                                <BaseSelect
                                    options={CustomerScheduleType}
                                    placeholder="Kiểu lịch hẹn"
                                    defaultValue={query.name ? query.name : null}
                                    onChange={(value) => this.onChangeFilter('name', value)}
                                    className="w-100"
                                />,
                                <BaseSelect
                                    options={branchActiveList || []}
                                    optionValue="id"
                                    optionLabel="name"
                                    placeholder="Chọn cơ sở"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('branch_id', value)}
                                    defaultValue={query.branch_id ? parseInt(query.branch_id) : null}
                                />,
                                <BaseSelect
                                    options={CustomerScheduleStatus.concat(CustomerScheduleTeleStatus).concat(CustomerScheduleNaStatus)}
                                    placeholder="Trạng thái"
                                    className="w-100"
                                    defaultValue={query.status ? query.status : null}
                                    onChange={(value) => this.onChangeFilter('status', value)}
                                />,
                                <BaseSelect
                                    options={customerStatusActiveList || []}
                                    optionValue="id"
                                    optionLabel="name"
                                    showSearch
                                    placeholder="Trạng thái KH"
                                    onChange={(value) => this.onChangeFilter('customer_status_id', value)}
                                    className="w-100"
                                    defaultValue={query.customer_status_id ? parseInt(query.customer_status_id) : null}
                                />,
                                <FilterableMultiSelect
                                    options={customer_source_active_list || []}
                                    placeholder="Nguồn khách hàng"
                                    parameterName='customer_source_id'
                                    optionValue="id"
                                    optionLabel="name"
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày tạo', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Lịch hẹn', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('appointment_schedule', value)}
                                    defaultValue={[query.appointment_schedule_start ? moment(query.appointment_schedule_start, 'YYYY-MM-DD') : null, query.appointment_schedule_end ? moment(query.appointment_schedule_end, 'YYYY-MM-DD') : null]}
                                />,
                                <TreeSelect
                                    showSearch
                                    className="w-100"
                                    dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                    treeData={service}
                                    treeNodeFilterProp='title'
                                    allowClear
                                    placeholder="Dịch vụ"
                                    treeDefaultExpandAll
                                    defaultValue={query.service_id ? parseInt(query.service_id) : null}
                                    onChange={(value) => this.onChangeFilter('service_id', value)}
                                />,
                                <BaseSelect
                                    options={[
                                        { value: 'manual', label: 'Manual' },
                                        { value: 'from_chat', label: 'From chat' },
                                        { value: 'from_crm', label: 'Crm' }
                                    ]}
                                    showSearch
                                    placeholder="Nguồn tạo"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('mode', value)}
                                    defaultValue={query.mode ? query.mode : null}
                                />,
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Nhân viên chốt"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('sales_staff', value)}
                                    defaultValue={query.sales_staff ? parseInt(query.sales_staff) : null}
                                />,
                                <BaseSelect
                                    options={doctors || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Bác sĩ thực hiện"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('doctor_id', value)}
                                    defaultValue={query.doctor_id ? parseInt(query.doctor_id) : null}
                                />,
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Người tạo"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_user_id', value)}
                                    defaultValue={query.created_user_id ? parseInt(query.created_user_id) : null}
                                />
                            ]
                        }
                    >
                        {
                            checkPermission('customer_schedule', 'transfer_to_staff') ? (
                                <Button icon={<UserSwitchOutlined />} className="table-button" type='primary' disabled={!selectedRowKeys.length ? true : false} onClick={() => this.setState({ visible_transfer_to_staff: true })}>Giao cho tư vấn</Button>
                            ) : null
                        }
                        {
                            checkPermission('customer', 'transfer_to_staff') ? (
                                <Button icon={<UserSwitchOutlined />} className="table-button" type='primary' disabled={!selectedRowKeys.length ? true : false} onClick={() => this.setState({ visibleAssignedToStaff: true })}>Giao cho telesale</Button>
                            ) : null
                        }
                        {
                            checkPermission('customer_schedule', 'change_schedule') ? (
                                <Button icon={<SwapOutlined />} className="table-button" type='primary' disabled={!selectedRowKeys.length ? true : false} onClick={() => this.setState({ visible_change_schedule: true })}>Chuyển lịch</Button>
                            ) : null
                        }

                        <Button className="table-button" type='primary' icon={<DownloadOutlined />} loading={loadingCapture} onClick={() => this.handleCaptureClick()}>Chụp màn hình</Button>
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                    </TableActionBar>
                    {
                        hasSelected ? <Row className="mb-2" align="middle">Đang chọn {selectedRowKeys.length} bản ghi </Row> : ''
                    }
                </div>

                <Card size='small' bodyStyle={{ padding: 0 }} className='has-custom-column-table-btn'>
                    <Tabs onChange={(value) => this.onChangeFilter('tab', value)} defaultActiveKey={query.tab} type="card" size="small">
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Lịch hẹn hôm nay</span>} key="1">
                            <div ref={this.tableRef}>
                                {tables}
                            </div>
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Lịch hẹn ngày mai</span>} key="2">
                            <div ref={this.tableRef}>
                                {tables}
                            </div>
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Lịch hẹn hôm qua</span>} key="4">
                            <div ref={this.tableRef}>
                                {tables}
                            </div>
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Lịch chưa xác định</span>} key="5">
                            <div ref={this.tableRef}>
                                <Alert
                                    style={{ border: 'none' }}
                                    message="Danh sách các khách hàng chưa hẹn được cụ thể lịch hẹn tiếp theo, cần chú ý liên hệ chăm sóc lại thường xuyên."
                                    type="warning"
                                />
                                {tables}
                            </div>
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Lịch chưa đến, chưa gọi</span>} key="6">
                            <div ref={this.tableRef}>
                                <Alert
                                    style={{ border: 'none' }}
                                    message="Danh sách khách hàng đã có lịch hẹn nhưng chưa đến (hoặc chưa gọi lại cho khách), cần gọi lại để hẹn lịch mới hàng ngày."
                                    type="warning"
                                />
                                {tables}
                            </div>
                        </TabPane>
                        <TabPane tab={<span style={{ fontWeight: 600 }}>Tất cả lịch hẹn</span>} key="3">
                            <div ref={this.tableRef}>
                                {tables}
                            </div>
                        </TabPane>
                    </Tabs>
                </Card>

                <CustomerScheduleForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <CustomerScheduleDetail
                    visible={isOpenDetail}
                    currentItem={currentData}
                    reloadData2={() => this.reloadData()}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
                <CustomerDetail
                    visible={isOpenCustomerDetail}
                    currentItem={currentCustomerData}
                    reloadData={() => this.onCustomerDetail(currentCustomerId)}
                    onCancel={() => this.toggleOpenCustomerDetail(false)}
                    loadingDetail={loadingDetail}
                />
                <CustomerScheduleUpdateStatusForm
                    visible={isOpenStatusForm}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.setState({ isOpenStatusForm: false })}
                />
                <TelesaleAssignedToStaffForm
                    visible={visibleAssignedToStaff}
                    customer_ids={customerIds}
                    onCancel={() => this.onCancel()}
                />
                <Modal
                    title="Giao cho nhân viên tư vấn trực tiếp"
                    visible={visible_transfer_to_staff}
                    header={false}
                    onOk={(e) => this.onTransferToStaff(e)}
                    width={isMobile ? '100%' : '40%'}
                    onCancel={() => this.onCancel()}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            Cancel
                        </Button>,
                        <Button
                            loading={loadingTransToStaff}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.onTransferToStaff(e)}
                        >
                            OK
                        </Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Nhân viên tư vấn">
                                    {getFieldDecorator("consultant_direct_staff_ids", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: []
                                    })(
                                        <BaseSelect
                                            options={users ?? []}
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            placeholder="Chọn 1 hoặc nhiều nhân viên tư vấn"
                                            defaultText="Chọn nhân viên tư vấn"
                                            showSearch={true}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>

                <Modal
                    title="Chuyển lịch hẹn"
                    visible={visible_change_schedule}
                    header={false}
                    onOk={(e) => this.handleChangeSchedule(e)}
                    width={isMobile ? '100%' : '40%'}
                    onCancel={() => this.onCancel()}
                    maskClosable={false}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            Cancel
                        </Button>,
                        <Button
                            loading={loadingChangeSchedule}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.handleChangeSchedule(e)}
                        >
                            OK
                        </Button>,
                    ]}
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Alert
                                    message="Lưu ý"
                                    type="info"
                                    showIcon
                                    className='mb-5 fix-alert-element'
                                    description={<>
                                        Chỉ chuyển <strong>Lịch làm dịch vụ</strong> và <strong>
                                            Lịch tái khám</strong>
                                    </>}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label='Kiểu lịch hẹn'>
                                    {getFieldDecorator("name", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <BaseSelect
                                            options={CustomerScheduleType}
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.changeName(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                require_time ? (
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Thời gian">
                                            {getFieldDecorator('time', {
                                                rules: [
                                                    {
                                                        required: require_time,
                                                        message: 'Vui lòng điền!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    showTime={{ format: 'HH:mm', defaultValue: moment('00:00', 'HH:mm') }} // Giới hạn giờ và phút là 00:00
                                                    format="DD/MM/YYYY HH:mm"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                        </Row>
                    </Form>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        schedule_list: state.CustomerScheduleReducer.schedule_list,
        pagination: state.CustomerScheduleReducer.pagination,
        service_active_list: state.ServiceReducer.service_active_list,
        pagination_active: state.CustomerReducer.pagination_active,
        users: state.config.users,
        doctors: state.config.doctors,
        branchActiveList: state.BranchReducer.branchActiveList,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList,
        customer_source_active_list: state.CustomerSourceReducer.customer_source_active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerSchedule: (filter) => dispatch(getListCustomerSchedule(filter)),
        getSpecificCustomerSchedule: (id) => dispatch(getSpecificCustomerSchedule(id)),
        removeCustomerSchedule: (ids) => dispatch(removeCustomerSchedule(ids)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
        transferToStaff: (data) => dispatch(transferToStaff(data)),
        getBranchActive: () => dispatch(getBranchActive()),
        changeSchedule: (data) => dispatch(changeSchedule(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerScheduleList' })(CustomerScheduleList));

