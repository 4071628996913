import api from '../../utils/api';
import {
    GET_LIST_DOCUMENTS,
    GET_ONE_DOCUMENTS,
    CREATE_DOCUMENTS,
    UPDATE_DOCUMENTS,
    REMOVE_DOCUMENTS,
    GET_DOCUMENTS_BY_CATEGORY
} from './types';
import { NotificationManager } from 'react-notifications'

export const getDocuments = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/documents', { params: filter }).then(res => {
            dispatch({
                type: GET_LIST_DOCUMENTS,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getOneDocument = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/documents/${id}`).then(res => {
            dispatch({
                type: GET_ONE_DOCUMENTS,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getDocumentsByCategory = (category_id, filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/documents/category/${category_id}`, { params: filter }).then(res => {
            dispatch({
                type: GET_DOCUMENTS_BY_CATEGORY,
                payload: res.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createDocument = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/documents/create', data).then(res => {
            dispatch({
                type: CREATE_DOCUMENTS,
                payload: res.data.data
            });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateDocument = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/documents/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_DOCUMENTS,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeDocuments = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/documents', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_DOCUMENTS,
                payload: ids
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}