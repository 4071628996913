import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    DeleteOutlined,
    DownOutlined,
    MenuOutlined,
    PhoneOutlined,
    PlusOutlined,
    ReloadOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Modal, Tooltip, Tag, DatePicker, Avatar, Button, Alert, Typography, Space, Dropdown } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import CustomerPotentialForm from './CustomerPotentialForm';
import CustomerPotentialDetail from './CustomerPotentialDetail';
import ImportExcelForm from './ImportExcelForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords, repeatPhoneNumber, isValidDate, potentialProfileStatus, ConvertPotentialProfileStatus } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import moment from 'moment';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import { getListCustomerPotential, getSpecificCustomerPotential, removeCustomerPotential, getListName } from '../../redux/actions/CustomerPotentialActions';
import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';
import BaseSelect from '../../components/Elements/BaseSelect';
import CreateCustomerProfileForm from './CreateCustomerProfileForm';
import DynamicTable from '../../components/DynamicTable';
//import Stringee from '../../components/Stringee';

const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Paragraph } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/customer-potential',
        breadcrumbName: 'Khách hàng tiềm năng',
    },
]

class CustomerPotentialList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenForm: false,
            currentItem: null,
            isOpenDetail: false,
            loadingDetail: false,
            importResponseMessage: null,
            importResponseType: null,
            isOpenFormImport: false,
            districts: [],
            wards: [],
            isOpenFormCreateCustomer: false,
            currentData: null,
            callToNumber: null,
            callToAlias: null,
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCustomerPotential(query).then(() => {
            this.setState({ isLoading: false });
        })

        this.props.getListProvinces();
        this.props.getListDistricts({ province_code: query.province_code }).then(res => {
            this.setState({ districts: res.data });
        })
        this.props.getListWards({ district_code: query.district_code }).then(res2 => {
            this.setState({ wards: res2.data })
        })
        this.props.getListName();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListCustomerPotential(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        if (name === 'last_update_result') {
            if (value && value.length > 0) {
                query.last_update_result_start = moment(value[0]).format('YYYY-MM-DD');
                query.last_update_result_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.last_update_result_start;
                delete query.last_update_result_end;
            }
        }
        delete query.last_update_result;
        delete query.created_at;
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onRemove() {
        this.props.removeCustomerPotential(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá khách hàng này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCustomerPotential([id]).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificCustomerPotential(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenDetail(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCustomerPotential(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onCancel() {
        this.props.form.resetFields();
        this.setState({ selectedRowKeys: [] })
    }

    onSetMessage = (message, type) => {
        this.setState({ importResponseMessage: message, importResponseType: type })
    }

    loadDistricts(province_code) {
        if (province_code) {
            this.props.getListDistricts({ province_code: province_code }).then(res => {
                this.props.form.resetFields(['district_code', 'wards_code']);
                this.setState({ districts: res.data, wards: [] })
            })
        }
    }

    loadWards(district_code) {
        if (district_code) {
            this.props.getListWards({ district_code: district_code }).then(res => {
                this.props.form.resetFields(['wards_code']);
                this.setState({ wards: res.data })
            })
        }
    }

    handleCreateCustomer(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificCustomerPotential(id).then(res => {
            this.setState({ currentItem: res, isOpenFormCreateCustomer: true })
        })
    }

    makeStringeeCall(callToNumber, callToAlias) {
        //console.log(callToNumber, callToAlias);
        this.setState({ callToNumber: callToNumber, callToAlias: callToAlias, openPopover: true })
    }

    render() {
        var { customerList, pagination, list_provinces, customerStatusActiveList, list_name, users } = this.props;
        var { isLoading, selectedRowKeys,
            isOpenFormImport, isOpenForm,
            currentItem, isOpenDetail, loadingDetail,
            importResponseType, importResponseMessage,
            current_id, districts, wards, isOpenFormCreateCustomer,
            callToNumber,
            callToAlias,
            openPopover } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const renderItems = (record) => {
            const itemsAction = [];
            // if (checkPermission('customer_potential', 'voicecall')) {
            //     itemsAction.push(
            //         {
            //             key: '3',
            //             label: (
            //                 <div onClick={() => this.makeStringeeCall(record.phone, record.full_name)}>Gọi điện thoại</div>
            //             ),
            //             icon: <PhoneOutlined />
            //         }
            //     )
            // }
            if (checkPermission('customer', 'create')) {
                itemsAction.push(
                    {
                        key: '1',
                        label: (
                            <div onClick={() => this.handleCreateCustomer(record.id)}>Tạo hồ sơ</div>
                        ),
                        icon: <PlusOutlined />
                    },
                )
            }
            if (checkPermission('customer_potential', 'remove')) {
                itemsAction.push(
                    {
                        key: '2',
                        label: (
                            <div onClick={() => this.openAlert(record.id)}>Xóa khách hàng</div>
                        ),
                        icon: <DeleteOutlined />,
                        danger: true
                    }
                )
            }

            Object.keys(itemsAction).sort();
            return itemsAction;
        }

        const columns = [
            {
                title: '#',
                align: 'center',
                width: '50px',
                key: 'actions',
                render: (text, record) => {
                    const items = renderItems(record);
                    return (
                        <Space size="middle">
                            {items && items.length > 0 ? (
                                <Dropdown trigger="click" menu={{ items }}>
                                    <div className='item-action-btn'>
                                        <MenuOutlined /> <DownOutlined />
                                    </div>
                                </Dropdown>
                            ) : (
                                <MenuOutlined style={{ color: '#dedede' }} />
                            )}
                        </Space>
                    );
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'code',
                key: 'code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <Paragraph style={{ margin: 0 }} copyable={
                            {
                                text: record ? record.code : null,
                                tooltips: false
                            }
                        }>
                            {text}
                        </Paragraph>
                    )
                }
            },
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '200px',
                render: (text, record) => {
                    //if (record.full_name) {
                    return (
                        <Tooltip title="Xem chi tiết" placement="right">
                            <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                {
                                    record.avatar ? (
                                        <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                    ) : (
                                        <Avatar
                                            style={{
                                                backgroundColor: '#31cdf5',
                                                verticalAlign: 'middle'
                                            }}
                                            size="small">
                                            {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                        </Avatar>
                                    )
                                }
                                &nbsp;{record.full_name}
                            </div>
                        </Tooltip>
                    )
                    // } else {
                    //     return <>N/A</>
                    // }
                }
            },
            {
                title: 'Mã thẻ mới',
                dataIndex: 'card_number_new',
                key: 'card_number_new',
                width: '180px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.card_number_new : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Mã thẻ cũ',
                dataIndex: 'card_number',
                key: 'card_number',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.card_number : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                width: '120px',
                key: 'phone',
                render: (text, record) => {
                    return (
                        <>
                            <HandlePhoneNumber
                                permission={checkPermission('customer_potential', 'phonenumber')}
                                inputPinCode={checkPermission('customer_potential', 'input_pin_code')}
                                customer_id={record?.id}
                                hiddenPhoneNumber={repeatPhoneNumber(text)}
                                fullPhoneNumber={text}
                            />
                        </>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'customer_status',
                key: 'customer_status',
                width: '200px',
                render: (text, record) => {
                    if (record.customer_status_color) {
                        return (
                            <Tag color={record.customer_status_color}>{text}</Tag>
                        )
                    } else {
                        return (<Tag>{text}</Tag>)
                    }
                }
            },
            {
                title: 'Kết quả tư vấn',
                dataIndex: 'result_content',
                key: 'result_content',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Tỉnh/Thành phố',
                dataIndex: 'province_name',
                key: 'province_name',
                width: '140px'
            },
            {
                title: 'Quận/Huyện',
                dataIndex: 'district_name',
                key: 'district_name',
                width: '140px'
            },
            {
                title: 'Phường/Xã',
                dataIndex: 'wards_name',
                key: 'wards_name',
                width: '140px'
            },
            {
                title: 'Tổ',
                dataIndex: 'residential_group',
                key: 'residential_group',
                width: '100px'
            },
            {
                title: 'Địa chỉ',
                dataIndex: 'address',
                key: 'address',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Năm sinh',
                dataIndex: 'birthday',
                key: 'birthday',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>{text}</>
                    )
                }
            },
            {
                title: 'Giới tính',
                dataIndex: 'gender',
                key: 'gender',
                width: '90px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <span style={{ textTransform: 'capitalize' }}>{text}</span>
                    )
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Danh sách',
                dataIndex: 'list_name',
                key: 'list_name',
                width: '150px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text, false)}</div>
                        )
                    }
                }
            },
            {
                title: 'Hồ sơ',
                dataIndex: 'profile',
                key: 'profile',
                width: '150px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <>{ConvertPotentialProfileStatus(record.profile)}</>
                    )
                }
            },
            {
                title: 'Ngày telesale',
                dataIndex: 'last_update_result',
                key: 'last_update_result',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text, true)}</div>
                        )
                    }
                }
            },
            {
                title: 'Nhân viên telesale',
                dataIndex: 'user_update_result',
                key: 'user_update_result',
                width: '200px',
                render: (text, record) => {
                    return (
                        <>{text}</>
                    )
                }
            },
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Khách hàng tiềm năng" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('customer_potential', 'create')}
                        isShowDeleteButton={checkPermission('customer_potential', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        activeFilter={
                            query.created_at ||
                            query.last_update_result ||
                            query.province_code ||
                            query.district_code ||
                            query.wards_code ||
                            query.customer_status_id ||
                            query.user_id_update_result
                        }
                        filters={
                            [<BaseSelect
                                options={list_name || []}
                                optionValue="list_name_code"
                                optionLabel="list_name"
                                showSearch
                                placeholder="Danh sách"
                                onChange={(value) => this.onChangeFilter('list_name_code', value)}
                                className="w-100"
                                defaultValue={query.list_name_code ? query.list_name_code : null}
                            />,
                            <BaseSelect
                                options={potentialProfileStatus || []}
                                showSearch
                                placeholder="Hồ sơ"
                                onChange={(value) => this.onChangeFilter('profile', value)}
                                className="w-100"
                                defaultValue={query.profile ? query.profile : null}
                            />,
                            <RangePicker
                                format="DD/MM/YYYY"
                                placeholder={['Ngày', 'telesale']}
                                className="w-100"
                                onChange={(value) => this.onChangeFilter('last_update_result', value)}
                                defaultValue={[query.last_update_result_start ? moment(query.last_update_result_start, 'YYYY-MM-DD') : null, query.last_update_result_end ? moment(query.last_update_result_end, 'YYYY-MM-DD') : null]}
                            />,
                            <BaseSelect
                                options={users || []}
                                optionValue="user_id"
                                optionLabel="full_name"
                                showSearch
                                placeholder="Nhân viên telesale"
                                className="w-100"
                                onChange={(value) => this.onChangeFilter('user_id_update_result', value)}
                                defaultValue={query.user_id_update_result ? parseInt(query.user_id_update_result) : null}
                            />,
                            <BaseSelect
                                options={customerStatusActiveList || []}
                                optionValue="id"
                                optionLabel="name"
                                showSearch
                                placeholder="Trạng thái khách hàng"
                                onChange={(value) => this.onChangeFilter('customer_status_id', value)}
                                className="w-100"
                                defaultValue={query.customer_status_id ? parseInt(query.customer_status_id) : null}
                            />,
                            <BaseSelect
                                options={list_provinces || []}
                                optionValue="code"
                                optionLabel="name"
                                showSearch
                                placeholder="Tỉnh/Thành phố"
                                className="w-100"
                                onChange={(value) => {
                                    this.onChangeFilter('province_code', value);
                                    this.loadDistricts(value)
                                }}
                                defaultValue={query.province_code ? query.province_code : null}
                            />,
                            <BaseSelect
                                options={districts || []}
                                optionValue="code"
                                optionLabel="name"
                                showSearch
                                placeholder="Quận/Huyện"
                                className="w-100"
                                onChange={(value) => {
                                    this.onChangeFilter('district_code', value);
                                    this.loadWards(value)
                                }}
                                defaultValue={query.district_code ? query.district_code : null}
                            />,
                            <BaseSelect
                                options={wards || []}
                                optionValue="code"
                                optionLabel="name"
                                showSearch
                                placeholder="Phường/Xã"
                                className="w-100"
                                onChange={(value) => this.onChangeFilter('wards_code', value)}
                                defaultValue={query.wards_code ? query.wards_code : null}
                            />
                            ]
                        }
                    >
                        {
                            checkPermission('customer_potential', 'import_excel') ? (
                                <Button className="table-button" type="dashed" onClick={() => this.setState({ isOpenFormImport: true })}>Import excel</Button>
                            ) : null
                        }

                        <RangePicker
                            format="DD/MM/YYYY"
                            placeholder={['Ngày tạo', 'Đến ngày']}
                            style={{ width: '220px' }}
                            className="table-button"
                            onChange={(value) => this.onChangeFilter('created_at', value)}
                            defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <div>
                    {
                        importResponseMessage && importResponseType ? (
                            <Alert
                                message={importResponseMessage}
                                type={importResponseType}
                                showIcon
                                className="mb-2 mt-2"
                                closable
                                onClose={() => this.onSetMessage(null, null)}
                            />
                        ) : null
                    }
                </div>
                <div className='has-custom-column-table-btn'>
                    <DynamicTable
                        rowKey="id"
                        dataSource={customerList || []}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        moduleKey="customer_potential_list"
                        className='has-table-summary'
                        onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
                    />
                </div>
                <CustomerPotentialForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => false}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <CustomerPotentialDetail
                    visible={isOpenDetail}
                    currentItem={currentItem}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                    loadingDetail={loadingDetail}
                />
                <ImportExcelForm
                    visible={isOpenFormImport}
                    onCancel={() => this.setState({ isOpenFormImport: false })}
                    onSetMessage={this.onSetMessage}
                    reloadData={() => this.reloadData()}
                />

                <CreateCustomerProfileForm
                    visible={isOpenFormCreateCustomer}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.setState({ isOpenFormCreateCustomer: false })}
                />
                {/* <Stringee
                    callToNumber={callToNumber}
                    callToAlias={callToAlias}
                    isModalOpen={openPopover}
                    handleCancel={() => this.setState({ openPopover: false })}
                /> */}
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        customerList: state.CustomerPotentialReducer.customerList,
        pagination: state.CustomerPotentialReducer.pagination,
        list_name: state.CustomerPotentialReducer.list_name,
        list_provinces: state.RegionsReducer.list_provinces,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList,
        users: state.config.users,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerPotential: (filter) => dispatch(getListCustomerPotential(filter)),
        getSpecificCustomerPotential: (id) => dispatch(getSpecificCustomerPotential(id)),
        removeCustomerPotential: (ids) => dispatch(removeCustomerPotential(ids)),
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter)),
        getListName: (filter) => dispatch(getListName(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerPotentialList' })(CustomerPotentialList));

