import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Descriptions, Divider, Tag, Row, Col, Image, Space, Card, Collapse, Empty, Button, Modal } from 'antd'
import { DeleteOutlined, EditOutlined, PrinterOutlined } from '@ant-design/icons';
import { checkPermission } from '../../../utils/permission';
import { isValidDate } from '../../../utils/helpers';
import HtmlParser from 'react-html-parser';
import { removeExamVisit, getSpecificExamVisit } from '../../../redux/actions/CustomerServicesActions';
import ExaminationVisitEditForm from './ExaminationVisitEditForm';
import ExaminationVisitAddForm from './ExaminationVisitAddForm';
import PrintTable from './PrintTable';

const { Panel } = Collapse;
const { confirm } = Modal;

class CustomerServicesList extends Component {
    constructor() {
        super();
        this.state = {
            currentData: null,
            isOpenEditExamVisitForm: false,
            isOpenAddExamVisitForm: false,
            customerServiceId: null
        };

        this.printTableRef = React.createRef();
    }

    handleCancel() {
        this.setState({ isOpenEditExamVisitForm: false });
    }

    handleAddExaminationVisit(visible, customerServiceId = null) {
        this.setState({ isOpenAddExamVisitForm: visible, customerServiceId: customerServiceId });
    }

    handleEditExaminationVisit(id) {
        this.props.getSpecificExamVisit(id).then(res => {
            this.setState({ isOpenEditExamVisitForm: true, currentData: res });
            console.log(res);
        });
    }

    handleDeleteExaminationVisit(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeExamVisit([id]).then(() => {
                setTimeout(() => {
                    this.props.reloadData();
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    handlePrint = async (id) => {
        const res = await this.props.getSpecificExamVisit(id);
        this.setState({ currentData: res }, () => {
            const content = document.getElementById('custom-print-content').innerHTML;
            const printWindow = window.open('', '', 'width=900,height=800');
            printWindow.document.open();
            printWindow.document.write('<html><head><title>Print</title></head><body>');
            printWindow.document.write(content);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        });
    };

    render() {
        var { isOpenEditExamVisitForm, isOpenAddExamVisitForm, currentData, customerServiceId } = this.state;
        var { currentItem } = this.props;

        return (
            <div className='customer-data-assistant-service-list-detail'>
                <Divider orientation="left" orientationMargin="0" className='mt-4 mb-3'>
                    <Tag color='#0b2b33'>2</Tag> Dịch vụ đã chốt
                </Divider>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Collapse
                            expandIconPosition="end"
                            ghost
                            defaultActiveKey={[]}
                        >
                            {currentItem.services_selected.length > 0 ? currentItem.services_selected.map((item, index) => {
                                return (
                                    <Panel
                                        key={index}
                                        header={<strong>{index + 1} . {item.service_name}</strong>}
                                    >
                                        <Row gutter={16}>
                                            <Col xs={24} md={24} className='mb-2'>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Ghi chú">
                                                        {HtmlParser(item.note)}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            </Col>
                                            <Col xs={24} md={12} className='mb-2'>
                                                <Card size='small' className='border-dashed'>
                                                    <h6 className='h6-title'>Ảnh before</h6>
                                                    {
                                                        Array.isArray(item.condition_before) && item.condition_before.length ? (
                                                            <Image.PreviewGroup>
                                                                <Space wrap>
                                                                    {
                                                                        item.condition_before.map((item, index) => {
                                                                            return (
                                                                                <Image
                                                                                    key={index}
                                                                                    height={40}
                                                                                    width={40}
                                                                                    src={item?.src}
                                                                                    style={{
                                                                                        border: '1px solid #dedede',
                                                                                        objectFit: 'cover',
                                                                                        objectPosition: 'center',
                                                                                    }}
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                </Space>
                                                            </Image.PreviewGroup>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Card size='small' className='border-dashed'>
                                                    <h6 className='h6-title'>Ảnh after</h6>
                                                    {
                                                        Array.isArray(item.condition_after) && item.condition_after.length ? (
                                                            <Image.PreviewGroup>
                                                                <Space wrap>
                                                                    {
                                                                        item.condition_after.map((item, index) => {
                                                                            return (
                                                                                <Image
                                                                                    key={index}
                                                                                    height={40}
                                                                                    width={40}
                                                                                    src={item?.src}
                                                                                    style={{
                                                                                        border: '1px solid #dedede',
                                                                                        objectFit: 'cover',
                                                                                        objectPosition: 'center',
                                                                                    }}
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                </Space>
                                                            </Image.PreviewGroup>
                                                        ) : null
                                                    }
                                                </Card>
                                            </Col>
                                        </Row>
                                        <div className='examination_visit-header'>
                                            <h6 className='h6-title'>Lần khám:</h6>
                                            <Button size='small' type='primary' onClick={() => this.handleAddExaminationVisit(true, item.id)}>Thêm lần khám</Button>
                                        </div>
                                        {
                                            item?.examination_visit && item?.examination_visit.length ? (
                                                <>
                                                    <Row gutter={16}>
                                                        {item.examination_visit.map((item2, index) => {
                                                            return (
                                                                <Col key={index} xs={24} md={12} className='mb-2'>
                                                                    <Card
                                                                        extra={<>
                                                                            {checkPermission('customer_services', 'exam_visit_remove') && (<>
                                                                                <span onClick={() => this.handleDeleteExaminationVisit(item2.id)} className='delete-icon'><DeleteOutlined /> Xóa</span>
                                                                                <Divider type="vertical" />
                                                                            </>)}
                                                                            {checkPermission('customer_services', 'update_exam_visit') && (<>
                                                                                <span onClick={() => this.handleEditExaminationVisit(item2.id)} className='item-action-btn'><EditOutlined /> Sửa</span>
                                                                                <Divider type="vertical" />
                                                                            </>)}
                                                                            <Button size='small' type='dashed' onClick={() => this.handlePrint(item2.id)} icon={<PrinterOutlined />}> In
                                                                                <span id="custom-print-content" style={{ display: 'none' }}>
                                                                                    <PrintTable ref={this.printTableRef} tableData={currentData} />
                                                                                </span>
                                                                            </Button>
                                                                        </>}
                                                                        size="small"
                                                                        className='card-service-detail'
                                                                    >
                                                                        <Row gutter={16}>
                                                                            <Col xs={24} md={24}>
                                                                                <Descriptions size="small">
                                                                                    <Descriptions.Item label="Thời điểm">
                                                                                        {isValidDate(item2.time)}
                                                                                    </Descriptions.Item>
                                                                                </Descriptions>
                                                                            </Col>
                                                                            <Col xs={24} md={24}>
                                                                                <Descriptions size="small">
                                                                                    <Descriptions.Item label="Bác sĩ">
                                                                                        {item2.doctor_charge_id}
                                                                                    </Descriptions.Item>
                                                                                </Descriptions>
                                                                            </Col>
                                                                            <Col xs={24} md={24}>
                                                                                <Descriptions size="small">
                                                                                    <Descriptions.Item label="Phụ tá">
                                                                                        {item2.assistant_doctor_id}
                                                                                    </Descriptions.Item>
                                                                                </Descriptions>
                                                                            </Col>
                                                                            <Col xs={24} md={24}>
                                                                                <h6 className="h6-title">Kết quả khám:</h6>
                                                                                {HtmlParser(item2.note)}
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </>
                                            ) : (
                                                <div style={{ textAlign: 'center' }}>
                                                    <Empty imageStyle={{ height: 30 }} style={{ margin: '10px 0' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </div>
                                            )
                                        }
                                    </Panel>
                                )
                            }) : (
                                <div style={{ textAlign: 'center' }}>
                                    <Empty imageStyle={{ height: 30 }} style={{ margin: '10px 0' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                            )}
                        </Collapse>
                    </Col>
                </Row>
                <ExaminationVisitEditForm
                    visible={isOpenEditExamVisitForm}
                    onCancel={() => this.handleCancel()}
                    currentExaminationVisit={currentData}
                    reloadData={() => this.props.reloadData()}
                />

                <ExaminationVisitAddForm
                    visible={isOpenAddExamVisitForm}
                    onCancel={() => this.handleAddExaminationVisit(false)}
                    customerServiceId={customerServiceId}
                    reloadData={() => this.props.reloadData()}
                />
            </div >
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        removeExamVisit: (ids) => dispatch(removeExamVisit(ids)),
        getSpecificExamVisit: (id) => dispatch(getSpecificExamVisit(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerServicesList);