import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, TreeSelect, InputNumber, DatePicker, Modal, Collapse, Descriptions, Divider, Empty, Checkbox, Card, Spin } from 'antd';
import { DeleteOutlined, FormOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import { createService } from '../../redux/actions/CustomerServicesActions';
import { getSpecificService } from '../../redux/actions/ServicesActions';
import { makeTree, customDataImageUpload, _newLine, isValidDate } from '../../utils/helpers';
import moment from 'moment';
import UploadImage from '../../components/UploadImage';
import CurrentImage from './items/CurrentImage';
import Consultant from './items/Consultant';
import ExaminationVisit from './items/ExaminationVisit';
import BulkUpdateServiceForm from './items/BulkUpdateServiceForm';

const { Panel } = Collapse;
const { confirm } = Modal;

class CustomerServiceAddForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            saveLoading: false,
            resetUploadImagesBefore: true,
            resetUploadImagesAfter: true,
            currentConsultant: [],
            selectedItems: [],
            selectAll: false,
            items: [],
            visibleConsultant: false,
            currentServiceId: null,
            currentExaminationVisit: [],
            visibleExaminationVisit: false,
            visibleBulkUpdate: false,
            selectedDate: moment(),
        };
    }

    async handleSave(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.images_before;
            delete values.images_after;
            delete values.selected_service_id;
            if (!err) {
                this.setState({ saveLoading: true });
                try {
                    const { items } = this.state;
                    const { currentCustomer } = this.props;

                    const data = items.map(item => {
                        return {
                            ...item,
                            images_before: customDataImageUpload(item.images_before, item.bulk_images_before),
                            images_after: customDataImageUpload(item.images_after, item.bulk_images_after)
                        }
                    });

                    values.items = data;
                    values.service_type = 'CHOT';

                    values.customer_id = currentCustomer ? currentCustomer?.id : values.customer_id;
                    await this.props.createService(values);
                    this.props.reloadData();
                    this.onClose();

                    console.log(values);
                } catch (error) {
                    this.setState({ saveLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            saveLoading: false,
            resetUploadImagesBefore: true,
            resetUploadImagesAfter: true,
            items: [],
            selectedItems: [],
            selectAll: false,
            selectedDate: moment(),
        });
    }

    onChangeService = async (id) => {
        this.setState({ isLoading: true });
        await this.props.getSpecificService(id).then((item) => {
            let new_items = {
                id: item.id,
                name: item.name,
                regular_price: item.price,
                sale_price: item.price,
                quantity: 1,
                amount: item.price * 1,
                note: null,
                examination_visit: [],
                consultant: [],
                images_before: [],
                images_after: [],
            };

            // Kiểm tra xem item đã tồn tại trong state.items chưa
            let isUpdating = this.props.currentData;

            this.setState({
                items: isUpdating
                    ? [new_items].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
                    : [...this.state.items, new_items].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                isLoading: false
            }, () => {
                this.calculateTotalAmount(); // Gọi sau khi setState để đảm bảo rằng state đã được cập nhật
            });
        }).catch(err => {
            this.setState({ isLoading: false });
        });
        this.calculateTotalAmount(); // Tính toán lại total_amount và debt
    };


    calculateTotalAmount = () => {
        let total_amount = 0;
        this.state.items.forEach(item => {
            total_amount += item.sale_price * item.quantity;
        });
        this.props.form.setFieldsValue({
            total_amount: total_amount
        });
    };

    async onChangeItem(name, value, itemId) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.id == itemId);
        let currentItem = items[index];

        // Cập nhật giá trị cho thuộc tính đang thay đổi
        currentItem[name] = value;

        // Nếu thay đổi quantity hoặc sale_price thì tính lại amount
        if (name === 'quantity' || name === 'sale_price') {
            currentItem.amount = this.calculateAmount(currentItem);
            this.calculateTotalAmount();
        }

        if (name === 'amount') {
            currentItem.sale_price = Math.round(currentItem.amount / currentItem.quantity);
        }

        //console.log('currentItem', currentItem);

        // Cập nhật lại items và render lại
        items[index] = currentItem;
        this.renderNewItem(items);
    }

    renderNewItem(items = []) {
        let new_items = items.map((item, index) => {
            return {
                ...item,
                note: _newLine(item.note)
            }
        })
        // Cập nhật state với các giá trị mới
        this.setState({ items: new_items }, () => {
            // Tính toán và cập nhật các giá trị cần thiết
            let total_amount = new_items.reduce((total, item) => total + item.amount, 0);
            this.props.form.setFieldsValue({
                total_amount: total_amount
            });
        });
    }

    calculateAmount(item) {
        return item.quantity * item.sale_price;
    }

    onDelete(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Xóa dịch vụ này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => {
                let items = this.state.items.filter(item => {
                    return item.id != id;
                });
                this.renderNewItem(items)
            },
            onCancel() { },
        })
    }

    handleSelectAll = (e) => {
        const checked = e.target.checked;
        this.setState({
            selectAll: checked,
            selectedItems: checked ? this.state.items.map(item => item.id) : [],
        });
    };

    handleItemSelect = (itemId, checked) => {
        this.setState(prevState => {
            const selectedItems = checked
                ? [...prevState.selectedItems, itemId]
                : prevState.selectedItems.filter(id => id !== itemId);
            return {
                selectedItems,
                selectAll: selectedItems.length === this.state.items.length
            };
        });
    };

    addConsultant = (serviceId) => {
        // Tìm đối tượng trong items có id trùng với serviceId
        const consultantData = this.state.items.find(item => item.id === serviceId)?.consultant;
        if (consultantData) {
            this.setState({ currentConsultant: consultantData });
        } else {
            console.log("Không tìm thấy consultant cho serviceId này.");
        }
        this.setState({ visibleConsultant: true, currentServiceId: serviceId });
    };

    addExaminationVisit = (serviceId) => {
        // Tìm đối tượng trong items có id trùng với serviceId
        const examinationVisitData = this.state.items.find(item => item.id === serviceId)?.examination_visit;
        if (examinationVisitData) {
            this.setState({ currentExaminationVisit: examinationVisitData });
        } else {
            console.log("Không tìm thấy examination visit cho serviceId này.");
        }
        this.setState({ visibleExaminationVisit: true, currentServiceId: serviceId });
    }

    getExaminationVisit = (data) => {
        const { currentServiceId } = this.state;
        const { config } = this.props;

        // Lặp qua data và bổ sung trường full_name cho từng doctor_charge_id, assistant_doctor_id trong mảng
        const updatedData = data.map(item => {
            const doctors = item.doctor_charge_id.map(doctorId => {
                const user = config?.users?.find(user => user.user_id === doctorId);
                return user ? user.full_name : '';
            });

            const assistant = item.assistant_doctor_id.map(assistantId => {
                const user = config?.users?.find(user => user.user_id === assistantId);
                return user ? user.full_name : '';
            });

            return {
                ...item,
                doctor_charge_names: doctors,
                assistant_doctor_names: assistant
            };
        });

        // Cập nhật state với mảng data đã được bổ sung trường full_name
        this.setState((prevState) => {
            const updatedItems = prevState.items.map(item =>
                item.id === currentServiceId ? { ...item, examination_visit: updatedData } : item
            );
            return {
                items: updatedItems,
                examination_visit: updatedData
            };
        }, () => {
            NotificationManager.success("Cập nhật lần khám thành công.");
        });
    };

    getConsultant = (data) => {
        const { currentServiceId } = this.state;
        const { config } = this.props;

        // Lặp qua data và bổ sung trường full_name từ config.users
        const updatedData = data.map(item => {
            const user = config?.users?.find(user => user.user_id === item.user_id);
            return {
                ...item,
                full_name: user ? user.full_name : ''  // Nếu tìm thấy user thì lấy full_name, nếu không thì để trống
            };
        });

        // Cập nhật state với mảng data đã được bổ sung trường full_name
        this.setState((prevState) => {
            const updatedItems = prevState.items.map(item =>
                item.id === currentServiceId ? { ...item, consultant: updatedData } : item
            );
            return {
                items: updatedItems,
                consultant: updatedData
            };
        }, () => {
            NotificationManager.success("Cập nhật nhân viên tư vấn thành công.");
        });
    };

    onChangeImagesBefore = (data, itemId) => {
        this.setState((prevState) => {
            const updatedItems = prevState.items.map(item =>
                item.id === itemId ? { ...item, images_before: data } : item
            );
            return {
                items: updatedItems,
                resetUploadImagesBefore: false,
                images_before: data
            };
        }, () => {
            NotificationManager.success("Tải ảnh before thành công.");
        });
    };

    onChangeImagesAfter = (data, itemId) => {
        this.setState((prevState) => {
            const updatedItems = prevState.items.map(item =>
                item.id === itemId ? { ...item, images_after: data } : item
            );
            return {
                items: updatedItems,
                resetUploadImagesAfter: false,
                images_after: data
            };
        }, () => {
            NotificationManager.success("Tải ảnh after thành công.");
        });
    }

    openBulkUpdate = () => {
        const { selectedItems, items } = this.state;
        const selectedItemsData = items.filter(item => selectedItems.includes(item.id));

        if (selectedItemsData.length > 0) {
            this.setState({ visibleBulkUpdate: true });
        } else {
            NotificationManager.error("Vui lòng chọn ít nhất một dịch vụ để cập nhật.");
        }
    }

    getBulkUpdate = (data) => {
        const { selectedItems, items } = this.state;
        const updatedItems = items.map(item => {
            if (selectedItems.includes(item.id)) {
                return {
                    ...item,
                    // sale_price: data.sale_price,
                    // quantity: data.quantity,
                    // amount: data.sale_price * data.quantity,
                    // note: data.note,
                    consultant: data.consultant,
                    examination_visit: data.examination_visit,
                    // bulk_images_before: data.images_before,
                    // bulk_images_after: data.images_after,

                    // Reset lại các trường images_before, images_after để tránh trường hợp khi cập nhật ảnh mới thì ảnh cũ vẫn còn
                    // images_before: [],
                    // images_after: []
                }
            }
            return item;
        });

        // Reset lại trạng thái của UploadImage, trương hợp đã tải ảnh trước đó
        this.setState({ resetUploadImagesAfter: true, resetUploadImagesBefore: true });

        this.setState({ items: updatedItems }, () => {
            this.calculateTotalAmount();
            NotificationManager.success("Cập nhật tất cả dịch vụ thành công.");
        });
    }

    getImages(data) {
        this.setState((prevState) => {
            const updatedItems = prevState.items.map(item =>
                item.id === data.itemKey
                    ? {
                        ...item,
                        ...(data.filedName === 'images_before'
                            ? { bulk_images_before: data.data }
                            : { bulk_images_after: data.data }
                        )
                    }
                    : item
            );
            return {
                items: updatedItems
            };
        });
    }

    handleDateChange = (value) => {
        this.setState({ selectedDate: value });
    };

    render() {
        var { isLoading, items, selectAll, selectedItems, resetUploadImagesBefore,
            resetUploadImagesAfter, saveLoading,
            currentConsultant, currentExaminationVisit } = this.state;
        var {
            visible,
            service_active_list,
            currentCustomer
        } = this.props;
        const { getFieldDecorator } = this.props.form;
        const service = makeTree(service_active_list || [], 0);

        var total_amount = 0;
        items.forEach(item => {
            total_amount += item.sale_price;
        });


        return (
            <Drawer
                visible={visible}
                title={`Thêm dịch vụ chốt`}
                onOk={(e) => this.handleSave(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={saveLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.handleSave(e)}
                            >
                                Lưu
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical' className='customer-data-customer-service-form'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> <span className='color-primary'>{`${currentCustomer?.full_name} - ${currentCustomer?.code}`}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={14}>
                                <Form.Item label='Dịch vụ'>
                                    {getFieldDecorator("selected_service_id", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <TreeSelect
                                            showSearch
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                            treeData={service}
                                            treeNodeFilterProp='title'
                                            allowClear
                                            placeholder="Chọn một bản ghi"
                                            treeDefaultExpandAll
                                            onChange={this.onChangeService}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={10}>
                                <Form.Item label="Ngày chốt">
                                    {getFieldDecorator('selected_at', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: moment()
                                    })(
                                        <DatePicker
                                            className='w-100'
                                            format="DD/MM/YYYY"
                                            onChange={this.handleDateChange}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24} className='mb-2'>
                                <h6 className='h6-title'>Dịch vụ đã chọn</h6>
                                <Spin tip="Loading..." spinning={isLoading}>
                                    {items.length > 0 && (
                                        <div className='customer-service-form-header-action'>
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={this.handleSelectAll}
                                                className='mb-2 mt-2'
                                                style={{ marginLeft: 10 }}
                                            >
                                                Chọn tất cả
                                            </Checkbox>

                                            <Button
                                                disabled={!selectedItems.length ? true : false}
                                                onClick={() => this.openBulkUpdate()}
                                                type='primary'
                                                icon={<FormOutlined />}
                                                size='small'>Cập nhật tất cả</Button>
                                        </div>
                                    )}

                                    <Collapse
                                        expandIconPosition="end"
                                        ghost
                                        defaultActiveKey={[]}
                                    >
                                        {items.length > 0 ? items.map((item, index) => {
                                            const isSelected = selectedItems.includes(item.id);

                                            return (
                                                <Panel header={
                                                    <>
                                                        <Checkbox
                                                            checked={isSelected}
                                                            onClick={(e) => e.stopPropagation()} // Ngăn không cho mở Collapse khi bấm vào Checkbox
                                                            onChange={(e) => this.handleItemSelect(item.id, e.target.checked)}
                                                            style={{ marginRight: 8 }}
                                                        />
                                                        <span className="chingo-normal-title">
                                                            {index + 1}. {item.name}
                                                        </span>
                                                    </>
                                                } key={item.id}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Số lượng">
                                                            <InputNumber
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                value={item.quantity}
                                                                name="quantity"
                                                                min={1}
                                                                className='w-100'
                                                                onChange={(value) => {
                                                                    // Kiểm tra giá trị và thay thế nếu cần thiết
                                                                    const validValue = value == null || value <= 0 ? 1 : value;
                                                                    this.onChangeItem('quantity', validValue, item.id);
                                                                }}
                                                            />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Giá bán">
                                                            <InputNumber
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                value={item.sale_price}
                                                                name="sale_price"
                                                                min={0}
                                                                className='w-100'
                                                                onChange={(value) => this.onChangeItem('sale_price', value, item.id)}
                                                            />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Thành tiền">
                                                            <InputNumber
                                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                value={item.amount}
                                                                name="amount"
                                                                min={0}
                                                                className='w-100'
                                                                onChange={(value) => this.onChangeItem('amount', value, item.id)}
                                                            />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Ghi chú">
                                                            <Input.TextArea
                                                                value={item.note}
                                                                name="note"
                                                                style={{ height: 16, marginBottom: 0 }}
                                                                onChange={(e) => this.onChangeItem(e.target.name, e.target.value, item.id)}
                                                            />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Card
                                                                size='small'
                                                                title="Nhân viên tư vấn"
                                                                extra={<Button onClick={() => this.addConsultant(item.id)} type='primary' icon={<UserAddOutlined />} size='small'>Cập nhật</Button>}
                                                                className='mt-3'
                                                                headStyle={{ padding: 0, borderBottom: '1px dashed #d9d9d9' }}
                                                                bodyStyle={{ padding: '10px 0px' }}
                                                                bordered={false}
                                                            >
                                                                {item.consultant.length > 0 ? (
                                                                    item.consultant.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <Descriptions size="small" key={index}>
                                                                                    <Descriptions.Item>
                                                                                        {item.full_name} - {isValidDate(item.consultant_date, true)}
                                                                                    </Descriptions.Item>
                                                                                </Descriptions>
                                                                                <Divider className="no-margin" />
                                                                            </>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <div style={{ textAlign: 'center', padding: 0 }} className="ant-list-empty-text">
                                                                        Chưa có dữ liệu.
                                                                    </div>
                                                                )}
                                                            </Card>
                                                        </Col>

                                                        <Col xs={24} md={24} className='examination-visit'>
                                                            <Card
                                                                size='small'
                                                                title="Lần khám"
                                                                extra={<Button onClick={() => this.addExaminationVisit(item.id)} type='primary' icon={<UserAddOutlined />} size='small'>Cập nhật</Button>}
                                                                className='mt-3'
                                                                headStyle={{ padding: 0, borderBottom: '1px dashed #d9d9d9' }}
                                                                bodyStyle={{ padding: 0 }}
                                                                bordered={false}
                                                            >
                                                                {item.examination_visit.length > 0 ? (
                                                                    <>
                                                                        <Collapse
                                                                            expandIconPosition="end"
                                                                            ghost
                                                                            defaultActiveKey={[]}
                                                                        >
                                                                            {
                                                                                item.examination_visit.map((item, index) => {
                                                                                    return (
                                                                                        <Panel header={
                                                                                            <>
                                                                                                <span className="chingo-normal-title">
                                                                                                    {isValidDate(item.time, true)}
                                                                                                </span>
                                                                                            </>
                                                                                        } key={index}>
                                                                                            <Divider className='no-margin' orientation="left" orientationMargin="0">Bác sĩ khám</Divider>
                                                                                            {item.doctor_charge_names.length > 0 ? (
                                                                                                <ul className='examination-visit-doctor-list'>
                                                                                                    {item.doctor_charge_names.map((doctor, index) => {
                                                                                                        return (
                                                                                                            <li key={index}>{doctor}</li>
                                                                                                        );
                                                                                                    })}
                                                                                                </ul>
                                                                                            ) : null}
                                                                                            <Divider className='no-margin' orientation="left" orientationMargin="0">Phụ tá</Divider>
                                                                                            {item.assistant_doctor_names.length > 0 ? (
                                                                                                <ul className='examination-visit-doctor-list'>
                                                                                                    {item.assistant_doctor_names.map((assistant, index) => {
                                                                                                        return (
                                                                                                            <li key={index}>{assistant}</li>
                                                                                                        );
                                                                                                    })}
                                                                                                </ul>
                                                                                            ) : null}
                                                                                        </Panel>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </Collapse>
                                                                    </>
                                                                ) : (
                                                                    <div style={{ textAlign: 'center', padding: 0 }} className="ant-list-empty-text">
                                                                        Chưa có dữ liệu.
                                                                    </div>
                                                                )}
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={16} className='mt-4'>
                                                        <Col xs={24} md={12}>
                                                            <Form.Item label="Ảnh before" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                                                <CurrentImage
                                                                    filedName="images_before"
                                                                    currentImages={item.bulk_images_before}
                                                                    itemKey={item.id}
                                                                    getData={(data) => this.getImages(data)}
                                                                />
                                                                {getFieldDecorator('images_before', {
                                                                    rules: [
                                                                        {
                                                                            required: false,
                                                                            message: 'Vui lòng điền!',
                                                                        },
                                                                    ],
                                                                    initialValue: null
                                                                })(
                                                                    <UploadImage
                                                                        uploadText="Chọn ảnh"
                                                                        multiple={true}
                                                                        maxCount={50}
                                                                        onChangeData={(data) => this.onChangeImagesBefore(data, item.id)}
                                                                        isResetUpload={resetUploadImagesBefore}
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <Form.Item label="Ảnh after" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                                                <CurrentImage
                                                                    filedName="images_after"
                                                                    currentImages={item.bulk_images_after}
                                                                    itemKey={item.id}
                                                                    getData={(data) => this.getImages(data)}
                                                                />
                                                                {getFieldDecorator('images_after', {
                                                                    rules: [
                                                                        {
                                                                            required: false,
                                                                            message: 'Vui lòng điền!',
                                                                        },
                                                                    ],
                                                                    initialValue: null
                                                                })(
                                                                    <UploadImage
                                                                        uploadText="Chọn ảnh"
                                                                        multiple={true}
                                                                        maxCount={50}
                                                                        onChangeData={(data) => this.onChangeImagesAfter(data, item.id)}
                                                                        isResetUpload={resetUploadImagesAfter}
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Descriptions size="small" style={{ textAlign: 'right', marginTop: 10 }}>
                                                        <div>
                                                            <Button onClick={() => this.onDelete(item.id)} type='danger' icon={<DeleteOutlined />} size='small'>Xóa</Button>
                                                        </div>
                                                    </Descriptions>
                                                    <Divider className="no-margin" />
                                                </Panel>
                                            )
                                        }) : (
                                            <div style={{ textAlign: 'center' }}>
                                                <Empty imageStyle={{ height: 30 }} style={{ margin: '10px 0' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </div>
                                        )}

                                    </Collapse>
                                    {items.length > 0 && (
                                        <div className='mt-3' style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '600' }}>
                                            <div style={{ marginRight: '10px' }}>Tổng giá trị:</div>
                                            <div>
                                                {getFieldDecorator('total_amount', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: total_amount
                                                })(
                                                    <InputNumber
                                                        disabled
                                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        className="w-100 custom-input-number"
                                                        step={100000}
                                                        addonAfter="đ"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Spin>
                            </Col>
                        </Row>

                        <Consultant
                            visible={this.state.visibleConsultant}
                            onCancel={() => this.setState({ visibleConsultant: false })}
                            getConsultant={this.getConsultant}
                            currentConsultant={currentConsultant}
                            currentDate={this.state.selectedDate}
                        />

                        <ExaminationVisit
                            visible={this.state.visibleExaminationVisit}
                            onCancel={() => this.setState({ visibleExaminationVisit: false })}
                            getExaminationVisit={this.getExaminationVisit}
                            currentExaminationVisit={currentExaminationVisit}
                            currentDate={this.state.selectedDate}
                        />

                        <BulkUpdateServiceForm
                            visible={this.state.visibleBulkUpdate}
                            onCancel={() => this.setState({ visibleBulkUpdate: false })}
                            getBulkUpdate={this.getBulkUpdate}
                            currentDate={this.state.selectedDate}
                        />

                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        service_active_list: state.ServiceReducer.service_active_list,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createService: (data) => dispatch(createService(data)),
        getSpecificService: (id) => dispatch(getSpecificService(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerServiceAddForm' })(CustomerServiceAddForm));
