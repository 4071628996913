import React, { Component } from 'react';
import { isValidDate } from '../../../utils/helpers';
import HtmlParser from 'react-html-parser';

class PrintTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { tableData } = this.props;

        return (
            <div className="container">
                <div>
                    <strong>Thời điểm khám:</strong> {isValidDate(tableData?.time, true)}
                </div>
                <div>
                    <strong>Bác sĩ:</strong> {tableData?.doctor_charge || ''}
                </div>
                <div>
                    <strong>Phụ tá:</strong> {tableData?.assistant_doctor || ''}
                </div>
                <div>
                    <strong>Kết quả khám:</strong> {HtmlParser(tableData?.note || '')}
                </div>
                <style>
                    {`
                    @media print {
                        @page {
                            size: A5;
                            margin: 0;
                        }

                        body {
                            font-family: Arial, sans-serif;
                            font-size: 12px;
                            line-height: 1.5;
                            color: #000;
                            margin: 0;
                        }

                        .container {
                            width: 100%;
                            max-width: 140mm;
                            margin: 0 auto;
                            padding: 0;
                            border: 1px solid #ddd;
                            background-color: #fff;
                            box-sizing: border-box;
                        }

                        .container div {
                            margin-bottom: 0;
                        }

                        .container strong {
                            display: inline-block;
                            width: 100px;
                        }

                        /* Ẩn các phần không cần in */
                        .no-print {
                            display: none !important;
                        }
                    }

                    /* Định dạng trong chế độ xem thông thường */
                    .container {
                        width: 100%;
                        max-width: 140mm;
                        margin: 20px auto;
                        padding: 10mm;
                        border: 1px solid #ddd;
                        background-color: #fff;
                    }
                    `}
                </style>
            </div>
        );
    }
}

export default PrintTable;
