import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Button, Row, Col, Space } from 'antd'
import { EditOutlined, DownloadOutlined, FileMarkdownOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import DocumentForm from './DocumentForm';
import { downloadFromLink, isValidDate } from '../../utils/helpers';

class DocumentDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenForm: false,
            currentData: null
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenForm(isOpenForm) {
        this.setState({ isOpenForm: isOpenForm });
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }
    render() {
        var { isOpenForm } = this.state;
        var { visible, currentData } = this.props;

        return (
            <>
                <Drawer
                    visible={visible}
                    title={`# ${currentData ? currentData.name : ''}`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '50%'}
                    extra={
                        checkPermission('documents', 'update') ? (
                            <Button type="primary" onClick={() => this.toggleOpenForm(true)} icon={<EditOutlined />}>Sửa</Button>
                        ) : null
                    }
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Loại văn bản">
                                            {currentData?.category?.name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tên văn bản">
                                            {currentData?.name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Số văn bản">
                                            {currentData?.document_number}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày ban hành">
                                            {isValidDate(currentData.issue_date, false)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày hết hạn">
                                            {isValidDate(currentData.expiry_date, false)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Trạng thái">
                                            {
                                                currentData && (
                                                    currentData.status === 1
                                                        ? <> <CheckCircleTwoTone twoToneColor="#52c41a" /> Active </>
                                                        : currentData.status === 0
                                                            ? <> <CloseCircleTwoTone twoToneColor="#e74c3c" /> Inactive </>
                                                            : null
                                                )
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentData.created_at, true)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người tạo">
                                            {currentData?.created_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tệp gốc">
                                            {
                                                currentData?.original_documents?.length > 0 && (
                                                    <Space wrap>
                                                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }} >
                                                            {currentData.original_documents.map((item, index) => (
                                                                <li key={index}>
                                                                    <FileMarkdownOutlined /> {item.name} &nbsp;
                                                                    <DownloadOutlined
                                                                        onClick={() => this.onDownload(item.data)}
                                                                        className='color-blue'
                                                                    />
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Space>
                                                )
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tệp đã ký">
                                            {
                                                currentData?.signed_documents?.length > 0 && (
                                                    <Space wrap>
                                                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }} >
                                                            {currentData.signed_documents.map((item, index) => (
                                                                <li key={index}>
                                                                    <FileMarkdownOutlined /> {item.name} &nbsp;
                                                                    <DownloadOutlined
                                                                        onClick={() => this.onDownload(item.data)}
                                                                        className='color-blue'
                                                                    />
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Space>
                                                )
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Mô tả nội dung">
                                            {currentData?.description && ReactHtmlParser(currentData.description)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ghi chú">
                                            {currentData?.note && ReactHtmlParser(currentData.note)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Nơi lưu bản gốc">
                                            {currentData?.file_location && ReactHtmlParser(currentData.file_location)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                            </Row>
                        </div>
                    ) : null}

                </Drawer>
                <DocumentForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'DocumentDetail' })(DocumentDetail));
