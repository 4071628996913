import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Input, Button, Popconfirm, DatePicker, Select, Space, Card, Row, Col, Form, Empty, Drawer } from "antd";
import { DeleteOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';
import { createExamVisit } from '../../../redux/actions/CustomerServicesActions';

const { Option } = Select;

const ExaminationVisitAddForm = (props) => {

    const { config, customerServiceId, visible, onCancel } = props;

    const [items, setItems] = useState([{
        key: 0,
        name: "",
        time: moment(),
        doctor_charge_id: [],
        assistant_doctor_id: [],
        note: ""
    }]);
    const [count, setCount] = useState(0);

    const onAdd = () => {
        const newIndex = count + 1;
        const newItem = {
            key: newIndex,
            name: "",
            time: moment(),
            doctor_charge_id: [],
            assistant_doctor_id: [],
            note: ""
        };
        setItems([...items, newItem]);
        setCount(newIndex);
    };

    const onDelete = (key) => {
        const updatedItems = items.filter(item => item.key !== key);
        setItems(updatedItems);
    };

    const onChangeItem = (name, value, itemKey) => {
        const updatedItems = items.map(item =>
            item.key === itemKey ? { ...item, [name]: value } : item
        );
        setItems(updatedItems);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const allHaveValidUserId = items.every(item =>
            Array.isArray(item.doctor_charge_id) &&
            item.doctor_charge_id.length > 0 &&
            item.doctor_charge_id.every(id => id != null && id !== "")
        );

        if (allHaveValidUserId) {
            const params = {
                customer_service_id: customerServiceId,
                items: items
            }
            console.log('params', params);
            await props.createExamVisit(params);
            props.reloadData();
            setItems([{
                key: 0,
                name: "",
                time: moment(),
                doctor_charge_id: [],
                assistant_doctor_id: [],
                note: ""
            }]);
            onCancel();
        } else {
            NotificationManager.error("Vui lòng chọn Bác sĩ cho tất cả các mục trước khi cập nhật.");
        }
    };

    const onClose = () => {
        setItems([{
            key: 0,
            name: "",
            time: moment(),
            doctor_charge_id: [],
            assistant_doctor_id: [],
            note: ""
        }]);
        onCancel();
    }

    return (
        <>
            <Drawer
                visible={visible}
                title="Thêm lần khám"
                onOk={(e) => handleSave(e)}
                onClose={() => onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                bodyStyle={{ padding: '10px 20px' }}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) => handleSave(e)}
                            >
                                Lưu
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <div>
                        {items.length > 0 ? (
                            <div>
                                {items.map((item, index) => (
                                    <Card key={item.key}
                                        size='small'
                                        className="mb-2 service-card-box"
                                        bordered={false}
                                        bodyStyle={{ padding: 0 }}
                                        headStyle={{ padding: 0 }}
                                        title={`Lần khám: ${index + 1}`}
                                        extra={<>
                                            <Popconfirm
                                                title="Xóa lần khám này?"
                                                onConfirm={() => onDelete(item.key)}
                                                placement="topLeft"
                                            >
                                                <DeleteOutlined className='delete-icon' />
                                            </Popconfirm>
                                        </>}
                                    >
                                        <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <Form.Item>
                                                    <Select
                                                        mode='multiple'
                                                        style={{ width: '100%' }}
                                                        placeholder="Chọn 1 hoặc nhiều bác sĩ"
                                                        optionLabelProp="label"
                                                        allowClear={true}
                                                        showSearch={true}
                                                        value={item.doctor_charge_id || []}
                                                        onChange={(value) => onChangeItem('doctor_charge_id', value, item.key)}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {
                                                            config && config?.doctors ? (
                                                                <>
                                                                    {
                                                                        config?.doctors.map((item) => {
                                                                            return (
                                                                                <Option key={item.user_id} value={item.user_id} label={item.full_name}>{item.full_name}</Option>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            ) : null
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Form.Item>
                                                    <Select
                                                        mode='multiple'
                                                        style={{ width: '100%' }}
                                                        placeholder="Chọn 1 hoặc nhiều phụ tá"
                                                        optionLabelProp="label"
                                                        allowClear={true}
                                                        showSearch={true}
                                                        value={item.assistant_doctor_id || []}
                                                        onChange={(value) => onChangeItem('assistant_doctor_id', value, item.key)}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {
                                                            config && config?.doctors ? (
                                                                <>
                                                                    {
                                                                        config?.doctors.map((item) => {
                                                                            return (
                                                                                <Option key={item.user_id} value={item.user_id} label={item.full_name}>{item.full_name}</Option>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            ) : null
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Form.Item>
                                                    <DatePicker
                                                        value={item.time || moment()}
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => onChangeItem("time", value, item.key)}
                                                        showTime={{ format: 'HH:mm' }}
                                                        format="DD/MM/YYYY HH:mm"
                                                        placeholder='Chọn thời điểm khám'
                                                        allowClear={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Form.Item style={{ marginBottom: 0 }}>
                                                    <Input.TextArea
                                                        value={item.note}
                                                        rows={10}
                                                        onChange={(e) => onChangeItem("note", e.target.value, item.key)}
                                                        placeholder='Kết quả khám'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </div>
                        ) : (
                            <div style={{ textAlign: 'center' }}>
                                <Empty imageStyle={{ height: 30 }} style={{ margin: '50px 0' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        )}
                        <Button type="primary" size='default' className='w-100' onClick={onAdd} icon={<MedicineBoxOutlined />}>
                            Thêm lần khám
                        </Button>
                    </div>
                    : null}
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => ({
    config: state.config
});

const mapDispatchToProps = (dispatch) => ({
    createExamVisit: (data) => dispatch(createExamVisit(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExaminationVisitAddForm);
