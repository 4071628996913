import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ReloadOutlined, FileMarkdownOutlined, DownloadOutlined } from '@ant-design/icons';
import { Table, Modal, Tooltip, Divider, Button } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';
import DocumentForm from './DocumentForm';
import DocumentDetail from './DocumentDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, downloadFromLink, stripHtml } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import BaseSelect from '../../components/Elements/BaseSelect';
import { getDocumentCategoriesActive } from '../../redux/actions/DocumentCategories';
import { getDocuments, getOneDocument, removeDocuments } from '../../redux/actions/Documents';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/documents',
        breadcrumbName: 'Danh sách văn bản',
    },
]

class DocumentsList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentData: null,
        isOpenDetail: false
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.loadData(query);
        this.props.getDocumentCategoriesActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.loadData(query);
        }
    }

    loadData = (query) => {
        this.setState({ isLoading: true });
        this.props.getDocuments(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentData = null) {
        this.setState({ isOpenForm: isOpenForm, currentData: currentData });
    }

    onEdit(id) {
        this.props.getOneDocument(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeDocuments(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeDocuments(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getOneDocument(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDownload(url) {
        setTimeout(() => {
            const response = {
                file: url,
            };
            downloadFromLink(response.file);
        }, 100);
    }

    render() {
        var { documents, pagination, document_categories_active } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentData, isOpenDetail, current_id } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: '#',
                width: '75px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('documents', 'update') ? (
                                <>
                                    <EditOutlined className='color-primary item-action-btn' onClick={() => this.onEdit(record.id)} />
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {
                            checkPermission('documents', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên văn bản',
                dataIndex: 'name',
                key: 'name',
                width: '300px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }} className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Số văn bản',
                dataIndex: 'document_number',
                key: 'document_number',
                render: (text, record) => {
                    if (checkPermission('documents', 'detail')) {
                        return (
                            <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                {text}
                            </div>
                        )
                    } else {
                        return (<>{text}</>)
                    }
                }
            },
            {
                title: 'Loại văn bản',
                dataIndex: 'category',
                key: 'category',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text?.name)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text?.name)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Mô tả nội dung',
                dataIndex: 'description',
                key: 'description',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Tệp gốc',
                dataIndex: 'original_documents',
                key: 'original_documents',
                width: '300px',
                render: (text, record) => {
                    if (record?.original_documents?.length > 0) {
                        return (
                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }} >
                                {record.original_documents.map((item, index) => (
                                    <li className='color-blue item-action-btn' key={index} onClick={() => this.onDownload(item.data)}>
                                        <DownloadOutlined /> {item.name} &nbsp;
                                    </li>
                                ))}
                            </ul>
                        )
                    }
                }
            },
            {
                title: 'Tệp đã ký',
                dataIndex: 'signed_documents',
                key: 'signed_documents',
                width: '300px',
                render: (text, record) => {
                    if (record?.signed_documents?.length > 0) {
                        return (
                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }} >
                                {record?.signed_documents.map((item, index) => (
                                    <li className='color-blue item-action-btn' key={index} onClick={() => this.onDownload(item.data)}>
                                        <DownloadOutlined /> {item.name} &nbsp;
                                    </li>
                                ))}
                            </ul>
                        )
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: '100px',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh sách văn bản" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('documents', 'create')}
                        isShowDeleteButton={checkPermission('documents', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        <BaseSelect
                            options={document_categories_active || []}
                            optionValue="id"
                            optionLabel="name"
                            placeholder="Chọn một bản ghi"
                            defaultValue={query.categories_id}
                            style={{ width: '200px' }}
                            onChange={(value) => this.onChangeFilter('categories_id', value)}
                            className="table-button"
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={documents || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200'],
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <DocumentForm
                    visible={isOpenForm}
                    currentData={currentData}
                    reloadData={() => this.loadData(query)}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <DocumentDetail
                    visible={isOpenDetail}
                    currentData={currentData}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        documents: state.Documents.documents,
        pagination: state.Documents.pagination,
        document_categories_active: state.DocumentCategories.document_categories_active
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDocuments: (filter) => dispatch(getDocuments(filter)),
        getOneDocument: (id) => dispatch(getOneDocument(id)),
        removeDocuments: (ids) => dispatch(removeDocuments(ids)),
        getDocumentCategoriesActive: () => dispatch(getDocumentCategoriesActive())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList);
