import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tag, Tooltip, Input, Button, Modal, Space, Row, Col, Form, Typography } from 'antd';
import { NotificationManager } from 'react-notifications'
import { createViewPhoneNumber } from './../redux/actions/ViewPhonenumberActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import md5 from 'md5';
import { login } from '../routes/auth/Webauthn';

const { Paragraph } = Typography;


class HandlePhoneNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFullPhoneNumberVisible: false,
            isModalVisible: false,
            password: '',
            correctPassword: this.props.authUser?.pin_code
        };
        this.timeoutId = null;
    }

    handlePhoneNumber = () => {
        this.setState({ isModalVisible: true });
    };

    handlePasswordInput = (e) => {
        this.setState({ password: e.target.value });
    };

    submitPassword = () => {
        const { password, correctPassword } = this.state;
        if (password.trim() === '') {
            NotificationManager.error("Chưa nhập mã pin");
            return;
        }
        if (md5(password) === correctPassword) {
            this.setState({ isFullPhoneNumberVisible: true, isModalVisible: false });

            this.timeoutId = setTimeout(() => {
                this.setState({ isFullPhoneNumberVisible: false });
            }, 15000); // 15000 milliseconds = 15s

            // Lưu log
            const data = {
                customer_id: this.props.customer_id,
                phone: this.props.fullPhoneNumber,
                customer_type: this.props.customer_type
            };
            this.props.createViewPhoneNumber(data);
            this.setState({ password: '' })
        } else {
            NotificationManager.error("Mã pin không đúng");
        }
    };

    handleModalCancel = () => {
        this.setState({ isModalVisible: false, password: '' });
    };

    handleMouseLeave = () => {
        clearTimeout(this.timeoutId);
        this.setState({ isFullPhoneNumberVisible: false });
    };

    handleOutsideClick = (e) => {
        if (this.tagRef && !this.tagRef.contains(e.target)) {
            this.handleMouseLeave();
        }
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }

    handleVeryfy = async () => {
        var { authUser } = this.props;

        const result = await login(authUser);
        if (result) {
            this.setState({ isFullPhoneNumberVisible: true, isModalVisible: false });

            this.timeoutId = setTimeout(() => {
                this.setState({ isFullPhoneNumberVisible: false });
            }, 15000); // 15000 milliseconds = 15s

            // Lưu log
            const data = {
                customer_id: this.props.customer_id,
                phone: this.props.fullPhoneNumber,
                customer_type: this.props.customer_type
            };
            this.props.createViewPhoneNumber(data);
            this.setState({ password: '' })
        }
    }

    formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        // Kiểm tra nếu số điện thoại bắt đầu với "0"
        if (phoneNumber.startsWith('0')) {
            // Loại bỏ số 0 ở đầu và thay thế bằng "84"
            return '84' + phoneNumber.slice(1);
        }
        // Nếu không có số 0 ở đầu, trả về số điện thoại gốc
        return phoneNumber;
    }

    render() {
        const { hiddenPhoneNumber, fullPhoneNumber, permission, inputPinCode } = this.props;
        const { isFullPhoneNumberVisible, isModalVisible } = this.state;

        return (
            <>
                {
                    permission ? fullPhoneNumber : (
                        <>
                            {
                                inputPinCode ? (
                                    <Tooltip title="Click để hiển thị" placement="right">
                                        <Tag
                                            ref={(ref) => (this.tagRef = ref)}
                                            className='item-action-btn'
                                            onClick={this.handlePhoneNumber}
                                        >
                                            {isFullPhoneNumberVisible ? (
                                                <>
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: this.formatPhoneNumber(fullPhoneNumber),
                                                            tooltips: false
                                                        }
                                                    }>
                                                        {fullPhoneNumber}
                                                    </Paragraph>
                                                </>
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon="fa-solid fa-lock" /> {hiddenPhoneNumber}
                                                </>
                                            )}
                                        </Tag>
                                    </Tooltip>
                                ) : (
                                    <Tag>
                                        <FontAwesomeIcon icon="fa-solid fa-lock" /> {hiddenPhoneNumber}
                                    </Tag>
                                )
                            }
                        </>
                    )
                }

                <Modal
                    title="Xác nhận mã pin"
                    visible={isModalVisible}
                    onCancel={this.handleModalCancel}
                    footer={null}
                >
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item>
                                <Space.Compact className='w-100'>
                                    <Input.Password
                                        value={this.state.password}
                                        onChange={this.handlePasswordInput}
                                        autoComplete="off"
                                        allowClear
                                        prefix={<FontAwesomeIcon icon="fa-solid fa-key" />}
                                    />
                                    <Button type='primary' onClick={this.submitPassword}>Xác nhận</Button>
                                </Space.Compact>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item>
                                <Button type='primary' onClick={this.handleVeryfy}>Xác thực bằng khuôn mặt</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createViewPhoneNumber: (data) => dispatch(createViewPhoneNumber(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HandlePhoneNumber);


// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { Tag, Tooltip } from 'antd';

// import { createViewPhoneNumber } from './../redux/actions/ViewPhonenumberActions';

// class HandlePhoneNumber extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isFullPhoneNumberVisible: false
//         };
//         this.timeoutId = null;
//     }

//     handlePhoneNumber = () => {
//         this.setState({ isFullPhoneNumberVisible: true });

//         this.timeoutId = setTimeout(() => {
//             this.setState({ isFullPhoneNumberVisible: false });
//         }, 15000); // 15000 milliseconds = 15s

//         // Lưu log
//         const data = {
//             customer_id: this.props.customer_id,
//             phone: this.props.fullPhoneNumber,
//             customer_type: this.props.customer_type
//         }
//         this.props.createViewPhoneNumber(data);
//     };

//     handleMouseLeave = () => {
//         clearTimeout(this.timeoutId);
//         this.setState({ isFullPhoneNumberVisible: false });
//     };

//     handleOutsideClick = (e) => {
//         if (!this.tagRef.contains(e.target)) {
//             this.handleMouseLeave();
//         }
//     };

//     componentDidMount() {
//         document.addEventListener('mousedown', this.handleOutsideClick);
//     }

//     componentWillUnmount() {
//         document.removeEventListener('mousedown', this.handleOutsideClick);
//     }

//     render() {
//         const { hiddenPhoneNumber, fullPhoneNumber } = this.props;
//         const { isFullPhoneNumberVisible } = this.state;

//         return (
//             <Tooltip title="Click để hiển thị" placement="right">
//                 <Tag
//                     ref={(ref) => (this.tagRef = ref)}
//                     className='item-action-btn'
//                     onClick={this.handlePhoneNumber}
//                 >
//                     {isFullPhoneNumberVisible ? fullPhoneNumber : hiddenPhoneNumber}
//                 </Tag>
//             </Tooltip>
//         );
//     }
// }
// function mapStateToProps(state) {
//     return {}
// }

// function mapDispatchToProps(dispatch) {
//     return {
//         createViewPhoneNumber: (data) => dispatch(createViewPhoneNumber(data)),

//     }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(HandlePhoneNumber);