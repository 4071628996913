import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AreaChartOutlined, ClockCircleOutlined, CustomerServiceOutlined, FileSearchOutlined, MedicineBoxOutlined, MenuOutlined, OneToOneOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import PageTitle from '../../components/PageTitle';
import { isMobile } from 'react-device-detect';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import { cleanObject } from '../../utils/helpers';

import Statistic from './Statistic';
import CustomerInfo from './CustomerInfo';
import AppDrawer from '../../layouts/AppDrawer';
import Appointment from './appointment/Appointment';
import TelesaleList from './telesale/TelesaleList';
import ConsultantList from './consultant/ConsultantList';
import Assistant from './assistant/Assistant';
import Doctor from './doctor/Doctor';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/customer-data',
        breadcrumbName: 'Dữ liệu khách hàng',
    },
]
const { TabPane } = Tabs;

class CustomerData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleDrawer: false
        }
    }

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    onChangeFilter(name, value) {
        console.log(name, value);
        let query = qs.parse(this.props.location.search.slice(1));
        query = cleanObject(query);

        if (value === 'schedule') {
            query['tabs'] = 'schedule';
            query['tab'] = '1';
        } else {
            query[name] = value;
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }


    onTabClick = (key) => {
        // Cập nhật state và URL khi tab thay đổi
        this.setState({ activeTab: key });
        this.onChangeFilter('tabs', key);
    };

    toggleDrawer(visibleDrawer) {
        this.setState({ visibleDrawer })
    }

    render() {
        const { visibleDrawer } = this.state;
        const { location, authUser } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));

        const shouldShowItemMenu = (isMobile && location.pathname.includes('customer-data'));

        return (
            <div>
                {isMobile ? null : (
                    <>
                        <PageTitle routes={routes} title="Dữ liệu khách hàng" />
                        <div style={{ marginBottom: 15 }} />
                    </>
                )}
                <Tabs
                    onChange={(value) => this.onTabClick(value)}
                    defaultActiveKey={query.tabs}
                    type="card"
                    size='large'
                    className='tabs-custom'
                    tabBarStyle={{
                        background: "#0b2b33",
                        ...(isMobile ? { marginLeft: '-8px', marginRight: '-8px' } : {})
                    }}
                >
                    <TabPane tab={<span ><AreaChartOutlined />Thống kê</span>} key="statistic" forceRender>
                        <Statistic />
                    </TabPane>
                    {!['BACSI', 'PHUTA'].includes(authUser.role_code) && (
                        <TabPane tab={<span><FileSearchOutlined />Tra cứu khách hàng</span>} key="lookup" forceRender>
                            <CustomerInfo />
                        </TabPane>
                    )}
                    <TabPane tab={<span><ClockCircleOutlined />Lịch hẹn</span>} key="schedule" forceRender>
                        <Appointment />
                    </TabPane>
                    {!['BACSI', 'PHUTA', 'QUANLYKHO'].includes(authUser.role_code) && (
                        <>
                            <TabPane tab={<span><CustomerServiceOutlined />Telesale</span>} key="telesale" forceRender>
                                <TelesaleList />
                            </TabPane>
                            <TabPane tab={<span><OneToOneOutlined />Tư vấn trực tiếp</span>} key="consultant-direct" forceRender>
                                <ConsultantList />
                            </TabPane>
                        </>
                    )}
                    {['ADMIN', 'PHUTA', 'QUANLYKHO'].includes(authUser.role_code) && (
                        <TabPane tab={<span><MedicineBoxOutlined />Phụ tá</span>} key="assistant" forceRender>
                            <Assistant />
                        </TabPane>
                    )}
                    {['ADMIN', 'BACSI'].includes(authUser.role_code) && (
                        <TabPane tab={<span style={{ marginRight: 50 }}><MedicineBoxOutlined />Bác sĩ</span>} key="doctor" forceRender>
                            <Doctor />
                        </TabPane>
                    )}
                </Tabs>

                {
                    shouldShowItemMenu ? (
                        <>
                            <div className='menu-icon-mobile' onClick={() => this.toggleDrawer(true)}>
                                <MenuOutlined />
                            </div>
                            <AppDrawer visible={visibleDrawer} onClose={() => this.toggleDrawer(false)} />
                        </>
                    ) : null
                }

            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerData' })(CustomerData));

