import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, InputNumber, Spin, Popconfirm, Divider, Table, DatePicker, Alert } from 'antd';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseSelectCustom from '../../components/Elements/BaseSelect/custom';
import { _newLine, customDataImageUpload, PaymentMethod, isValidDateForm, PaymentType } from '../../utils/helpers';
import ListCurrentImage from '../../components/ListCurrentImage';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications'
import { createCashReceipt, updateCashReceipt } from '../../redux/actions/CashReceiptActions';
import { getListCustomerActive, getSpecificCustomer } from '../../redux/actions/CustomerActions';
import { getSpecificService, getServiceActive } from '../../redux/actions/ServicesActions';
import { getActiveProduct, getSpecificProduct } from '../../redux/actions/ProductActions';
import NumberFormat from 'react-number-format';
import BaseRadioList from '../../components/Elements/BaseRadios';
import moment from 'moment';

class CashReceiptForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            customerQuery: '',
            productQuery: '',
            items: [],
            images: [],
            currentImages: [],
            isResetUploadImage: true,
            net_total: 0,
            customer_debt: 0,
            special_discount: 0
        };
    }

    componentDidMount() {
        this.props.getListCustomerActive();
        this.props.getActiveProduct();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.items && Array.isArray(nextProps?.currentItem?.items)) {
                this.renderNewItem(nextProps?.currentItem?.items)
            } else {
                this.setState({ items: [] })
            }
            if (nextProps?.currentItem?.images && Array.isArray(nextProps?.currentItem?.images)) {
                this.setState({ currentImages: nextProps?.currentItem?.images })
            } else {
                this.setState({ currentImages: [] })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { items, currentImages, images } = this.state;

                    values.items = items;
                    values.images = customDataImageUpload(images, currentImages);
                    values.payment_at = values.payment_at ? values.payment_at.format("YYYY-MM-DD") : null;
                    values.amount_paid = values.net_total;

                    if (this.props.currentItem) {
                        values.customer_id = this.props.currentItem.customer_id;
                        await this.props.updateCashReceipt(this.props.currentItem.id, values).then((res) => {
                            this.props.reloadData();
                            this.onClose();
                        }).catch(error => {
                            this.setState({ isLoading: false });
                        });

                    } else {
                        await this.props.createCashReceipt(values).then((res) => {
                            this.props.reloadData();
                            this.onClose();
                        }).catch(error => {
                            this.setState({ isLoading: false });
                        });

                    }
                    console.log(values);
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false, isResetUploadImage: true, images: [], customer_debt: 0 });
    }

    onSearchCustomer(val) {
        this.setState({ customerQuery: val }, () => {
            this.props.getListCustomerActive({ keyword: val });
        })
    }

    handleCustomerScroll(e) {
        this.props.getListCustomerActive({ keyword: this.state.customerQuery, page: this.props.pagination_active.currentPage + 1 });
    }

    onSearchProduct(val) {
        this.setState({ productQuery: val }, () => {
            this.props.getActiveProduct({ keyword: val });
        })
    }

    handleProductScroll(e) {
        this.props.getActiveProduct({ keyword: this.state.productQuery, page: this.props.pagination.currentPage + 1 }, true);
    }

    renderNewItem(items = []) {
        let new_items = items.map((item, index) => {
            return {
                id: item.id,
                name: item.name,
                price: item.price,
                quantity: item.quantity,
                //amount: item.quantity * item.price,
                amount: item.amount,
                type: item.type
            }
        })
        this.setState({
            items: new_items.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
        }, () => {
            this.calculateTotalAmount(); // Gọi sau khi setState để đảm bảo rằng state đã được cập nhật
        });
    }

    onDelete(id) {
        let items = this.state.items.filter(item => {
            return item.id != id;
        });
        this.renderNewItem(items)
    }

    async onChangeItem(name, value, itemId) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.id == itemId);
        let currentItem = items[index];
        currentItem[name] = value;
        items[index] = currentItem;
        this.renderNewItem(items)
    }

    onChangeService = (id) => {
        this.setState({ isloading: true });
        this.props.getSpecificService(id).then((item) => {
            let new_items = {
                id: item.id,
                name: item.name,
                price: item.price,
                quantity: 1,
                amount: item.price * 1,
                type: 'Dịch vụ'
            };
            this.setState({
                items: [...this.state.items, new_items].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                isloading: false
            }, () => {
                this.calculateTotalAmount(); // Gọi sau khi setState để đảm bảo rằng state đã được cập nhật
            });
        }).catch(err => {
            this.setState({ isloading: false })
        });
        this.calculateTotalAmount(); // Tính toán lại total_amount và debt
    };

    onChangeProduct(id) {
        this.setState({ isloading: true })
        this.props.getSpecificProduct(id).then((item) => {
            let new_items = {
                id: item.id,
                name: item.name,
                price: item.sale_price,
                quantity: 1,
                amount: item.sale_price * 1,
                type: 'Sản phẩm'
            }
            this.setState({
                items: [...this.state.items, new_items].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                isloading: false
            }, () => {
                this.calculateTotalAmount(); // Gọi sau khi setState để đảm bảo rằng state đã được cập nhật
            });
        }).catch(err => {
            this.setState({ isloading: false })
        })
        this.calculateTotalAmount(); // Tính toán lại total_amount và debt
    }

    onChangeCustomer(id) {
        this.props.getSpecificCustomer(id).then((item) => {
            const receiptExportItems = item.receiptExport || [];
            const services = item.services_selected || [];

            // Xử lý receiptExport
            const receiptExportMapped = receiptExportItems.map((item) => ({
                id: item.id,
                name: item.name,
                type: 'Sản phẩm',
                price: item.sale_price,
                quantity: 1,
                amount: item.quantity * item.sale_price,
            }));

            // Xử lý services_selected
            const servicesMapped = services.map((service) => ({
                id: service.id,
                name: service.service_name ?? null,
                type: 'Dịch vụ',
                price: service.sale_price,
                quantity: service.quantity,
                // amount: service.quantity * service.sale_price,
                amount: service.amount
            }));

            //console.log('servicesMapped', servicesMapped)

            // Gộp hai mảng
            const mergedItems = [...receiptExportMapped, ...servicesMapped];
            // Render các phần tử mới
            this.renderNewItem(mergedItems);
            this.setState({ isLoading: false, customer_debt: item.customer_debt }, () => {
                this.calculateNetTotal(); // Tính toán lại net_total
            });
        }).catch(err => {
            this.setState({ items: [] })
        })

        this.calculateTotalAmount(); // Tính toán lại total_amount và debt
    }

    calculateTotalAmount = () => {
        let total_amount = 0;
        this.state.items.forEach(item => {
            //total_amount += item.price * item.quantity;
            total_amount += item.amount
        });
        if (this.props.form.getFieldValue('total_amount') !== undefined) {
            this.props.form.setFieldsValue({
                total_amount: total_amount
            });
        }
        this.calculateNetTotal(); // Tính toán lại net_total
    };

    onChangeFieldTotal = (field, value) => {
        this.props.form.setFieldsValue({ [field]: value });
        if (field === 'total_amount' || field === 'special_discount') {
            this.calculateNetTotal();
        }
    };

    // calculateNetTotal = () => {
    //     const { customer_debt } = this.state;
    //     const total_amount = this.props.form.getFieldValue('total_amount') || 0;
    //     const special_discount = this.props.form.getFieldValue('special_discount') || 0;
    //     const net_total = total_amount - special_discount;
    //     // Nếu dư nợ khách hàng khác 0 thì net_total không được lớn hơn dư nợ
    //     this.props.form.setFieldsValue({ net_total: customer_debt !== 0 ? customer_debt : net_total });
    // };

    calculateNetTotal = () => {
        const { customer_debt } = this.state;
        const total_amount = this.props.form.getFieldValue('total_amount') || 0;
        const special_discount = this.props.form.getFieldValue('special_discount') || 0;

        // Nếu customer_debt khác 0 thì net_total = customer_debt - special_discount
        // Nếu customer_debt = 0 thì net_total = total_amount - special_discount
        const net_total = customer_debt !== 0
            ? customer_debt - special_discount
            : 0;//total_amount - special_discount;

        this.setState({ special_discount: special_discount });
        if (this.props.form.getFieldValue('net_total') !== undefined) {
            this.props.form.setFieldsValue({ net_total });
        }
    };

    onChangeImage = (data) => {
        this.setState({
            isResetUploadImage: false,
            images: data
        });
        NotificationManager.success("Image complete.");
    }

    getImages(data) {
        console.log('currentImages', data);
        this.setState({ currentImages: data })
    }

    render() {
        var { isLoading, items, isResetUploadImage, currentImages, customer_debt, special_discount } = this.state;
        var {
            visible,
            currentItem,
            customerActiveList,
            product_list,
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        var columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: "Sản phẩm/Dịch vụ",
                dataIndex: "name"
            },
            {
                title: "Loại",
                dataIndex: "type",
                width: '100px'
            },
            {
                title: "Số lượng",
                dataIndex: "quantity",
                width: '80px',
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="quantity"
                            min={1}
                            className='w-100 fix-height-input'
                            disabled={record.type === 'Dịch vụ'}
                            onChange={(value) => {
                                const validValue = value == null || value <= 0 ? 1 : value;
                                this.onChangeItem('quantity', validValue, record.id);
                            }}
                        />
                    )
                }
            },
            {
                title: "Giá bán",
                dataIndex: "price",
                width: "120px",
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="price"
                            step={50000}
                            min={0}
                            disabled={record.type === 'Dịch vụ'}
                            className='w-100 fix-height-input'
                            onChange={(value) => this.onChangeItem('price', value, record.id)}
                        />
                    )
                }
            },
            {
                title: "Thành tiền",
                dataIndex: "amount",
                width: "120px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            },
            {
                title: "#",
                align: "center",
                width: '30px',
                render: (text, record) => {
                    return (
                        <Popconfirm
                            title="Xóa bản ghi này?"
                            onConfirm={() => this.onDelete(record.id)}
                        >
                            <DeleteOutlined className='item-action-btn-remove' />
                        </Popconfirm>
                    )
                }
            }
        ];

        var total_amount = 0;
        items.forEach(item => {
            total_amount += item.price;
        });

        return (
            <Drawer
                visible={visible}
                title={currentItem ? 'Sửa phiếu thu' : `Tạo phiếu thu`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '55%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                {currentItem ? 'Cập nhật' : "Tạo phiếu"}
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <Alert
                                    message="Chú ý"
                                    description={<>
                                        - Phải tạo <strong>Dịch vụ chốt</strong> trước khi tạo phiếu thu <br />
                                        - Trường <strong>"Ưu đãi đặc biệt"</strong> (nếu có) ghi rõ lý do được ưu đãi vào ô ghi chú bên dưới
                                    </>}
                                    type="warning"
                                />
                            </Col>
                        </Row>
                        <Spin tip="Loading..." spinning={isLoading}>
                            <Row gutter={16}>
                                {
                                    currentItem ? (
                                        <Col xs={24} md={24} className='mb-3'>
                                            <h6><UserOutlined /> Khách hàng:&nbsp;
                                                <span className='color-primary'>
                                                    {currentItem?.customer_name + ' - ' + currentItem?.customer_code}
                                                </span>
                                            </h6>
                                        </Col>
                                    ) : (
                                        <Col xs={24} md={24}>
                                            <Form.Item label={<>Khách hàng: </>}>
                                                {getFieldDecorator("customer_id", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem?.customer_id : null
                                                })(
                                                    <BaseSelect
                                                        options={customerActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="full_name"
                                                        additionalLabel="phone"
                                                        additionalLabel2="code"
                                                        isCustomerList={true}
                                                        showSearch
                                                        placeholder="Mã, tên, 3 số cuối sđt của khách hàng"
                                                        onSearch={(val) => this.onSearchCustomer(val)}
                                                        onScrollEnd={(e) => this.handleCustomerScroll(e)}
                                                        onChange={(value) => this.onChangeCustomer(value)}
                                                        className="w-100"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Divider orientation="left" orientationMargin="0">
                                        Nội dung
                                    </Divider>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className='mb-3'>
                                    <Form.Item label="Sản phẩm">
                                        <BaseSelectCustom
                                            options={product_list || []}
                                            optionValue="id"
                                            optionLabel="name"
                                            additionalLabel1="quantity"
                                            additionalLabel2="expiry_date_format"
                                            additionalLabel3="warehouse_name"
                                            showSearch
                                            allowClear={false}
                                            defaultValue="Chọn sản phẩm"
                                            onSearch={(val) => this.onSearchProduct(val)}
                                            onScrollEnd={(e) => this.handleProductScroll(e)}
                                            onChange={(id) => this.onChangeProduct(id)}
                                            className="w-100"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className='mb-3'>
                                    <Table
                                        className='mt-2 desabled-input-table'
                                        size='small'
                                        rowKey='key'
                                        locale={{ emptyText: "Chưa có dữ liệu" }}
                                        tableLayout="auto"
                                        columns={columns}
                                        dataSource={items || []}
                                        pagination={false}
                                        scroll={{
                                            x: 'max-content'
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16} justify="end">
                                <Col xs={24} sm={24} md={24}>
                                    <Form.Item
                                        className="form-item-label-right"
                                        label="Tổng"
                                        labelCol={{ xs: { span: 24 }, sm: { span: 10 }, md: { span: 14 }, lg: { span: 16 }, xl: { span: 18 } }}
                                        wrapperCol={{ xs: { span: 24 }, sm: { span: 14 }, md: { span: 10 }, lg: { span: 8 }, xl: { span: 6 } }}
                                    >
                                        {getFieldDecorator('total_amount', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.total_amount : total_amount
                                        })(
                                            <InputNumber
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                className="w-100"
                                                step={50000}
                                                min={0}
                                                disabled
                                                onChange={(value) => this.onChangeFieldTotal('total_amount', value)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} justify="end">
                                <Col xs={24} sm={24} md={24}>
                                    <Form.Item
                                        className="form-item-label-right"
                                        label={<>Dư nợ</>}
                                        labelCol={{ xs: { span: 24 }, sm: { span: 10 }, md: { span: 14 }, lg: { span: 16 }, xl: { span: 18 } }}
                                        wrapperCol={{ xs: { span: 24 }, sm: { span: 14 }, md: { span: 10 }, lg: { span: 8 }, xl: { span: 6 } }}
                                    >
                                        {getFieldDecorator('outstanding_debt', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.remaining_debt ?? 0 : customer_debt
                                        })(
                                            <InputNumber
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                className="w-100"
                                                step={50000}
                                                min={0}
                                                disabled
                                                placeholder='Đơn vị vnđ'
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} justify="end">
                                <Col xs={24} sm={24} md={24}>
                                    <Form.Item
                                        className="form-item-label-right"
                                        label={<>Ưu đãi đặc biệt</>}
                                        labelCol={{ xs: { span: 24 }, sm: { span: 10 }, md: { span: 14 }, lg: { span: 16 }, xl: { span: 18 } }}
                                        wrapperCol={{ xs: { span: 24 }, sm: { span: 14 }, md: { span: 10 }, lg: { span: 8 }, xl: { span: 6 } }}
                                    >
                                        {getFieldDecorator('special_discount', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.special_discount : 0
                                        })(
                                            <InputNumber
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                className="w-100"
                                                step={50000}
                                                min={0}
                                                placeholder='Đơn vị vnđ'
                                                onChange={(value) => this.onChangeFieldTotal('special_discount', value)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} justify="end">
                                <Col xs={24} sm={24} md={24}>
                                    <Form.Item
                                        className="form-item-label-right"
                                        label="Số tiền phải thu"
                                        labelCol={{ xs: { span: 24 }, sm: { span: 10 }, md: { span: 14 }, lg: { span: 16 }, xl: { span: 18 } }}
                                        wrapperCol={{ xs: { span: 24 }, sm: { span: 14 }, md: { span: 10 }, lg: { span: 8 }, xl: { span: 6 } }}
                                    >
                                        {getFieldDecorator('net_total', {
                                            initialValue: currentItem ? currentItem.amount_paid : 0,
                                            rules: [{ required: true, message: 'Vui lòng điền!' }],
                                        })(
                                            <InputNumber
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                className="w-100"
                                                step={50000}
                                                min={0}
                                                placeholder='Đơn vị vnđ'
                                                max={!currentItem ? customer_debt !== 0 ? customer_debt - special_discount : total_amount - special_discount : null}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Hình thức thu">
                                        {getFieldDecorator('payment_method', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.payment_method : null
                                        })(
                                            <BaseRadioList
                                                options={PaymentMethod ?? []}
                                                showSearch
                                                placeholder="Chọn hình thức thu"
                                                className="w-100"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Loại thanh toán">
                                        {getFieldDecorator('payment_type', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.payment_type : null
                                        })(
                                            <BaseRadioList
                                                options={PaymentType ?? []}
                                                showSearch
                                                placeholder="Loại thanh toán"
                                                className="w-100"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Ngày thu">
                                        {getFieldDecorator('payment_at', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn!',
                                                },
                                            ],
                                            initialValue: currentItem ? isValidDateForm(currentItem.payment_at, false) : moment()
                                        })(
                                            <DatePicker
                                                className='w-100'
                                                format="DD/MM/YYYY"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ghi chú">
                                        {getFieldDecorator('note', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? _newLine(currentItem.note) : null
                                        })(
                                            <Input.TextArea rows={3} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ảnh phiếu" className='flex-upload'>
                                        <ListCurrentImage currentImages={currentImages} getData={(data) => this.getImages(data)} />
                                        {getFieldDecorator('images', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={50}
                                                onChangeData={this.onChangeImage}
                                                isResetUpload={isResetUploadImage}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Spin>
                    </Form>
                    : null
                }
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        service_active_list: state.ServiceReducer.service_active_list,
        config: state.config,
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active,
        product_list: state.ProductReducer.product_list,
        pagination: state.ProductReducer.pagination,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCashReceipt: (data) => dispatch(createCashReceipt(data)),
        updateCashReceipt: (id, data) => dispatch(updateCashReceipt(id, data)),
        getSpecificService: (id) => dispatch(getSpecificService(id)),
        getServiceActive: (filter) => dispatch(getServiceActive(filter)),
        getListCustomerActive: (filter) => dispatch(getListCustomerActive(filter)),
        getActiveProduct: (filter, concatenate) => dispatch(getActiveProduct(filter, concatenate)),
        getSpecificProduct: (id) => dispatch(getSpecificProduct(id)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CashReceiptForm' })(CashReceiptForm));
