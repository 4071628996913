import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, ExportOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Modal, Tooltip, DatePicker, Button, Divider } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, ProductReceiptExportType, ConvertProductReceiptExportType, isValidDate, downloadFromLink } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import ProductReceiptExportDetail from './ProductReceiptExportDetail';
import ProductReceiptExportAddForm from './ProductReceiptExportAddForm';
import BaseSelect from '../../components/Elements/BaseSelect';
import NumberFormat from 'react-number-format';
import appConfig from '../../config';
import moment from 'moment';
import PrintTable from './PrintTable';
import { PrinterOutlined } from '@ant-design/icons';

import {
    getAllProductReceiptExport,
    getSpecificProductReceiptExport,
    removeProductReceiptExport,
    exportExcel
} from '../../redux/actions/ProductReceiptExportActions';
import { NotificationManager } from 'react-notifications';

const { confirm } = Modal;
const { RangePicker } = DatePicker;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/receipt-export',
        breadcrumbName: 'Phiếu xuất kho',
    },
]

class ProductReceiptExportList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenAddForm: false,
            currentItem: null,
            isOpenDetail: false,
            current_id: null,
            loadingDetail: false,
            isLoadingExport: false
        }

        this.printTableRef = React.createRef();
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllProductReceiptExport(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getAllProductReceiptExport(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        delete query.created_at;
        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    onRemove() {
        this.props.removeProductReceiptExport(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeProductReceiptExport(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificProductReceiptExport(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenDetail(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getAllProductReceiptExport(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    // do_export() {
    //     var query = qs.parse(this.props.location.search.slice(1));
    //     this.setState({ isLoadingExport: true });
    //     this.props.exportExcel(query).then((res) => {
    //         console.log("res: ", res)
    //         setTimeout(() => {
    //             const response = {
    //                 file: `${appConfig.MEDIA_URL}/${res.path}`,
    //             };
    //             downloadFromLink(response.file, "_product_receipt_export.xlsx");
    //         }, 100);
    //         this.setState({ isLoadingExport: false });
    //     }).catch(err => {
    //         this.setState({ isLoadingExport: false });
    //     });

    // }

    do_export() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoadingExport: true });
        this.props.exportExcel(query).catch(err => {
            this.setState({ isLoadingExport: false });
        });
        this.setState({ isLoadingExport: false });
        NotificationManager.success("Quá trình xuất file đang được thực hiện. Vui lòng kiểm tra email của bạn sau ít phút.", "Thông báo", 9000);

    }

    handlePrint = async (id) => {
        const res = await this.props.getSpecificProductReceiptExport(id);
        this.setState({ currentItem: res }, () => {
            this.printTableRef.current.calculateTotal().then(() => {
                const content = document.getElementById('custom-print-content').innerHTML;
                const printWindow = window.open('', '', 'width=900,height=800');
                printWindow.document.open();
                printWindow.document.write('<html><head><title>Print</title></head><body>');
                printWindow.document.write(content);
                printWindow.document.write('</body></html>');
                printWindow.document.close();
                printWindow.print();
            });
        });
    };

    render() {
        var { product_receipt_export_list, pagination } = this.props;
        var { isLoading, selectedRowKeys, isLoadingExport, isOpenAddForm, currentItem, current_id, isOpenDetail, loadingDetail } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        console.log('currentItem: ', currentItem);

        const columns = [
            {
                title: 'Mã phiếu',
                dataIndex: 'code',
                key: 'code',
                width: '80px'
            },
            {
                title: '#',
                width: '80px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        <Tooltip title="In phiếu" placement="right">
                            <Button size='small' type='dashed' onClick={() => this.handlePrint(record.id)} icon={<PrinterOutlined />}>
                                <span id="custom-print-content" style={{ display: 'none' }}>
                                    <PrintTable ref={this.printTableRef} tableData={currentItem ? currentItem?.items : []} />
                                </span>
                            </Button>
                        </Tooltip>
                        {
                            checkPermission('product_receipt_export', 'remove') ? (
                                <>
                                    <Divider type="vertical" />
                                    <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                                </>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên phiếu',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => {
                    if (record.name) {
                        if (checkPermission('product_receipt_export', 'detail')) {
                            return (
                                <Tooltip title="Xem chi tiết" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        {record.name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>{record.name}</>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Loại phiếu',
                dataIndex: 'type',
                key: 'type',
                width: '100px',
                render: (text, record) => {
                    return (<>{ConvertProductReceiptExportType(text)}</>)
                }
            },
            {
                title: 'Tổng tiền',
                dataIndex: 'total_amount',
                key: 'total_amount',
                width: '120px',
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Ngày xuất',
                dataIndex: 'export_date',
                key: 'export_date',
                width: '120px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text, false)}</div>
                    )
                }
            },
            {
                title: 'Ngày lập phiếu',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '150px',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text, true)}</div>
                    )
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Phiếu xuất kho" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('product_receipt_export', 'create')}
                        isShowDeleteButton={checkPermission('product_receipt_export', 'remove')}
                        onAdd={() => this.setState({ isOpenAddForm: true })}
                        addText="Tạo phiếu mới"
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        activeFilter={
                            query.created_date_start ||
                            query.created_date_end
                        }
                        filters={
                            [
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày xuất', 'Đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />
                            ]
                        }
                    >
                        <BaseSelect
                            options={ProductReceiptExportType}
                            onChange={(value) => this.onChangeFilter("type", value)}
                            defaultValue={query.type || null}
                            placeholder="Loại phiếu"
                            style={{ width: '150px' }}
                            className="table-button"
                        />
                        {
                            checkPermission('product_receipt_export', 'export') ? (
                                <Button className="table-button" loading={isLoadingExport} type='default' onClick={() => this.do_export()} icon={<ExportOutlined />}>Xuất file excel</Button>
                            ) : null
                        }
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={product_receipt_export_list || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <ProductReceiptExportAddForm
                    visible={isOpenAddForm}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.setState({ isOpenAddForm: false })}
                />

                <ProductReceiptExportDetail
                    visible={isOpenDetail}
                    currentItem={currentItem}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                    loadingDetail={loadingDetail}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        product_receipt_export_list: state.ProductReceiptExportReducer.product_receipt_export_list,
        pagination: state.ProductReceiptExportReducer.pagination
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllProductReceiptExport: (filter) => dispatch(getAllProductReceiptExport(filter)),
        getSpecificProductReceiptExport: (id) => dispatch(getSpecificProductReceiptExport(id)),
        removeProductReceiptExport: (ids) => dispatch(removeProductReceiptExport(ids)),
        exportExcel: (filter) => dispatch(exportExcel(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReceiptExportList);
