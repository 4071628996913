import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Button, Row, Col, Typography, Spin, Divider, Space, Image } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import ReactHtmlParser from 'react-html-parser'
import { isMobile } from 'react-device-detect';
import { isValidDate } from '../../utils/helpers';
import CardIssuedCountForm from './CardIssuedCountForm';

const { Text } = Typography;

class CardIssuedCountDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenForm: false,
            currentCustomer: null
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenForm(isOpenForm) {
        this.setState({ isOpenForm: isOpenForm });
    }

    render() {
        var { isOpenForm } = this.state;
        var { visible, currentItem, loadingDetail } = this.props;

        return (
            <>
                <Drawer
                    visible={visible}
                    title={`Chi tiết: #${currentItem?.id}`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '55%'}
                    extra={
                        <Space style={{ flexWrap: 'nowrap' }}>
                            {
                                checkPermission('card_issued_count', 'update') ? (
                                    <Button type="primary" onClick={() => this.toggleOpenForm(true)} icon={<EditOutlined />}>Sửa</Button>
                                ) : null
                            }
                        </Space>
                    }
                >
                    {visible ? (
                        <div className='wrap-customer-detail'>
                            <Spin tip="Loading..." spinning={loadingDetail}>
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Khách hàng">
                                                {currentItem?.customer_name || currentItem?.recipient}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Số lượng thẻ đã phát">
                                                {currentItem?.quantity}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Mã thẻ đã phát">
                                                {currentItem?.card_number}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Loại thẻ">
                                                {currentItem?.card_type_name}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Nơi phát">
                                                {currentItem?.issuance_place}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Địa chỉ">
                                                {currentItem?.address}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Số điện thoại">
                                                {currentItem?.phone}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ngày phát">
                                                {isValidDate(currentItem?.issuance_date, false)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Người cập nhật">
                                                {currentItem?.updated_user}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Người tạo">
                                                {currentItem?.created_user}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ngày tạo">
                                                {isValidDate(currentItem?.created_at)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ghi chú">
                                                {ReactHtmlParser(currentItem?.note)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <div className='mb-1 mt-2'>
                                            <Text strong>Ảnh</Text>
                                        </div>
                                        <Image.PreviewGroup>
                                            <Space wrap>
                                                {
                                                    currentItem?.images.map(item => {
                                                        return (
                                                            <Image
                                                                height={50}
                                                                width={50}
                                                                src={item.src}
                                                                style={{
                                                                    border: '1px solid #dedede',
                                                                    objectFit: 'cover',
                                                                    objectPosition: 'center',
                                                                }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Space>
                                        </Image.PreviewGroup>
                                    </Col>
                                </Row>
                            </Spin>
                        </div>
                    ) : null}
                </Drawer>
                <CardIssuedCountForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CardIssuedCountDetail' })(CardIssuedCountDetail));
