import api from '../../utils/api';
import {
    GET_LIST_DOCUMENT_CATEGORIES,
    GET_SPECIFIC_DOCUMENT_CATEGORIES,
    UPDATE_SPECIFIC_DOCUMENT_CATEGORIES,
    CREATE_NEW_DOCUMENT_CATEGORIES,
    REMOVE_DOCUMENT_CATEGORIES,
    GET_DOCUMENT_CATEGORIES_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListDocumentCategories = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/document-categories', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_DOCUMENT_CATEGORIES, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificDocumentCategories = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/document-categories/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_DOCUMENT_CATEGORIES, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getDocumentCategoriesActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/document-categories/active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_DOCUMENT_CATEGORIES_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createDocumentCategories = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/document-categories', data).then(res => {
            dispatch({ type: CREATE_NEW_DOCUMENT_CATEGORIES, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateDocumentCategories = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/document-categories/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_DOCUMENT_CATEGORIES, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeDocumentCategories = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/document-categories`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_DOCUMENT_CATEGORIES, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
