import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Input, Button, DatePicker, Select, Space, Card, Row, Col, Drawer } from "antd";
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { _newLine, isValidateTime } from '../../../utils/helpers';
import { updateExamVisit } from '../../../redux/actions/CustomerServicesActions';

const { Option } = Select;

const ExaminationVisitEditForm = (props) => {

    const { config, currentExaminationVisit, visible, onCancel } = props;
    const { getFieldDecorator } = props.form;
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setIsLoading(true);
                    values.time = values.time ? values.time.format("YYYY-MM-DD HH:mm:ss") : null;
                    console.log("values", values);
                    await props.updateExamVisit(currentExaminationVisit?.id, values);
                    setIsLoading(false);
                    props.reloadData()
                    onClose();
                    props.form.resetFields();
                } catch (error) {
                    setIsLoading(false);
                }
            }
        });
    }

    const onClose = () => {
        onCancel();
    }

    return (
        <>
            <Drawer
                visible={visible}
                title="Cập nhật lần khám"
                onOk={(e) => handleSave(e)}
                onClose={() => onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                bodyStyle={{ padding: '10px 20px' }}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                loading={isLoading}
                                onClick={(e) => handleSave(e)}
                            >
                                Lưu
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <div>
                        <Card
                            size='small'
                            className="mb-2 service-card-box"
                            bordered={false}
                            bodyStyle={{ padding: 0 }}
                            headStyle={{ padding: 0 }}
                        >
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item>
                                        {getFieldDecorator("doctor_charge_id", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentExaminationVisit?.doctor_charge_id || []
                                        })(
                                            <Select
                                                mode='multiple'
                                                style={{ width: '100%' }}
                                                placeholder="Chọn 1 hoặc nhiều bác sĩ"
                                                optionLabelProp="label"
                                                allowClear={true}
                                                showSearch={true}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    config && config?.doctors ? (
                                                        <>
                                                            {
                                                                config?.doctors.map((item) => {
                                                                    return (
                                                                        <Option key={item.user_id} value={item.user_id} label={item.full_name}>{item.full_name}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    ) : null
                                                }
                                            </Select>
                                        )}

                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item>
                                        {getFieldDecorator("assistant_doctor_id", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentExaminationVisit?.assistant_doctor_id || []
                                        })(
                                            <Select
                                                mode='multiple'
                                                style={{ width: '100%' }}
                                                placeholder="Chọn 1 hoặc nhiều phụ tá"
                                                optionLabelProp="label"
                                                allowClear={true}
                                                showSearch={true}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    config && config?.doctors ? (
                                                        <>
                                                            {
                                                                config?.doctors.map((item) => {
                                                                    return (
                                                                        <Option key={item.user_id} value={item.user_id} label={item.full_name}>{item.full_name}</Option>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    ) : null
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item>
                                        {getFieldDecorator("time", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: isValidateTime(currentExaminationVisit?.time) ? moment(currentExaminationVisit?.time, "YYYY-MM-DD HH:mm:ss") : null
                                        })(
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                showTime={{ format: 'HH:mm' }}
                                                format="DD/MM/YYYY HH:mm"
                                                placeholder='Chọn thời điểm khám'
                                                allowClear={false}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item style={{ marginBottom: 0 }}>
                                        {getFieldDecorator("note", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: _newLine(currentExaminationVisit?.note) || ''
                                        })(
                                            <Input.TextArea
                                                rows={10}
                                                placeholder='Kết quả khám'
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                    : null}
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => ({
    config: state.config
});

const mapDispatchToProps = (dispatch) => ({
    updateExamVisit: (id, data) => dispatch(updateExamVisit(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ExaminationVisitEditForm' })(ExaminationVisitEditForm));
