import React, { useEffect, useState } from "react";
import { Button, Input, Row, Col, Collapse, Modal } from "antd";
import ImageSelector from '../../components/ImageSelector';
import { generateId } from "../../utils/helpers";
import moment from "moment";

const { Panel } = Collapse;
const { confirm } = Modal;
const datetime = moment().format('YYYY-MM-DD HH:mm:ss');

const BeforeAfterForm = (props) => {

    const { onChangeData, currentData } = props;

    const [formData, setFormData] = useState(currentData || defaultFormData.data);

    // Hàm xử lý thêm record mới
    const addRecord = (profileKey) => {
        const newRecord = {
            id: generateId(),
            datetime: datetime,
            angles: [
                {
                    id: generateId(),
                    name: '1. Góc chính diện',
                    images: [], // [{id: 1, src: "https://picsum.photos/200"}, {id: 2, src: "https://picsum.photos/id/237/200/300"}],
                },
                {
                    id: generateId(),
                    name: '2. Góc chính diện cười',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '3. Góc quay trái',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '4. Góc quay trái cười',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '5. Hàm chính diện',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '6. Hàm chếch 45° trái',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '7. Hàm chếch 45° phải',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '8. Hàm trên chính diện',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '9. Hàm trên chính diện có uốn lưỡi',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '10. Góc 45° trái',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '11. Góc 45° trái cười',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '12. Góc 45° phải',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '13. Góc 45° phải cười',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '14. Phim chính diện',
                    images: [],
                },
                {
                    id: generateId(),
                    name: '15. Phim quay trái',
                    images: [],
                }
            ],
            note: "",
        };

        setFormData((prevData) => ({
            ...prevData,
            [profileKey]: {
                ...prevData[profileKey],
                records: [...prevData[profileKey].records, newRecord],
            },
        }));
    };

    // Hàm xử lý thay đổi ghi chú
    const handleNoteChange = (profileKey, recordId, newNote) => {
        setFormData((prevData) => ({
            ...prevData,
            [profileKey]: {
                ...prevData[profileKey],
                records: prevData[profileKey].records.map((record) =>
                    record.id === recordId ? { ...record, note: newNote } : record
                ),
            },
        }));
    };

    // Hàm xử lý upload ảnh
    const handleImageChange = (profileKey, recordId, angleId, fileList, changeDetails) => {
        const { action, removedId } = changeDetails || {};

        const uploadedImages = fileList.map((file) => ({
            id: file.id,
            src: file.originalImage,
        }));

        setFormData((prevData) => ({
            ...prevData,
            [profileKey]: {
                ...prevData[profileKey],
                records: prevData[profileKey].records.map((record) => {
                    if (record.id !== recordId) return record; // Bỏ qua nếu không khớp recordId

                    return {
                        ...record,
                        angles: record.angles.map((angle) => {
                            if (angle.id !== angleId) return angle; // Bỏ qua nếu không khớp angleId

                            let updatedImages = angle.images || []; // Xử lý nếu angle.images chưa được khởi tạo

                            if (action === 'remove') {
                                // Xóa ảnh dựa trên `removedId`
                                updatedImages = updatedImages.filter((img) => img.id !== removedId);
                            } else {
                                // Thêm ảnh mới, tránh trùng lặp
                                updatedImages = [
                                    ...updatedImages.filter(
                                        (img) => !uploadedImages.some((newImg) => newImg.id === img.id)
                                    ),
                                    ...uploadedImages,
                                ];
                            }

                            return {
                                ...angle,
                                images: updatedImages,
                            };
                        }),
                    };
                }),
            },
        }));
    };

    const handleDelete = (profileKey, recordId) => {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá record này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => {
                setFormData((prevData) => ({
                    ...prevData,
                    [profileKey]: {
                        ...prevData[profileKey],
                        records: prevData[profileKey].records.filter((record) => record.id !== recordId),
                    },
                }));
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            },
            onCancel() { },
        })
    }

    useEffect(() => {
        if (formData) {
            onChangeData(formData);
        }
    }, [formData]);

    return (
        <Row gutter={16} className="fix-padding-collapse">
            {Object.keys(formData).map((key) => {
                const profile = formData[key];
                return (
                    <Col xs={24} md={24} key={key}>
                        <Collapse defaultActiveKey={['0']} ghost>
                            <Panel header={<strong>{profile.name}</strong>} forceRender key={key}>
                                <Row gutter={16}>
                                    {profile?.records?.map((record, index) => (
                                        <Col xs={24} md={12} key={index}>
                                            <div className="border-dashed line-profile-image">
                                                <Row gutter={16}>
                                                    {record?.angles?.map((angle, angleIndex) => (
                                                        <Col xs={24} md={12} key={angleIndex}>
                                                            <div className="mb-2">
                                                                <label>{angle.name}</label>
                                                                <ImageSelector
                                                                    onChangeData={(fileList, changeDetails) => handleImageChange(key, record.id, angle.id, fileList, changeDetails)} // Gắn hàm xử lý thay đổi dữ liệu
                                                                    multiple={false} // Cho phép chọn nhiều ảnh
                                                                    maxCount={1} // Giới hạn tối đa 50 ảnh
                                                                    uploadText="Chọn ảnh"
                                                                    defaultFileList={angle.images}
                                                                />
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>

                                                <div>
                                                    <label>Ghi chú</label>
                                                    <Input.TextArea
                                                        rows={2}
                                                        value={record.note}
                                                        onChange={(e) => handleNoteChange(key, record.id, e.target.value)}
                                                    />
                                                </div>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => handleDelete(key, record.id)}
                                                    danger
                                                    size="small"
                                                    className="profile-image-delete-button"
                                                >
                                                    Xóa
                                                </Button>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                                <Button
                                    onClick={() => addRecord(key)}
                                    type="dashed"
                                    size="small"
                                    className="mt-10 mb-10"
                                    style={{ color: '#1890ff' }}
                                >
                                    Thêm record
                                </Button>
                            </Panel>
                        </Collapse>
                    </Col>
                );
            })}
        </Row>
    );
};

export default BeforeAfterForm;

const defaultFormData = {
    service_id: 78,
    data: {
        anh_before: {
            type: "anh_before",
            name: "Ảnh before",
            records: [
                {
                    id: generateId(),
                    datetime: datetime,
                    angles: [
                        {
                            id: generateId(),
                            name: '1. Góc chính diện',
                            images: [], // [{id: 1, src: "https://picsum.photos/200"}, {id: 2, src: "https://picsum.photos/id/237/200/300"}],
                        },
                        {
                            id: generateId(),
                            name: '2. Góc chính diện cười',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '3. Góc quay trái',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '4. Góc quay trái cười',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '5. Hàm chính diện',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '6. Hàm chếch 45° trái',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '7. Hàm chếch 45° phải',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '8. Hàm trên chính diện',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '9. Hàm trên chính diện có uốn lưỡi',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '10. Góc 45° trái',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '11. Góc 45° trái cười',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '12. Góc 45° phải',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '13. Góc 45° phải cười',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '14. Phim chính diện',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '15. Phim quay trái',
                            images: [],
                        }
                    ],
                    note: "",
                },
            ],
        },
        anh_after: {
            type: "anh_after",
            name: "Ảnh after",
            records: [
                {
                    id: generateId(),
                    datetime: datetime,
                    angles: [
                        {
                            id: generateId(),
                            name: '1. Góc chính diện',
                            images: [], // [{id: 1, src: "https://picsum.photos/200"}, {id: 2, src: "https://picsum.photos/id/237/200/300"}],
                        },
                        {
                            id: generateId(),
                            name: '2. Góc chính diện cười',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '3. Góc quay trái',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '4. Góc quay trái cười',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '5. Hàm chính diện',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '6. Hàm chếch 45° trái',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '7. Hàm chếch 45° phải',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '8. Hàm trên chính diện',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '9. Hàm trên chính diện có uốn lưỡi',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '10. Góc 45° trái',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '11. Góc 45° trái cười',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '12. Góc 45° phải',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '13. Góc 45° phải cười',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '14. Phim chính diện',
                            images: [],
                        },
                        {
                            id: generateId(),
                            name: '15. Phim quay trái',
                            images: [],
                        }
                    ],
                    note: "",
                },
            ],
        }
    }
};