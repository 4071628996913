import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import qs from 'qs';
import { checkPermission } from '../../utils/permission';
import { withRouter } from 'react-router-dom';
import { cleanObject, repeatPhoneNumber, ConvertcustomerGender, ConvertCustomerCardStatus, isValidDate } from '../../utils/helpers';
import { Button, Col, Row, Space, Spin, Divider, Descriptions, Typography, Tag, Image, Alert, Collapse, Card } from "antd";
import ReactHtmlParser from 'react-html-parser';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import NumberFormat from 'react-number-format';
import { Form } from '@ant-design/compatible';
import CustomerForm from "./CustomerForm";
import { isMobile } from "react-device-detect";
import BaseSelect from '../../components/Elements/BaseSelect';
import { getListCustomerActive, getSpecificCustomer } from '../../redux/actions/CustomerActions';
import AppointmentHistory from "./appointment-history/AppointmentHistory";
import CustomerServices from "./service/CustomerServices";
import Payment from "./payment/Payment";
import ProfileImages from "./profile-images/ProfileImages";
import { getReceiptsByCustomerId } from '../../redux/actions/CashReceiptActions';
import { getMediaCustomer } from '../../redux/actions/MediaAction';

const { Paragraph } = Typography;
const { Panel } = Collapse;

const CustomerInfo = (props) => {

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const section4Ref = useRef(null);
    const section5Ref = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [hasCustomer, setHasCustomer] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customerQuery, setCustomerQuery] = useState('');
    const [paymentData, setPaymentData] = useState([]);
    const [profileData, setProfileData] = useState([]);
    const query = qs.parse(props.location.search.slice(1));

    const fetchData = async (type, customer) => {
        setIsLoading(true);
        if (type === 'payment') {
            const res = await props.getReceiptsByCustomerId(customer);
            setPaymentData(res.data);
        } else if (type === 'info') {
            await onChangeCustomer(customer);
        } else if (type === 'appointment-history') {

        } else if (type === 'service') {

        } else if (type === 'profile') {
            try {
                const res = await props.getMediaCustomer(customer);

                // Kiểm tra dữ liệu trả về
                if (res && res.data) {
                    setProfileData(res.data);
                } else {
                    // Xử lý trường hợp không có dữ liệu
                    console.error('Dữ liệu không hợp lệ hoặc rỗng:', res);
                    setProfileData(null); // Hoặc giá trị mặc định nếu cần
                }
            } catch (error) {
                // Xử lý ngoại lệ
                console.error('Lỗi khi gọi API:', error);
                setProfileData(null); // Hoặc giá trị mặc định
            }
        }
        setIsLoading(false);
    };

    const handleScroll = (ref, type) => {
        if (ref.current) {
            fetchData(type, query.customer).then(() => {
                const spaceTop = isMobile ? 6 : 100;
                const offsetTop = ref === section1Ref ? ref.current.getBoundingClientRect().top + window.scrollY - 200 : ref.current.offsetTop + spaceTop;
                window.scrollTo({ top: offsetTop, behavior: 'smooth' });
            });
        }
    };

    useEffect(() => {
        if (query.customer) {
            props.getListCustomerActive({ keyword: query.customer });
            //onChangeCustomer(query.customer);
            fetchData('info', query.customer);
            fetchData('payment', query.customer);
            fetchData('appointment-history', query.customer);
            fetchData('service', query.customer);
            fetchData('profile', query.customer);

        }
    }, []);

    useEffect(() => {
        if (customer) {
            //console.log('customer', customer);
            setHasCustomer(true);
            //window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            setHasCustomer(false);
        }
    }, [customer]);

    const fectAllTabData = async (id) => {
        //setIsLoading(true);
        //fetchData('info', id);
        fetchData('payment', id);
        //fetchData('appointment-history', id);
        //fetchData('service', id);
        fetchData('profile', id);
        setIsLoading(false);
    }

    const onChangeFilter = (name, value) => {
        let query = qs.parse(props.location.search.slice(1));
        query[name] = value;
        query = cleanObject(query);
        props.history.push({
            pathname: props.location.pathname,
            search: qs.stringify(query)
        });
    };

    const onSearchCustomer = (val) => {
        setCustomerQuery(val);
        props.getListCustomerActive({ keyword: val });
    };

    const handleCustomerScroll = (e) => {
        props.getListCustomerActive({ keyword: customerQuery, page: props.pagination_active.currentPage + 1 });
    }

    const onChangeCustomer = async (id) => {
        setIsLoading(true);
        await props.getSpecificCustomer(id).then((item) => {
            fectAllTabData(id);
            onChangeFilter('customer', id);
            setCustomer(item);
            setIsLoading(false);
        }).catch(err => {
            setCustomer(null);
            setIsLoading(false);
        });
    }

    const customerActiveList = props.customerActiveList;

    return (
        <div>
            {checkPermission('customer_data', 'lookup') ? (
                <Row gutter={16} className='search-customer'>
                    <Col xs={24} md={24} className="box-item-mobile mt-0 mb-0">
                        <Card
                            size="small"
                            bordered={false}
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                                className="card-search-customer"
                            >
                                <BaseSelect
                                    options={customerActiveList || []}
                                    optionValue="id"
                                    optionLabel="full_name"
                                    additionalLabel="phone"
                                    additionalLabel2="code"
                                    isCustomerList={true}
                                    showSearch
                                    defaultValue={parseInt(query.customer) || undefined}
                                    placeholder="Nhập mã, tên, 3 số cuối sđt của khách hàng"
                                    onSearch={(val) => onSearchCustomer(val)}
                                    onScrollEnd={(e) => handleCustomerScroll(e)}
                                    onChange={(value) => onChangeCustomer(value)}
                                    style={{ width: '80%' }}
                                    size="large"
                                    onClear={() => { onChangeFilter('customer', '') }}
                                    allowClear={false}
                                    value={parseInt(query.customer) || undefined}
                                />
                                <Button type="default" size="large" onClick={() => { onChangeFilter('customer', ''); onChangeCustomer(null) }}>Tìm lại</Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            ) : null}

            {hasCustomer ? (
                <div className="customer-info-data">
                    <Row gutter={16} className="customer-action-bar">
                        <Col xs={24} md={24} className='box-item-mobile'>
                            <Card
                                size="small"
                                bordered={false}
                            >
                                <Space size={[5, 5]} wrap center='center' style={{ justifyContent: 'center' }}>
                                    <Button onClick={() => handleScroll(section1Ref, 'info')} type="primary">Thông tin khách hàng</Button>
                                    <Button onClick={() => handleScroll(section2Ref, 'appointment-history')} type='primary'>Lịch sử cuộc hẹn</Button>
                                    <Button onClick={() => handleScroll(section3Ref, 'service')} type="primary">Dịch vụ chốt</Button>
                                    <Button onClick={() => handleScroll(section4Ref, 'payment')} type="primary">Thanh toán</Button>
                                    <Button onClick={() => handleScroll(section5Ref, 'profile')} type="primary">Cập nhật ảnh</Button>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                    <Spin tip="Loading..." spinning={isLoading}>
                        <div ref={section1Ref}></div>
                        <Row justify="end" gutter={16}>
                            <Col xs={24} md={24} className='box-item-mobile'>
                                <Card size="small"
                                    title={<><Tag color='#0b2b33'>1</Tag>  Thông tin khách hàng</>}
                                    bordered={false}
                                >
                                    <Collapse
                                        expandIconPosition="end"
                                        ghost
                                        defaultActiveKey={['1']}
                                    >
                                        <Panel header={<>
                                            <span className="ant-descriptions-item-label">Tên khách hàng </span> {customer?.full_name}
                                        </>} key="1">
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Mã khách hàng">
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: customer?.code,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <Tag color='#1890ff'>{customer?.code}</Tag>
                                                    </Paragraph>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Mã số sổ khám">
                                                    {
                                                        customer?.medical_book_number ? (
                                                            <Paragraph style={{ margin: 0 }} copyable={
                                                                {
                                                                    text: customer ? customer.medical_book_number : null,
                                                                    tooltips: false
                                                                }
                                                            }>
                                                                {customer ? customer.medical_book_number : null}
                                                            </Paragraph>
                                                        ) : null
                                                    }

                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Số điện thoại">
                                                    {customer ? (
                                                        <HandlePhoneNumber
                                                            permission={checkPermission('customer', 'phonenumber')}
                                                            inputPinCode={checkPermission('customer', 'input_pin_code')}
                                                            customer_id={customer.id}
                                                            hiddenPhoneNumber={repeatPhoneNumber(customer?.phone)}
                                                            fullPhoneNumber={customer?.phone}
                                                        />
                                                    ) : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Cơ sở">
                                                    {customer?.branch_name}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Panel>
                                        <Panel header={<>
                                            <span className="ant-descriptions-item-label">Chi phí </span>
                                            <span className="ant-descriptions-item-content">
                                                <Tag color='green'>
                                                    <NumberFormat value={customer?.revenue} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                </Tag>
                                            </span>

                                        </>} key="2">
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Ưu đãi">
                                                    <Tag color='blue'>
                                                        <NumberFormat value={customer?.total_discount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                    </Tag>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Đã thanh toán">
                                                    <Tag color='cyan'>
                                                        <NumberFormat value={customer?.total_amount_paid} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                    </Tag>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Còn nợ">
                                                    <Tag color='red'>
                                                        <NumberFormat value={customer?.customer_debt} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                    </Tag>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            {isMobile ? <Divider className="no-margin" /> : null}
                                        </Panel>
                                        <Panel header={<>
                                            <span className="ant-descriptions-item-label">Hạng khách hàng </span>
                                            <span className="ant-descriptions-item-content">
                                                <Tag color="#f39c12">{customer?.customer_class}</Tag>
                                            </span>
                                        </>} key="3">
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Nguồn">
                                                    {customer?.customer_source}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Mã số thẻ cũ">
                                                    {
                                                        customer?.card_number ? (
                                                            <Paragraph style={{ margin: 0 }} copyable={
                                                                {
                                                                    text: customer?.card_number,
                                                                    tooltips: false
                                                                }
                                                            }>
                                                                {customer?.card_number}
                                                            </Paragraph>
                                                        ) : null
                                                    }

                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Mã số thẻ mới">
                                                    {
                                                        customer?.card_number_new ? (
                                                            <Paragraph style={{ margin: 0 }} copyable={
                                                                {
                                                                    text: customer?.card_number_new,
                                                                    tooltips: false
                                                                }
                                                            }>
                                                                {customer?.card_number_new}
                                                            </Paragraph>
                                                        ) : null
                                                    }

                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Giới tính">
                                                    {customer ? ConvertcustomerGender(customer.gender) : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Năm sinh">
                                                    {customer?.birthday}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Trạng thái thẻ">
                                                    {customer ? ConvertCustomerCardStatus(customer?.card_status) : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Ngày tạo">
                                                    {isValidDate(customer?.created_at)} bởi: {customer?.created_user}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Panel>
                                    </Collapse>
                                    <Row gutter={16} className='mt-3'>
                                        <Col xs={24} md={24}>
                                            <Alert
                                                message={<span style={{ fontWeight: '600' }}>Ghi chú</span>}
                                                description={
                                                    <>
                                                        {customer ? ReactHtmlParser(customer.note) : null}

                                                        {Array.isArray(customer?.banner_ads) && customer?.banner_ads.length ? (
                                                            <>
                                                                <Divider orientation="left" orientationMargin={0}>Banner quảng cáo</Divider>
                                                                <Image.PreviewGroup>
                                                                    <Space>
                                                                        {
                                                                            customer?.banner_ads.map((item, index) => {
                                                                                return (
                                                                                    <Image
                                                                                        key={index}
                                                                                        height={50}
                                                                                        width={50}
                                                                                        src={item.src}
                                                                                        style={{
                                                                                            border: '1px solid #dedede',
                                                                                            objectFit: 'cover',
                                                                                            objectPosition: 'center'
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            </>
                                                        ) : null}
                                                    </>
                                                }
                                                type="info"
                                                className='fix-alert-element'
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <div ref={section2Ref}></div>
                        <AppointmentHistory customer={customer} />
                        <div ref={section3Ref}></div>
                        <CustomerServices
                            customer={customer}
                            reloadData={() => onChangeCustomer(query.customer)}
                        />
                        <div ref={section4Ref}></div>
                        <Payment
                            customer={customer}
                            data={paymentData}
                            reloadData={() => handleScroll(section4Ref, 'payment')}
                        />
                        <div ref={section5Ref}></div>
                        <ProfileImages
                            customer={customer}
                            data={profileData}
                            reloadData={() => handleScroll(section5Ref, 'profile')}
                        />
                    </Spin>
                </div>
            ) : (
                <CustomerForm setCustomer={setCustomer} />
            )}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerActive: (filter) => dispatch(getListCustomerActive(filter)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
        getReceiptsByCustomerId: (id) => dispatch(getReceiptsByCustomerId(id)),
        getMediaCustomer: (id) => dispatch(getMediaCustomer(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerInfo' })(CustomerInfo)));