import React, { useEffect, useState } from "react";
import { Button, Input, Row, Col, Collapse, Modal } from "antd";
import ImageSelector from '../../components/ImageSelector';
import { generateId } from "../../utils/helpers";
import moment from "moment";

const { Panel } = Collapse;
const { confirm } = Modal;
const datetime = moment().format('YYYY-MM-DD HH:mm:ss');

const ProfileForm = (props) => {

    const { onChangeData, currentData } = props;

    const [formData, setFormData] = useState(currentData || defaultFormData);

    // Hàm xử lý thêm record mới
    const addRecord = (profileKey) => {
        const newRecord = {
            id: generateId(),
            datetime: datetime,
            images: [],
            note: "",
        };

        setFormData((prevData) => ({
            ...prevData,
            [profileKey]: {
                ...prevData[profileKey],
                records: [...prevData[profileKey].records, newRecord],
            },
        }));
    };

    // Hàm xử lý thay đổi ghi chú
    const handleNoteChange = (profileKey, recordId, newNote) => {
        setFormData((prevData) => ({
            ...prevData,
            [profileKey]: {
                ...prevData[profileKey],
                records: prevData[profileKey].records.map((record) =>
                    record.id === recordId ? { ...record, note: newNote } : record
                ),
            },
        }));
    };

    // Hàm xử lý upload ảnh
    const handleImageChange = (profileKey, recordId, fileList, changeDetails) => {
        const { action, removedId } = changeDetails || {};

        const uploadedImages = fileList.map((file) => ({
            id: file.id,
            src: file.originalImage,
        }));

        setFormData((prevData) => ({
            ...prevData,
            [profileKey]: {
                ...prevData[profileKey],
                records: prevData[profileKey].records.map((record) => {
                    if (record.id !== recordId) return record;

                    let updatedImages = record.images;

                    if (action === 'remove') {
                        // Xóa ảnh cụ thể dựa trên removedId
                        updatedImages = record.images.filter((img) => img.id !== removedId);
                    } else {
                        // Thêm ảnh mới (tránh trùng lặp)
                        updatedImages = [
                            ...record.images.filter(
                                (img) => !uploadedImages.some((newImg) => newImg.id === img.id)
                            ),
                            ...uploadedImages,
                        ];
                    }

                    return {
                        ...record,
                        images: updatedImages,
                    };
                }),
            },
        }));
    };

    const handleDelete = (profileKey, recordId) => {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá record này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => {
                setFormData((prevData) => ({
                    ...prevData,
                    [profileKey]: {
                        ...prevData[profileKey],
                        records: prevData[profileKey].records.filter((record) => record.id !== recordId),
                    },
                }));
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            },
            onCancel() { },
        })
    }

    useEffect(() => {
        if (formData) {
            onChangeData(formData);
        }
    }, [formData]);

    return (
        <Row gutter={16} className="fix-padding-collapse">
            {Object.keys(formData).map((key) => {
                const profile = formData[key];
                return (
                    <Col xs={24} md={24} key={key}>
                        <Collapse defaultActiveKey={['0']} ghost>
                            <Panel header={<strong>{profile.name}</strong>} forceRender key={key}>
                                <Row gutter={16}>
                                    {profile?.records?.map((record, index) => (
                                        <Col xs={24} md={12} key={index}>
                                            <div className="border-dashed line-profile-image">
                                                <div className="mb-2">
                                                    <label>Ảnh</label>
                                                    <ImageSelector
                                                        onChangeData={(fileList, changeDetails) => handleImageChange(key, record.id, fileList, changeDetails)} // Gắn hàm xử lý thay đổi dữ liệu
                                                        multiple={true} // Cho phép chọn nhiều ảnh
                                                        maxCount={50} // Giới hạn tối đa 50 ảnh
                                                        uploadText="Chọn ảnh"
                                                        defaultFileList={record.images}
                                                    />
                                                </div>
                                                <div>
                                                    <label>Ghi chú</label>
                                                    <Input.TextArea
                                                        rows={2}
                                                        value={record.note}
                                                        onChange={(e) => handleNoteChange(key, record.id, e.target.value)}
                                                    />
                                                </div>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => handleDelete(key, record.id)}
                                                    danger
                                                    size="small"
                                                    className="profile-image-delete-button"
                                                >
                                                    Xóa
                                                </Button>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                                <Button
                                    onClick={() => addRecord(key)}
                                    type="dashed"
                                    size="small"
                                    className="mt-10 mb-10"
                                    style={{ color: '#1890ff' }}
                                >
                                    Thêm record
                                </Button>
                            </Panel>
                        </Collapse>
                    </Col>
                );
            })}
        </Row>
    );
};

export default ProfileForm;

const defaultFormData = {
    anh_chan_dung: {
        type: "anh_chan_dung",
        name: "Ảnh chân dung",
        records: [
            {
                id: generateId(),
                datetime: datetime,
                images: [], // [{id: 1, src: "https://picsum.photos/200"}, {id: 2, src: "https://picsum.photos/id/237/200/300"}],
                note: "",
            },
        ],
    },
    phieu_tu_van: {
        type: "phieu_tu_van",
        name: "Phiếu tư vấn",
        records: [
            {
                id: generateId(),
                datetime: datetime,
                images: [],
                note: "",
            },
        ],
    },
    so_kham: {
        type: "so_kham",
        name: "Sổ khám",
        records: [
            {
                id: generateId(),
                datetime: datetime,
                images: [],
                note: "",
            },
        ],
    },
    phim_x_quang: {
        type: "phim_x_quang",
        name: "Phim X Quang",
        records: [
            {
                id: generateId(),
                datetime: datetime,
                images: [],
                note: "",
            },
        ],
    },
    giay_to_khac: {
        type: "giay_to_khac",
        name: "Giấy tờ khác",
        records: [
            {
                id: generateId(),
                datetime: datetime,
                images: [],
                note: "",
            },
        ],
    },
};