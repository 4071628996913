import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { ClockCircleOutlined, EyeOutlined, UserOutlined, } from '@ant-design/icons';
import { Tag, Typography, Row, Col, Card, Divider, Descriptions, Tabs, Spin } from 'antd';
import { cleanObject, isValidDate } from '../../../utils/helpers';
import { getListCustomerHasPerformed, getListScheduleUpcoming } from '../../../redux/actions/AssistantActions';
import { getSpecificCustomerSchedule } from '../../../redux/actions/CustomerScheduleActions';
import { getSpecificCustomer } from '../../../redux/actions/CustomerActions';
import moment from 'moment';
import BaseSelect from '../../../components/Elements/BaseSelect';
import ScheduleDetail from '../components/ScheduleDetail';
import CustomerDetail from '../components/CustomerDetail';
const { Paragraph } = Typography;
const { TabPane } = Tabs;

class Assistant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            activeTab: 'schedule_upcoming',
            isOpenCustomerDetail: false,
            isOpenScheduleDetail: false,
            customer_id: null
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.loadData(query);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.loadData(query);
        }
    }

    loadData(query) {
        this.setState({ isLoading: true });
        this.props.getListCustomerHasPerformed({ assistant_id: query.assistant_id }).then((res) => {
            this.setState({ isLoading: false });
        });

        this.props.getListScheduleUpcoming({ assistant_id: query.assistant_id }).then((res) => {
            this.setState({ isLoading: false });
        });
    }

    onChangeTab = (value) => {
        this.setState({ activeTab: value });
    }

    onChangeFilter = (name, value) => {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    customerDetail(id) {
        this.setState({ customer_id: id });
        this.props.getSpecificCustomer(id).then(res => {
            this.openCustomerDetail(true, res);
        })
    }

    openCustomerDetail(isOpenCustomerDetail, data = null) {
        this.setState({ isOpenCustomerDetail: isOpenCustomerDetail, currentItem: data });
    }

    scheduleDetail(id) {
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.openScheduleDetail(true, res);
        })
    }

    openScheduleDetail(isOpenScheduleDetail, data = null) {
        this.setState({ isOpenScheduleDetail: isOpenScheduleDetail, currentItem: data });
    }

    render() {
        var { isOpenCustomerDetail, isOpenScheduleDetail, activeTab, isLoading, currentItem, customer_id } = this.state;
        const { customer_has_performed, customer_schedule_upcoming, doctors, authUser } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));

        return (
            <div>
                <Row gutter={16}>
                    <Col xs={24} md={24} className='box-item-mobile box-item-mobile-header'>
                        <Card size="small"
                            bordered={false}
                            headStyle={{ border: 0 }}
                        >
                            <Row gutter={16} align="middle">
                                {authUser.role_code === 'ADMIN' ? (
                                    <Col xs={24} md={24} lg={6} xl={6}>
                                        <BaseSelect
                                            options={doctors || []}
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            showSearch
                                            placeholder="Chọn nhân viên"
                                            className="w-100"
                                            onChange={(value) => this.onChangeFilter('assistant_id', value)}
                                            defaultValue={query.assistant_id ? parseInt(query.assistant_id) : null}
                                        />
                                    </Col>
                                ) : null}
                                <Col xs={24} md={24} lg={18} xl={18}>
                                    <Tabs
                                        activeKey={activeTab}
                                        onChange={(activeTab) => this.onChangeTab(activeTab)}
                                        size='small'
                                        className='tabs-custom-children'
                                        centered
                                    >
                                        <TabPane key="schedule_upcoming" tab={<>Lịch bệnh nhân sắp tới</>} forceRender></TabPane>
                                        <TabPane key="has_performed" tab={<>Bệnh nhân đã thực hiện</>} forceRender></TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Spin tip="Loading..." spinning={isLoading}>
                    {activeTab == 'schedule_upcoming' ? (
                        <Row gutter={16} className='assistant-doctor'>
                            {
                                customer_schedule_upcoming && customer_schedule_upcoming.length > 0 ? customer_schedule_upcoming.map((item, index) => {
                                    var diffDate = item.time ? moment(item.time, "YYYY-MM-DD").diff(moment().startOf('day'), 'days') : 0;
                                    return (
                                        <Col key={index} xs={24} md={12} lg={8} className='box-item-mobile'>
                                            <Card size="small"
                                                title={<>{<ClockCircleOutlined />}&nbsp;
                                                    {isValidDate(item.time)} -&nbsp;
                                                    {
                                                        diffDate ? (
                                                            <span>
                                                                {
                                                                    diffDate > 0 ? <Tag color="#f50">Còn lại {diffDate} ngày</Tag> : <Tag color="red">Cách đây {-1 * diffDate} ngày</Tag>
                                                                }
                                                            </span>
                                                        ) : (
                                                            <>
                                                                {
                                                                    item.time != null ? (
                                                                        <Tag color="green">Hôm nay</Tag>
                                                                    ) : null
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </>}
                                                extra={
                                                    <>
                                                        <span className='item-action-btn' onClick={() => this.scheduleDetail(item.id)}>
                                                            <EyeOutlined /> Xem
                                                        </span>
                                                    </>
                                                }
                                            >
                                                <Row gutter={16}>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Khách hàng">
                                                                <span className='item-action-btn' onClick={() => this.customerDetail(item.customer_id)}>
                                                                    {item.customer_name} <EyeOutlined />
                                                                </span>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className='no-margin' />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Mã khách hàng">
                                                                <Paragraph style={{ margin: 0 }} copyable={
                                                                    {
                                                                        text: item ? item.customer_code : null,
                                                                        tooltips: false
                                                                    }
                                                                }>
                                                                    {item.customer_code}
                                                                </Paragraph>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className='no-margin' />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Số sổ khám">
                                                                {item.medical_book_number && (
                                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                                        {
                                                                            text: item ? item.medical_book_number : null,
                                                                            tooltips: false
                                                                        }
                                                                    }>
                                                                        {item.medical_book_number}
                                                                    </Paragraph>
                                                                )}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    )
                                }) : (
                                    <Col xs={24} md={24} className='box-item-mobile'>
                                        <div style={{ textAlign: 'center', width: '100%' }} className='no-data'>
                                            <Card size="small" bordered={false}> Chưa có dữ liệu</Card>
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    ) : (
                        <Row gutter={16} className='assistant-doctor'>
                            {
                                customer_has_performed && customer_has_performed.length > 0 ? customer_has_performed.map((item, index) => {
                                    return (
                                        <Col key={index} xs={24} md={12} lg={8} className='box-item-mobile'>
                                            <Card
                                                size="small"
                                                title={<>{<UserOutlined />}&nbsp; {item.full_name}</>}
                                                extra={
                                                    <>
                                                        <span className='item-action-btn' onClick={() => this.customerDetail(item.id)}>
                                                            <EyeOutlined /> Xem
                                                        </span>
                                                    </>
                                                }
                                            >
                                                <Row gutter={16}>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Mã khách hàng">
                                                                <Paragraph style={{ margin: 0 }} copyable={
                                                                    {
                                                                        text: item ? item.code : null,
                                                                        tooltips: false
                                                                    }
                                                                }>
                                                                    {item.code}
                                                                </Paragraph>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className='no-margin' />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Số sổ khám">
                                                                {item.medical_book_number && (
                                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                                        {
                                                                            text: item ? item.medical_book_number : null,
                                                                            tooltips: false
                                                                        }
                                                                    }>
                                                                        {item.medical_book_number}
                                                                    </Paragraph>
                                                                )}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    )
                                }) : (
                                    <Col xs={24} md={24} className='box-item-mobile'>
                                        <div style={{ textAlign: 'center', width: '100%' }} className='no-data'>
                                            <Card size="small" bordered={false}> Chưa có dữ liệu</Card>
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    )}
                </Spin>

                <ScheduleDetail
                    visible={isOpenScheduleDetail}
                    currentItem={currentItem}
                    onCancel={() => this.setState({ isOpenScheduleDetail: false })}
                />

                <CustomerDetail
                    visible={isOpenCustomerDetail}
                    currentItem={currentItem}
                    reloadData={() => this.customerDetail(customer_id)}
                    onCancel={() => this.setState({ isOpenCustomerDetail: false })}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        customer_schedule_upcoming: state.AssistantReducer.schedule_upcoming.customers,
        customer_has_performed: state.AssistantReducer.customer_performed.customers,
        doctors: state.config.doctors,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerHasPerformed: (filter) => dispatch(getListCustomerHasPerformed(filter)),
        getListScheduleUpcoming: (filter) => dispatch(getListScheduleUpcoming(filter)),
        getSpecificCustomerSchedule: (id) => dispatch(getSpecificCustomerSchedule(id)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Assistant' })(Assistant)));

