import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Tag, Button, Row, Col, Typography, Spin, Divider, Space, Image } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import { ConvertRewardType, isValidDate } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import RewardSpinForm from './RewardSpinForm';
import HtmlParser from 'react-html-parser';

const { Paragraph } = Typography;
const { Text } = Typography;

class RewardSpinDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenForm: false
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenForm(isOpenForm) {
        this.setState({ isOpenForm: isOpenForm });
    }

    render() {
        var { isOpenForm } = this.state;
        var { visible, currentItem, loadingDetail } = this.props;
        console.log(currentItem)
        return (
            <>
                <Drawer
                    visible={visible}
                    title={`Chi tiết khách hàng: ${currentItem?.full_name}`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '45%'}
                    extra={
                        checkPermission('reward_spin', 'update') ? (
                            <Button type="primary" onClick={() => this.toggleOpenForm(true)} icon={<EditOutlined />}>Cập nhật</Button>
                        ) : null
                    }
                >
                    {visible ? (
                        <div className='wrap-customer-detail'>
                            <Spin tip="Loading..." spinning={loadingDetail}>
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Tên khách hàng">
                                                {currentItem ? currentItem.full_name : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Mã khách hàng">
                                                {
                                                    currentItem?.code ? (
                                                        <Paragraph style={{ margin: 0 }} copyable={
                                                            {
                                                                text: currentItem ? currentItem.code : null,
                                                                tooltips: false
                                                            }
                                                        }>
                                                            {currentItem ? currentItem.code : null}
                                                        </Paragraph>
                                                    ) : null
                                                }

                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Mã số thẻ cũ">
                                                {
                                                    currentItem?.card_number ? (
                                                        <Paragraph style={{ margin: 0 }} copyable={
                                                            {
                                                                text: currentItem ? currentItem.card_number : null,
                                                                tooltips: false
                                                            }
                                                        }>
                                                            {currentItem ? currentItem.card_number : null}
                                                        </Paragraph>
                                                    ) : null
                                                }

                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Mã số thẻ mới">
                                                {
                                                    currentItem?.card_number_new ? (
                                                        <Paragraph style={{ margin: 0 }} copyable={
                                                            {
                                                                text: currentItem ? currentItem.card_number_new : null,
                                                                tooltips: false
                                                            }
                                                        }>
                                                            {currentItem ? currentItem.card_number_new : null}
                                                        </Paragraph>
                                                    ) : null
                                                }

                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Giải thưởng">
                                                {ConvertRewardType(currentItem?.type)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Trạng thái">
                                                {
                                                    currentItem?.status == 1 ? (
                                                        <Tag color='#015609'>Đã nhận tưởng</Tag>
                                                    ) : (
                                                        <Tag color='#ff7875'>Chưa nhận thưởng</Tag>
                                                    )
                                                }

                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ngày quay">
                                                {isValidDate(currentItem?.created_at)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ngày hết hạn">
                                                {isValidDate(currentItem?.expire_date, false)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ngày nhận thưởng">
                                                {isValidDate(currentItem?.received_date)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Tỉnh/Thành phố">
                                                {currentItem?.province_name}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Quận/Huyện">
                                                {currentItem?.district_name}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Phường/Xã">
                                                {currentItem?.wards_name}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Tổ">
                                                {currentItem?.residential_group}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Địa chỉ">
                                                {currentItem?.address}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ghi chú">
                                                {HtmlParser(currentItem?.note)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Text strong style={{ display: 'block' }} className='mb-1 mt-2'>Ảnh: </Text>
                                        {
                                            Array.isArray(currentItem.images) && currentItem.images.length ? (
                                                <Image.PreviewGroup>
                                                    <Space wrap>
                                                        {
                                                            currentItem.images.map((item, index) => {
                                                                return (
                                                                    <Image
                                                                        key={index}
                                                                        height={50}
                                                                        width={50}
                                                                        src={item?.src}
                                                                        style={{
                                                                            border: '1px solid #dedede',
                                                                            objectFit: 'cover',
                                                                            objectPosition: 'center',
                                                                        }}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </Space>
                                                </Image.PreviewGroup>
                                            ) : null
                                        }
                                        <Divider className='no-margin' />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Text strong style={{ display: 'block' }} className='mb-1 mt-2'>Video: </Text>
                                        {HtmlParser(currentItem?.videos)}
                                    </Col>

                                    {/* <Col xs={24} md={24}>
                                        <Text strong style={{ display: 'block' }} className='mb-1 mt-2'>Video: </Text>
                                        {
                                            Array.isArray(currentItem.videos) && currentItem.videos.length ? (
                                                <Image.PreviewGroup>
                                                    <Space wrap>
                                                        {
                                                            currentItem.videos.map((item, index) => {
                                                                return (
                                                                    <video controls height="400px">
                                                                        <source src={item?.src} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                )
                                                            })
                                                        }
                                                    </Space>
                                                </Image.PreviewGroup>
                                            ) : null
                                        }
                                        <Divider className='no-margin' />
                                    </Col> */}
                                </Row>
                            </Spin>
                        </div>
                    ) : null}
                </Drawer>
                <RewardSpinForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'RewardSpinDetail' })(RewardSpinDetail));
