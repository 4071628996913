import {
    CUSTOMER_SERVICES_GET_LIST,
    CUSTOMER_SERVICES_UPDATE_SPECIFIC,
    CUSTOMER_SERVICES_CREATE_NEW,
    CUSTOMER_SERVICES_REMOVE,
    CUSTOMER_SERVICES_GET_STATISTICAL,
    CUSTOMER_SERVICES
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    services: [],
    statistical: null,
    customer_services: [],
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CUSTOMER_SERVICES_GET_LIST: {
            return {
                ...state,
                services: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case CUSTOMER_SERVICES: {
            return {
                ...state,
                customer_services: action.payload.data,
            }
        }

        case CUSTOMER_SERVICES_CREATE_NEW: {
            return {
                ...state,
                services: [action.payload, ...state.services],
                total: state.total + 1
            }
        }
        case CUSTOMER_SERVICES_UPDATE_SPECIFIC: {
            let index = state.services.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.services];
            temp[index] = action.payload;
            return { ...state, services: temp }
        }
        case CUSTOMER_SERVICES_REMOVE: {
            let temp = state.services.filter(item => {
                //console.log(action.payload)
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, services: temp }
        }
        case CUSTOMER_SERVICES_GET_STATISTICAL:
            return {
                ...state,
                statistical: action.payload
            };
        default: return { ...state };
    }
}
