import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Tag, Row, Col, Typography, Table, Divider, Space, Image } from 'antd'
import NumberFormat from 'react-number-format';
import ReactHtmlParser from 'react-html-parser'
import { isMobile } from 'react-device-detect';
import { ConvertCashReceiptStatus, ConvertPaymentMethod, isValidDate } from '../../../utils/helpers';

const { Paragraph, Text } = Typography;

class PaymentDetail extends Component {
    constructor() {
        super();
        this.state = {
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    render() {
        var { visible, currentData } = this.props;

        var columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: "Sản phẩm/Dịch vụ",
                dataIndex: "name"
            },
            {
                title: "Loại",
                dataIndex: "type",
                width: '100px'
            },
            {
                title: "Số lượng",
                dataIndex: "quantity",
                width: '80px'
            },
            {
                title: "Đơn giá",
                dataIndex: "price",
                width: "120px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            },
            {
                title: "Thành tiền",
                dataIndex: "amount",
                width: "120px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            }
        ];

        return (
            <>
                <Drawer
                    visible={visible}
                    title={`Chi tiết phiếu thu: #${currentData?.id}`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '60%'}
                >
                    {visible ? (
                        <div className='wrap-customer-detail'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tên khách hàng">
                                            {currentData?.customer_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Mã khách hàng">
                                            <Paragraph style={{ margin: 0 }} copyable={
                                                {
                                                    text: currentData?.customer_code,
                                                    tooltips: false
                                                }
                                            }>
                                                <Tag>{currentData?.customer_code}</Tag>
                                            </Paragraph>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Mã phiếu">
                                            {currentData?.code}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Số phiếu giấy">
                                            {currentData?.paper_receipt_number}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tổng">
                                            <NumberFormat value={currentData?.total_amount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ưu đãi">
                                            <NumberFormat value={currentData?.special_discount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Doanh số">
                                            <NumberFormat value={currentData?.customer_revenue} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Số tiền thu">
                                            <NumberFormat value={currentData?.amount_paid} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Tổng đã thu">
                                            <NumberFormat value={currentData?.total_amount_paid} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Công nợ">
                                            <Tag color='red'><NumberFormat value={currentData?.remaining_debt} displayType={'text'} suffix="đ" thousandSeparator={true} /></Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Trạng thái">
                                            {ConvertCashReceiptStatus(currentData?.status)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Hình thức thu">
                                            {ConvertPaymentMethod(currentData?.payment_method)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày thu">
                                            {isValidDate(currentData?.payment_at, false)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người cập nhật">
                                            {currentData?.updated_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người tạo">
                                            {currentData?.created_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentData?.created_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ghi chú">
                                            {ReactHtmlParser(currentData?.note)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <div className='mb-1 mt-2'>
                                        <Text strong>Ảnh phiếu</Text>
                                    </div>
                                    <Image.PreviewGroup>
                                        <Space wrap>
                                            {
                                                currentData?.images.map((item, index) => {
                                                    return (
                                                        <Image
                                                            key={index}
                                                            height={50}
                                                            width={50}
                                                            src={item.src}
                                                            style={{
                                                                border: '1px solid #dedede',
                                                                objectFit: 'cover',
                                                                objectPosition: 'center',
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </Space>
                                    </Image.PreviewGroup>
                                </Col>
                            </Row>
                            <Row gutter={16} className='mt-3'>
                                <Col xs={24} md={24}>
                                    <Table
                                        className='mb-4'
                                        title={() => <strong style={{ marginLeft: '-8px' }}>Nội dung</strong>}
                                        size='small'
                                        rowKey='key'
                                        tableLayout="auto"
                                        columns={columns}
                                        locale={{ emptyText: "Chưa có dữ liệu" }}
                                        dataSource={currentData ? currentData.items : []}
                                        pagination={false}
                                        scroll={{
                                            x: 'max-content'
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                </Drawer>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'PaymentDetail' })(PaymentDetail));
