import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { Col } from 'antd';
import { } from "@ant-design/icons";
import UploadImage from './UploadImage';
import { NotificationManager } from 'react-notifications';
import CurrentImage from './CurrentImage';

const ImageBeforeAfterForm = (props) => {

    const { getImageBeforeAfter, currentImage } = props;

    const [resetUploadImagesBefore, setResetUploadImagesBefore] = useState(false);
    const [resetUploadImagesAfter, setResetUploadImagesAfter] = useState(false);
    const [imagesBefore, setImagesBefore] = useState(null);
    const [imagesAfter, setImagesAfter] = useState(null);

    const onChangeImagesBefore = (data) => {
        setResetUploadImagesBefore(false);
        setImagesBefore(data);
        NotificationManager.success("Tải ảnh before thành công.");
    };

    const onChangeImagesAfter = (data) => {
        setResetUploadImagesAfter(false);
        setImagesAfter(data);
        NotificationManager.success("Tải ảnh after thành công.");
    };

    useEffect(() => {
        if (getImageBeforeAfter) {
            const values = {}
            values.images_before = imagesBefore;
            values.images_after = imagesAfter;
            getImageBeforeAfter(values);
        }
    }, [imagesBefore, imagesAfter]);

    return (
        <>

            <Col xs={24} md={24}>
                <Form.Item label="Ảnh before" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                    {/* <CurrentImage
                            filedName="images_before"
                            currentImages={item.bulk_images_before}
                            itemKey={item.id}
                            getData={(data) => this.getImages(data)}
                        /> */}
                    <UploadImage
                        uploadText="Chọn ảnh"
                        multiple={true}
                        maxCount={10}
                        onChangeData={(data) => onChangeImagesBefore(data)}
                        isResetUpload={resetUploadImagesBefore}
                    />
                </Form.Item>
            </Col>
            <Col xs={24} md={24}>
                <Form.Item label="Ảnh after" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                    {/* <CurrentImage
                            filedName="images_after"
                            currentImages={item.bulk_images_after}
                            itemKey={item.id}
                            getData={(data) => this.getImages(data)}
                        /> */}
                    <UploadImage
                        uploadText="Chọn ảnh"
                        multiple={true}
                        maxCount={10}
                        onChangeData={(data) => onChangeImagesAfter(data)}
                        isResetUpload={resetUploadImagesAfter}
                    />
                </Form.Item>
            </Col>
        </>
    )
}


function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ImageBeforeAfterForm' })(ImageBeforeAfterForm)));
