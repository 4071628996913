import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Button, Col, Divider, Drawer, Input, Row, TreeSelect, Typography } from "antd";
import { isMobile } from 'react-device-detect';
import ImageSelector from '../../components/ImageSelector';
import BaseSelect from '../../components/Elements/BaseSelect';
import { NotificationManager } from 'react-notifications';
import { createMedia, updateMedia, getMediaCustomer } from '../../redux/actions/MediaAction';
import { getCustomerServices } from '../../redux/actions/CustomerServicesActions'
import { getMediaCategoriesActive } from '../../redux/actions/MediaCategoriesActions'
import { getListCustomerActive } from '../../redux/actions/CustomerActions';
import { getBranchActive } from '../../redux/actions/BranchActions';
import { _newLine } from "../../utils/helpers";
import ProfileForm from "./ProfileForm";
import { FileImageOutlined, UserOutlined } from "@ant-design/icons";
import BaseRadioList from "../../components/Elements/BaseRadios";
import BeforeAfterForm from "./BeforeAfterForm";

const { Text } = Typography;

class MediaForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isResetUpload: false,
            images: [],
            showCustomer: false,
            customerQuery: '',
            currentImages: [],
            showBaranch: false,
            customerImages: [],
            profileData: null,
            media: null,
            imageType: 'profile',
            imageBeforeAfter: []
        };
    }

    componentDidMount() {
        this.props.getListCustomerActive();
        this.props.getMediaCategoriesActive();
        this.props.getBranchActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.data && Array.isArray(nextProps?.currentItem?.data)) {
                this.setState({ currentImages: nextProps?.currentItem?.data })
            } else {
                this.setState({ currentImages: [] })
            }
            this.setState({
                showCustomer: nextProps?.currentItem?.type === 'customer_profile',
                showBaranch: nextProps?.currentItem?.type === 'branch',
                profileData: nextProps?.currentItem?.data,
                imageType: nextProps?.currentItem?.image_type,
                imageBeforeAfter: nextProps?.currentItem?.image_before_after
            });
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { images, currentImages, customerImages, imageBeforeAfter, imageType } = this.state;

                    const mergedData = [...images, ...currentImages];
                    // Đặt lại giá trị cho trường id theo thứ tự tuần tự
                    const updatedImages = mergedData.map((item, index) => ({
                        ...item,
                        id: index + 1, // Đặt lại id từ 1 đến tổng số phần tử
                    }));

                    values.images = updatedImages;
                    values.customer_images = customerImages;
                    values.image_before_after = imageBeforeAfter;

                    if (this.props.currentItem) {
                        values.image_type = imageType;
                        values.customer_id = this.props.currentItem.customer_id;
                        await this.props.updateMedia(this.props.currentItem.id, values);
                    } else {
                        await this.props.createMedia(values);
                    }
                    console.log("values: ", values)
                    this.props.reloadData();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onChange = (data) => {
        this.setState({
            isResetUpload: false,
            images: data
        });
    }

    onClose() {
        this.setState({
            isLoading: false,
            isResetUpload: true,
            images: [],
            showCustomer: false,
            customerImages: [],
            imageBeforeAfter: [],
            media: null,
            imageType: 'profile'
        });
        this.props.form.resetFields();
        this.props.onCancel();
    }

    onChangeCategory = (value) => {
        this.setState({
            showCustomer: [1].includes(value),
            showBaranch: [2].includes(value),
            customerImages: [],
            images: [],
            isResetUpload: true,
            currentImages: [],
            imageBeforeAfter: [],
            media: null,
            imageType: 'profile'
        });
    };

    onSearchCustomer(val) {
        this.setState({ customerQuery: val }, () => {
            this.props.getListCustomerActive({ keyword: val });
        })
    }

    handleScrollCustomer(e) {
        this.props.getListCustomerActive({ keyword: this.state.customerQuery, page: this.props.pagination_active.currentPage + 1 });
    }

    getImages(data) {
        this.setState({ currentImages: data })
    }

    async handleChangeCustomer(value) {
        try {
            this.props.getCustomerServices(value);
            const res = await this.props.getMediaCustomer(value);
            if (res && res.data) {
                this.setState({ profileData: res.data, media: res });
            } else {
                console.error('No data returned from the API.');
                this.setState({ profileData: [] });
            }
        } catch (error) {
            console.error('Error fetching customer data:', error);
            this.setState({ profileData: [] });
        }
    }

    getImageProfile = (data) => {
        console.log('customerImages', data);
        this.setState({ customerImages: data });
    }

    handleChangeImageType = (imageType) => {
        this.setState({ imageType: imageType });
    }

    handleChangeCustomerService = (value) => {
        console.log(value)
    }

    getImageBeforeAfter = (data) => {
        console.log('imageBeforeAfter', data);
        this.setState({ imageBeforeAfter: data });
    }

    render() {
        const { visible, media_categories_active, customerActiveList, currentItem, branchActiveList, customer_services } = this.props;
        const { isLoading, imageType, isResetUpload, showCustomer, showBaranch, currentImages, profileData, media } = this.state;
        const { getFieldDecorator } = this.props.form;

        // console.log("customer_services", customer_services)

        return (
            <Drawer
                visible={visible}
                title={currentItem ? "Cập nhật" : "Thêm mới"}
                width={isMobile ? '100%' : '70%'}
                onClose={() => this.onClose()}
                maskClosable={false}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button key="back" onClick={() => this.onClose()}>
                            Huỷ
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            loading={isLoading}
                            key="submit"
                            type="primary"
                            onClick={(e) => this.submit(e)}
                        >
                            Lưu
                        </Button>
                    </div>
                }
            >
                {visible ? (
                    <Form layout='vertical'>
                        <>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Danh mục">
                                        {getFieldDecorator('categories_id', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn',
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.categories_id : null
                                        })(
                                            <BaseSelect
                                                options={media_categories_active || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                placeholder="Chọn một bản ghi"
                                                className="w-100"
                                                onChange={this.onChangeCategory}
                                                disabled={currentItem ? true : false}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {showCustomer ? (
                                <>
                                    {currentItem ? (<>
                                        <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <Form.Item label="Khách hàng">
                                                    <Text>
                                                        <UserOutlined /> {currentItem.customer?.full_name} - {currentItem.customer?.code}
                                                    </Text>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Form.Item label="Loại ảnh">
                                                    <Text>
                                                        <FileImageOutlined /> {currentItem?.image_type_text}
                                                    </Text>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>) : (
                                        <>
                                            <Row gutter={16}>
                                                <Col xs={24} md={24}>
                                                    <Form.Item label="Khách hàng">
                                                        {getFieldDecorator("customer_id", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Vui lòng chọn!",
                                                                },
                                                            ],
                                                            initialValue: currentItem ? currentItem.customer_id : null
                                                        })(
                                                            <BaseSelect
                                                                options={customerActiveList || []}
                                                                optionValue="id"
                                                                optionLabel="full_name"
                                                                additionalLabel="phone"
                                                                additionalLabel2="code"
                                                                allowClear
                                                                showSearch
                                                                placeholder="Nhập mã, tên, 3 số cuối sđt của khách hàng"
                                                                onSearch={(val) => this.onSearchCustomer(val)}
                                                                onScrollEnd={(e) => this.handleScrollCustomer(e)}
                                                                onChange={(value) => this.handleChangeCustomer(value)}
                                                                className="w-100"
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col xs={24} md={24}>
                                                    <Form.Item label="Loại ảnh">
                                                        {getFieldDecorator("image_type", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Vui lòng chọn!",
                                                                },
                                                            ],
                                                            initialValue: currentItem ? currentItem.image_type : 'profile'
                                                        })(
                                                            <BaseRadioList
                                                                options={[
                                                                    { label: "Ảnh hồ sơ", value: 'profile' },
                                                                    { label: "Ảnh before, after", value: 'before_after' }
                                                                ]}
                                                                onChange={(e) => this.handleChangeImageType(e)}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    )}


                                    {imageType === 'profile' ? (
                                        <ProfileForm
                                            //currentData={profileData}
                                            onChangeData={(data) => this.getImageProfile(data)}
                                        />
                                    ) : (
                                        <>
                                            <Col xs={24} md={24}>
                                                <Form.Item label='Dịch vụ'>
                                                    {getFieldDecorator("customer_service_id", {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Vui lòng chọn!",
                                                            },
                                                        ],
                                                        initialValue: currentItem ? currentItem.customer_service_id : null
                                                    })(
                                                        <BaseSelect
                                                            options={customer_services || []}
                                                            optionValue="id"
                                                            optionLabel="service_name"
                                                            allowClear
                                                            showSearch
                                                            placeholder="Chọn một bản ghi"
                                                            className="w-100"
                                                            onChange={(value) => this.handleChangeCustomerService(value)}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <BeforeAfterForm
                                                //currentData={currentItem}
                                                onChangeData={(data) => this.getImageBeforeAfter(data)}
                                            />
                                        </>
                                    )}
                                </>
                            ) : null
                            }
                            {
                                showBaranch ? (
                                    <Row gutter={16}>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Cơ sở">
                                                {getFieldDecorator('branch_id', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.branch_id : null
                                                })(
                                                    <BaseSelect
                                                        options={branchActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        placeholder="Chọn một bản ghi"
                                                        className="w-100"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ) : null
                            }
                        </>
                        <Row gutter={16}>
                            {!showCustomer && (
                                <Col xs={24} md={24}>
                                    <Form.Item label="File ảnh">
                                        <ImageSelector
                                            multiple={true}
                                            maxCount={50}
                                            onChangeData={this.onChange}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: _newLine(currentItem?.note)
                                    })(
                                        <Input.TextArea rows={4} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form >
                ) : null
                }
            </Drawer >
        );
    }
}

const mapStateToProps = state => {
    return {
        media_categories_active: state.MediaCategoriesReducer.media_categories_active,
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active,
        branchActiveList: state.BranchReducer.branchActiveList,
        customer_services: state.CustomerServicesReducer.customer_services,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createMedia: (data) => dispatch(createMedia(data)),
        getMediaCategoriesActive: () => dispatch(getMediaCategoriesActive()),
        getListCustomerActive: (filter) => dispatch(getListCustomerActive(filter)),
        updateMedia: (id, data) => dispatch(updateMedia(id, data)),
        getBranchActive: () => dispatch(getBranchActive()),
        getMediaCustomer: (id) => dispatch(getMediaCustomer(id)),
        getCustomerServices: (id) => dispatch(getCustomerServices(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "MediaForm" })(MediaForm));
