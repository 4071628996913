module.exports = {
    "admin.user.list": "Xem danh sách",
    "admin.user.create": "Thêm mới",
    "admin.user.detail": "Xem chi tiết",
    "admin.user.update": "Cập nhật thông tin",
    "admin.user.remove": "Xoá",

    "admin.permissions.list": "Xem danh sách quyền",
    "admin.permissions.update": "Phân quyền",
    "admin.permissions.get_by_role": "Lấy danh sách quyền theo vai trò",

    "admin.config.update": "Cấu hình email hệ thống",

    "admin.services.list": "Xem danh sách",
    "admin.services.create": "Thêm mới",
    "admin.services.update": "Cập nhật",
    "admin.services.remove": "Xóa",

    "admin.customer_class.list": "Xem danh sách",
    "admin.customer_class.create": "Thêm mới",
    "admin.customer_class.update": "Cập nhật",
    "admin.customer_class.remove": "Xóa",

    "admin.relationship_types.list": "Xem danh sách",
    "admin.relationship_types.create": "Thêm mới",
    "admin.relationship_types.update": "Cập nhật",
    "admin.relationship_types.remove": "Xóa",

    "admin.customer_status.list": "Xem danh sách",
    "admin.customer_status.create": "Thêm mới",
    "admin.customer_status.update": "Cập nhật",
    "admin.customer_status.remove": "Xóa",

    "admin.customer_interactive_status.list": "Xem danh sách",
    "admin.customer_interactive_status.create": "Thêm mới",
    "admin.customer_interactive_status.update": "Cập nhật",
    "admin.customer_interactive_status.remove": "Xóa",

    "admin.customer_interactive_type.list": "Xem danh sách",
    "admin.customer_interactive_type.create": "Thêm mới",
    "admin.customer_interactive_type.update": "Cập nhật",
    "admin.customer_interactive_type.remove": "Xóa",

    "admin.customer.list": "Xem danh sách",
    "admin.customer.create": "Thêm mới",
    "admin.customer.import_excel": "Import từ excel",
    "admin.customer.detail": "Xem chi tiết",
    "admin.customer.update": "Cập nhật",
    "admin.customer.remove": "Xóa",
    "admin.customer.phonenumber": "Xem số điện thoại",
    "admin.customer.voicecall": "Gọi điện thoại cho khách",
    "admin.customer.create_from_chat": "Tạo khách hàng từ form chat",
    "admin.customer.update_from_chat": "Cập nhật khách hàng từ form chat",
    "admin.customer.update_media": "Cập nhật ảnh tình trạng hiện tại",
    "admin.customer.transfer_to_staff": "Giao khách hàng cho telesale",
    "admin.customer.payment_history": "Xem lịch sử thanh toán",
    "admin.customer.input_pin_code": "Hiển thị form nhập mã pin xem SĐT",
    "admin.customer.phonenumber_update": "Cập nhật số điện thoại",
    "admin.customer.update_image_profile": "Cập nhật ảnh hồ sơ",
    "admin.customer.remove_image_profile": "Xóa ảnh hồ sơ",
    "admin.customer.export": "Xuất file",

    "admin.customer_services.list": "Xem danh sách",
    "admin.customer_services.create": "Tạo mới",
    "admin.customer_services.update": "Cập nhật",
    "admin.customer_services.remove": "Xóa",
    "admin.customer_services.detail": "Xem chi tiết",
    "admin.customer_services.create_exam_visit": "Thêm lần khám",
    "admin.customer_services.update_exam_visit": "Cập nhật lần khám",
    "admin.customer_services.exam_visit_remove": "Xóa lần khám",
    "admin.customer_services.export": "Xuất file",
    // "admin.customer_services.require_input_consultant_directly": "Yêu cầu nhập nv tư vấn trực tiếp",
    // "admin.customer_services.require_input_examination_visit": "Yêu cầu nhập lần khám",

    "admin.customer_source.list": "Xem danh sách",
    "admin.customer_source.create": "Thêm mới",
    "admin.customer_source.update": "Cập nhật",
    "admin.customer_source.remove": "Xóa",

    "admin.page_manager.list": "Danh sách trang",
    "admin.page_manager.connect_facebook": "Thực hiện kết nối facebook",
    "admin.page_manager.update": "Cập nhật trang",
    "admin.page_manager.update_status": "Kích hoạt trang",

    "admin.branch.list": "Xem danh sách",
    "admin.branch.create": "Thêm mới",
    "admin.branch.detail": "Xem chi tiết",
    "admin.branch.update": "Cập nhật",
    "admin.branch.remove": "Xóa",
    "admin.branch.remove_image": "Xóa ảnh cơ sở",

    "admin.role_group.list": "Xem danh sách",
    "admin.role_group.create": "Thêm mới",
    "admin.role_group.update": "Cập nhật",
    "admin.role_group.remove": "Xóa",

    "admin.roles.list": "Xem danh sách",
    "admin.roles.create": "Thêm mới",
    "admin.roles.update": "Cập nhật",
    "admin.roles.remove": "Xóa",

    "admin.customer_schedule.list": "Xem danh sách",
    "admin.customer_schedule.create": "Thêm mới",
    "admin.customer_schedule.detail": "Xem chi tiết",
    "admin.customer_schedule.update": "Cập nhật",
    "admin.customer_schedule.remove": "Xóa",
    "admin.customer_schedule.update_status": "Cập nhật trạng thái lịch hẹn",
    "admin.customer_schedule.transfer_to_staff": "Giao lich hẹn cho nhân viên tư vấn",
    "admin.customer_schedule.change_schedule": "Chuyển lịch hẹn",

    "admin.list_diseases.list": "Xem danh sách",
    "admin.list_diseases.create": "Thêm mới",
    "admin.list_diseases.update": "Cập nhật",
    "admin.list_diseases.remove": "Xóa",

    "admin.blood_type.list": "Xem danh sách",
    "admin.blood_type.create": "Thêm mới",
    "admin.blood_type.update": "Cập nhật",
    "admin.blood_type.remove": "Xóa",

    "admin.voucher.list": "Xem danh sách",
    "admin.voucher.create": "Thêm mới",
    "admin.voucher.detail": "Xem chi tiết",
    "admin.voucher.update": "Cập nhật",
    "admin.voucher.remove": "Xóa",
    "admin.voucher.apply_customer": "Thêm voucher cho khách",
    "admin.voucher.customer_update": "Cập nhật voucher của khách",
    "admin.voucher.voucher_remove": "Xóa voucher của khách",

    "admin.order.list": "Xem danh sách",
    "admin.order.create": "Thêm mới",
    "admin.order.detail": "Xem chi tiết",
    "admin.order.update": "Cập nhật",
    "admin.order.remove": "Xóa",

    "admin.product_warehouse.list": "Xem danh sách",
    "admin.product_warehouse.create": "Thêm mới",
    "admin.product_warehouse.update": "Cập nhật",
    "admin.product_warehouse.remove": "Xóa",

    "admin.product_group.list": "Xem danh sách",
    "admin.product_group.create": "Thêm mới",
    "admin.product_group.update": "Cập nhật",
    "admin.product_group.remove": "Xóa",

    "admin.product.list": "Xem danh sách",
    "admin.product.detail": "Xem chi tiết",
    "admin.product.update": "Cập nhật",
    "admin.product.remove": "Xóa",

    "admin.product_receipt_import.list": "Xem danh sách",
    "admin.product_receipt_import.create": "Thêm mới",
    "admin.product_receipt_import.detail": "Xem chi tiết",
    'admin.product_receipt_import.update': "Cập nhật",
    "admin.product_receipt_import.export": "Xuất file excel",
    'admin.product_receipt_import.remove': "Xóa",

    "admin.product_receipt_export.list": "Xem danh sách",
    "admin.product_receipt_export.create": "Thêm mới",
    "admin.product_receipt_export.update": "Cập nhật",
    "admin.product_receipt_export.detail": "Xem chi tiết",
    "admin.product_receipt_export.export": "Xuất file excel",
    "admin.product_receipt_export.remove": "Xóa",

    'admin.product_supplier.list': "Xem danh sách",
    'admin.product_supplier.create': "Thêm mới",
    'admin.product_supplier.update': "Cập nhật",
    'admin.product_supplier.remove': "Xóa",

    'admin.unit.list': "Xem danh sách",
    'admin.unit.create': "Thêm mới",
    'admin.unit.update': "Cập nhật",
    'admin.unit.remove': "Xóa",

    'admin.product_status.list': "Xem danh sách",
    'admin.product_status.create': "Thêm mới",
    'admin.product_status.update': "Cập nhật",
    'admin.product_status.remove': "Xóa",

    "admin.guide.list": "Xem danh sách",
    "admin.guide.create": "Thêm mới",
    "admin.guide.detail": "Xem chi tiết",
    "admin.guide.update": "Cập nhật",
    "admin.guide.remove": "Xóa",

    "admin.work_report.list": "Xem danh sách",
    "admin.work_report.create": "Tạo mới",
    "admin.work_report.update": "Cập nhật",
    "admin.work_report.detail": "Xem chi tiết",
    "admin.work_report.remove": "Xóa",

    "admin.telegram.list": "Xem danh sách",
    "admin.telegram.create": "Tạo mới",
    "admin.telegram.detail": "Chi tiết",
    "admin.telegram.update": "Cập nhật",
    "admin.telegram.remove": "Xóa",

    "admin.lookup_customer.list": "Xem danh sách",
    "admin.lookup_customer.transfer_to_staff": "Giao khách hàng cho telesale",

    "admin.customer_telesale.list": "Xem danh sách",
    "admin.customer_telesale.detail": "Xem chi tiết",
    "admin.customer_telesale.update": "Cập nhật",
    "admin.customer_telesale.update_result": "Cập nhật kết quả tư vấn",
    "admin.customer_telesale.remove": "Xóa",
    "admin.customer_telesale.telesale_again": "Giao cho nhân viên telesale lại",

    "admin.customer_consultant_direct.list": "Xem danh sách",
    "admin.customer_consultant_direct.detail": "Xem chi tiết",
    "admin.customer_consultant_direct.update": "Cập nhật",
    "admin.customer_consultant_direct.update_result": "Cập nhật kết quả tư vấn",
    "admin.customer_consultant_direct.remove": "Xóa",

    "admin.customer_potential.list": "Xem danh sách",
    "admin.customer_potential.create": "Thêm mới",
    "admin.customer_potential.import_excel": "Import excel",
    "admin.customer_potential.phonenumber": "Xem số điện thoại",
    "admin.customer_potential.voicecall": "Gọi điện thoại cho khách",
    "admin.customer_potential.update": "Cập nhật",
    "admin.customer_potential.remove": "Xóa",
    "admin.customer_potential.input_pin_code": "Hiển thị form nhập mã pin xem SĐT",

    "admin.cash_receipt.list": "Xem danh sách",
    "admin.cash_receipt.create": "Tạo phiếu",
    "admin.cash_receipt.detail": "Xem chi tiết",
    "admin.cash_receipt.update": "Cập nhật",
    "admin.cash_receipt.remove": "Xóa",
    "admin.cash_receipt.update_status": "Cập nhật trạng thái phiếu",
    "admin.cash_receipt.import_excel": "Import từ excel",

    "admin.cash_book.list": "Xem danh sách",
    // "admin.cash_book.create": "Tạo mới",
    // "admin.cash_book.detail": "Xem chi tiết",
    // "admin.cash_book.update": "Cập nhật",
    // "admin.cash_book.remove": "Xóa",

    "admin.payment_voucher.list": "Xem danh sách",
    "admin.payment_voucher.create": "Tạo phiếu",
    "admin.payment_voucher.detail": "Xem chi tiết",
    "admin.payment_voucher.update": "Cập nhật",
    "admin.payment_voucher.remove": "Xóa",
    "admin.payment_voucher.update_status": "Cập nhật trạng thái phiếu",

    "admin.reward_spin.list": "Xem giải thưởng",
    "admin.reward_spin.remove": "Xóa",
    "admin.reward_spin.create": "Quay",
    "admin.reward_spin.config": "Cấu hình",
    "admin.reward_spin.update": "Cập nhật",
    "admin.reward_spin.customer_has_list": "Xem khách hàng đã quay",
    "admin.reward_spin.remove_customer_has": "Xóa khách hàng đã quay",

    "admin.posts.list": "Xem danh sách",
    "admin.posts.create": "Tạo mới",
    "admin.posts.update": "Cập nhật",
    "admin.posts.remove": "Xóa",

    "admin.view_phonenumber.list": "Xem danh sách",
    "admin.view_phonenumber.phonenumber": "Xem số điện thoại",
    "admin.view_phonenumber.remove": "Xóa",

    "admin.project.list": "Xem danh sách",
    "admin.project.create": "Tạo mới",
    "admin.project.update": "Cập nhật",
    "admin.project.clone": "Nhân bản",
    "admin.project.remove": "Xóa",

    "admin.project_based_job.list": "Xem danh sách",
    "admin.project_based_job.create": "Tạo mới",
    "admin.project_based_job.update": "Cập nhật",
    "admin.project_based_job.detail": "Xem chi tiết",
    "admin.project_based_job.update_result": "Cập nhật kết quả",
    "admin.project_based_job.update_status": "Cập nhật tình trạng",
    "admin.project_based_job.assign": "Giao việc cho nhân viên",
    "admin.project_based_job.remove": "Xóa",

    "admin.messenger.thread": "Danh sách cuộc trò chuyện",
    "admin.messenger.message": "Xem chi tiết tin nhắn",
    "admin.messenger.send_message": "Gửi tin nhắn",
    "admin.messenger.update_customer": "Cập nhật khách hàng",
    "admin.messenger.customer_info": "Xem thông tin khách hàng",
    "admin.messenger.delete_comment": "Xóa bình luận",
    "admin.messenger.hide_comment": "Ẩn/Hiện bình luận",

    "admin.functional_area.list": "Xem danh sách",
    "admin.functional_area.create": "Tạo mới",
    "admin.functional_area.update": "Cập nhật",
    "admin.functional_area.remove": "Xóa",

    "admin.functional.list": "Xem danh sách",
    "admin.functional.create": "Tạo mới",
    "admin.functional.update": "Cập nhật",
    "admin.functional.detail": "Xem chi tiết",
    "admin.functional.remove": "Xóa",

    "admin.jobs.list": "Xem danh sách",
    "admin.jobs.create": "Tạo mới",
    "admin.jobs.update": "Cập nhật",
    "admin.jobs.detail": "Xem chi tiết",
    "admin.jobs.remove": "Xóa",

    "admin.shift_scheduling.list": "Xem ca làm việc",
    "admin.shift_scheduling.create": "Tạo mới",
    "admin.shift_scheduling.update": "Cập nhật",
    "admin.shift_scheduling.remove": "Xóa",

    "admin.customer_medical.create": "Cập nhật",

    "admin.history.list": "Xem danh sách",
    "admin.history.detail": "Xem chi tiết",
    "admin.history.remove": "Xóa",

    "admin.customer_doctor_assistant.list": "Tra cứu khách hàng",

    "admin.doctor_assistant_status.list": "Xem danh sách",
    "admin.doctor_assistant_status.assign": "Giao bênh nhân",

    "admin.dental_patient_cases.patient_waiting": "Xem danh sách",

    "admin.card_issued_count.list": "Xem danh sách",
    "admin.card_issued_count.create": "Tạo mới",
    "admin.card_issued_count.update": "Cập nhật",
    "admin.card_issued_count.detail": "Xem chi tiết",
    "admin.card_issued_count.remove": "Xóa",

    "admin.card_release_tracking.list": "Xem danh sách",
    "admin.card_release_tracking.create": "Tạo mới",
    "admin.card_release_tracking.update": "Cập nhật",
    "admin.card_release_tracking.detail": "Xem chi tiết",
    "admin.card_release_tracking.remove": "Xóa",

    "admin.card_type.list": "Xem danh sách",
    "admin.card_type.create": "Tạo mới",
    "admin.card_type.update": "Cập nhật",
    "admin.card_type.remove": "Xóa",

    "admin.card_pending_customers.list": "Xem danh sách",
    "admin.card_pending_customers.assign_to_staff": "Giao cho nhân viên",
    "admin.card_pending_customers.export": "Xuất excel",

    "admin.customer_data.lookup": "Tra cứu khách hàng",
    "admin.customer_data.create": "Thêm mới",
    "admin.customer_data.update": "Cập nhật",

    "admin.media_categories.list": "Xem danh sách",
    "admin.media_categories.create": "Thêm mới",
    "admin.media_categories.update": "Cập nhật",
    "admin.media_categories.remove": "Xóa",

    "admin.media.list": "Xem danh sách",
    "admin.media.create": "Thêm mới",
    "admin.media.remove": "Xóa",
    "admin.media.update": "Cập nhật",

    "admin.document_categories.list": "Xem danh sách",
    "admin.document_categories.create": "Thêm mới",
    "admin.document_categories.update": "Cập nhật",
    "admin.document_categories.remove": "Xóa",

    "admin.documents.list": "Xem danh sách",
    "admin.documents.create": "Thêm mới",
    "admin.documents.update": "Cập nhật",
    "admin.documents.remove": "Xóa",

    // card title
    "admin.user": "Nhân viên",
    "admin.config": "Cài đặt chung",
    "admin.services": "Danh mục dịch vụ",
    "admin.customer_class": "Hạng khách hàng",
    "admin.relationship_types": "Quan hệ gia đình",
    "admin.customer_status": "Trạng thái khách hàng",
    "admin.customer_interactive_status": "Trạng thái tương tác",
    "admin.customer_interactive_type": "Loại tương tác",
    "admin.customer": "Khách hàng",
    "admin.page_manager": "Danh sách fanpage",
    "admin.branch": "Cơ sở",
    "admin.role_group": "Nhóm vai trò",
    "admin.roles": "Vai trò",
    "admin.customer_services": "Dịch vụ",
    "admin.customer_source": "Nguồn khách hàng",
    "admin.customer_schedule": "Lịch hẹn",
    "admin.list_diseases": "Danh sách bệnh",
    "admin.blood_type": "Nhóm máu",
    "admin.voucher": "Voucher",
    "admin.order": "Đơn hàng",
    "admin.product_group": "Nhóm sản phẩm",
    "admin.product": "Sản phẩm",
    "admin.product_receipt_import": "Phiếu nhập",
    "admin.product_supplier": "Nhà cung cấp",
    "admin.product_warehouse": "Danh sách kho",
    "admin.product_receipt_export": "Phiếu xuất",
    "admin.unit": "Đơn vị tính",
    "admin.product_status": "Tình trạng sản phẩm",
    "admin.guide": "Hướng dẫn sử dụng",
    "admin.work_report": "Báo cáo",
    "admin.telegram": "Cài đặt telegram",
    "admin.lookup_customer": "Tra cứu khách hàng",
    "admin.customer_telesale": "Telesale",
    "admin.customer_consultant_direct": "Tư vấn trực tiếp",
    "admin.customer_potential": "Khách hàng tiềm năng",
    "admin.cash_receipt": "Phiếu thu",
    "admin.payment_voucher": "Phiếu chi",
    "admin.reward_spin": "Quay thưởng",
    "admin.posts": "Quản lý bài viết",
    "admin.view_phonenumber": "Lịch sử xem số điện thoại",
    "admin.project": "Dự án",
    "admin.project_based_job": "Công việc",
    "admin.messenger": "Messenger",
    "admin.functional_area": "Khu vực chức năng",
    "admin.functional": "Chức năng của khu vực",
    "admin.jobs": "Công việc",
    "admin.shift_scheduling": "Ca làm việc",
    "admin.customer_medical": "Thông tin y tế",
    "admin.history": "Lịch sử thay đôi",
    "admin.cash_book": "Sổ quỹ",
    "admin.customer_doctor_assistant": "Khách hàng",
    "admin.doctor_assistant_status": "Danh sách bác sĩ, phụ tá",
    "admin.dental_patient_cases": "Bệnh nhân đang chờ",
    "admin.card_issued_count": "Số lượng thẻ được phát đi",
    "admin.card_release_tracking": "Theo dõi phát hành thẻ",
    "admin.card_type": "Loại thẻ",
    "admin.card_pending_customers": "Khách hàng chờ làm thẻ",
    "admin.customer_data": "Dữ liệu khách hàng",
    "admin.media_categories": "Danh mục media",
    "admin.media": "Media",
    "admin.document_categories": "Danh mục văn bản",
    "admin.documents": "Văn bản"
};
