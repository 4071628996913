import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Space, Row, Col, Spin, Input } from 'antd';
import { } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import UploadImage from './UploadImage';
import { NotificationManager } from 'react-notifications';
import CurrentImage from './CurrentImage';
import { updateMedia, createMedia, getMediaCustomer } from '../../redux/actions/MediaAction';
import { checkPermission } from '../../utils/permission';
import { _newLine } from '../../utils/helpers';

class CustomerMediaProfileForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isLoadingData: true,
            portraitPhoto: [],
            consultationForm: [],
            medicalRecordBook: [],
            XRayImage: [],
            receipt: [],
            document: [],
            isResetUpload: true,
            currentConsultationForm: [],
            currentMedicalRecordBook: [],
            currentDocument: [],
            currentPortraitPhoto: [],
            currentReceipt: [],
            currentXRayImage: [],
            currentCustomerFamily: [],
            media: null
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentCustomer !== prevProps.currentCustomer) {
            this.loadData();
        }
    }

    updateDocumentState(data, key, stateKey) {
        if (!data) return;
        const documents = data[key]?.images;
        const isArray = Array.isArray(documents);
        this.setState({ [stateKey]: isArray ? documents : [] });
    }

    customDataImageUpload = (data = [], oldData = []) => {
        // Gộp cả hai mảng lại với nhau
        const mergedData = [...oldData, ...data];

        // Đặt lại giá trị cho trường id theo thứ tự tuần tự
        const updatedData = mergedData.map((item, index) => ({
            ...item,
            id: index + 1, // Đặt lại id từ 1 đến tổng số phần tử
        }));

        return updatedData;
    };

    async loadData() {
        this.setState({ isLoadingData: true })
        await this.props.getMediaCustomer(this.props.currentCustomer?.id).then(res => {
            //console.log(res);
            this.setState({ media: res });
            const data = res.data;
            this.updateDocumentState(data, 'consultation_form', 'currentConsultationForm');
            this.updateDocumentState(data, 'medical_record_book', 'currentMedicalRecordBook');
            this.updateDocumentState(data, 'orther_document', 'currentDocument');
            this.updateDocumentState(data, 'portrait_photo', 'currentPortraitPhoto');
            this.updateDocumentState(data, 'receipt', 'currentReceipt');
            this.updateDocumentState(data, 'x_ray_image', 'currentXRayImage');
            this.setState({ isLoadingData: false })
        }).catch(err => {
            this.setState({ isLoadingData: false })
        });
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const {
                        portraitPhoto,
                        consultationForm,
                        medicalRecordBook,
                        XRayImage,
                        receipt,
                        document,
                        currentConsultationForm,
                        currentMedicalRecordBook,
                        currentDocument,
                        currentPortraitPhoto,
                        currentReceipt,
                        currentXRayImage,
                        media
                    } = this.state;

                    values.customer_images = {
                        profile_portrait_photo: this.customDataImageUpload(portraitPhoto, currentPortraitPhoto),
                        profile_consultation_form: this.customDataImageUpload(consultationForm, currentConsultationForm),
                        profile_medical_record_book: this.customDataImageUpload(medicalRecordBook, currentMedicalRecordBook),
                        profile_orther_document: this.customDataImageUpload(document, currentDocument),
                        //profile_receipt: this.customDataImageUpload(receipt, currentReceipt),
                        profile_x_ray_image: this.customDataImageUpload(XRayImage, currentXRayImage)
                    };
                    values.categories_id = 1;  // 1: Ảnh khách hàng
                    values.customer_id = this.props.currentCustomer?.id;
                    values.image_type = 'profile';

                    console.log("values: ", values)
                    await this.props.createMedia(values);
                    this.props.reloadData();
                    this.onClose();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.props.reloadData();
        this.loadData();
        this.setState({
            isLoading: false,
            portraitPhoto: [],
            consultationForm: [],
            medicalRecordBook: [],
            XRayImage: [],
            receipt: [],
            document: [],
            isResetUpload: true,
            media: null
        });
    }

    onChangeUpload = (stateKey, data) => {
        this.setState({ isResetUpload: false, [stateKey]: data });
        NotificationManager.success('Tải ảnh thành công');
    }

    // Sử dụng hàm onChangeUpload cho từng trạng thái cụ thể
    onChangePortraitPhoto = (data) => this.onChangeUpload('portraitPhoto', data);
    onChangeConsultationForm = (data) => this.onChangeUpload('consultationForm', data);
    onChangeMedicalRecordBook = (data) => this.onChangeUpload('medicalRecordBook', data);
    onChangeXRayImage = (data) => this.onChangeUpload('XRayImage', data);
    onChangeReceipt = (data) => this.onChangeUpload('receipt', data);
    onChangeDocument = (data) => this.onChangeUpload('document', data);

    getImages(stateKey, data) {
        this.setState({ [stateKey]: data })
    }

    render() {
        var {
            isResetUpload,
            isLoading,
            currentConsultationForm,
            currentMedicalRecordBook,
            currentDocument,
            currentPortraitPhoto,
            currentReceipt,
            currentXRayImage,
            isLoadingData,
            media
        } = this.state;
        var { visible, currentCustomer } = this.props;

        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={`Hồ sơ: ${currentCustomer ? currentCustomer?.full_name : ""} - ${currentCustomer?.code}`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            {
                                checkPermission('customer', 'update_image_profile') ? (
                                    <Button
                                        loading={isLoading}
                                        key="submit"
                                        type="primary"
                                        onClick={(e) => this.submit(e)}
                                    >
                                        Cập nhật
                                    </Button>
                                ) : null
                            }
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Spin tip="Loading..." spinning={isLoadingData}>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Ảnh chân dung" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        <CurrentImage currentImages={currentPortraitPhoto} getData={(data) => this.getImages('currentPortraitPhoto', data)} />
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangePortraitPhoto}
                                            isResetUpload={isResetUpload}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Phiếu tư vấn" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        <CurrentImage currentImages={currentConsultationForm} getData={(data) => this.getImages('currentConsultationForm', data)} />
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeConsultationForm}
                                            isResetUpload={isResetUpload}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Sổ khám" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        <CurrentImage currentImages={currentMedicalRecordBook} getData={(data) => this.getImages('currentMedicalRecordBook', data)} />
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeMedicalRecordBook}
                                            isResetUpload={isResetUpload}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Phim X Quang" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        <CurrentImage currentImages={currentXRayImage} getData={(data) => this.getImages('currentXRayImage', data)} />
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeXRayImage}
                                            isResetUpload={isResetUpload}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={24} md={12}>
                                    <Form.Item label="Phiếu thu" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        <CurrentImage currentImages={currentReceipt} getData={(data) => this.getImages('currentReceipt', data)} />
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeReceipt}
                                            isResetUpload={isResetUpload}
                                        />
                                    </Form.Item>
                                </Col> */}
                                <Col xs={24} md={12}>
                                    <Form.Item label="Giấy tờ khác" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                        <CurrentImage currentImages={currentDocument} getData={(data) => this.getImages('currentDocument', data)} />
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeDocument}
                                            isResetUpload={isResetUpload}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ghi chú">
                                        {getFieldDecorator('note', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: media ? _newLine(media.note) : null
                                        })(
                                            <Input.TextArea rows={4} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Spin>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        createMedia: (data) => dispatch(createMedia(data)),
        updateMedia: (id, data) => dispatch(updateMedia(id, data)),
        getMediaCustomer: (id) => dispatch(getMediaCustomer(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerMediaProfileForm' })(CustomerMediaProfileForm));
