import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Tag, Row, Col, Typography, Divider, Space, Image, Card } from 'antd'
import ReactHtmlParser from 'react-html-parser'
import { isMobile } from 'react-device-detect';
import { isValidDate } from '../../utils/helpers';

const { Paragraph, Text } = Typography;

class MediaDetail extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    render() {
        var { } = this.state;
        var { visible, currentItem } = this.props;

        const renderDocumentCard = (title, images) => (
            <Col xs={24} md={12} className="mb-2">
                <Card size="small" className="border-dashed">
                    <Col xs={24} md={24}>
                        <Text strong style={{ display: "block" }} className="mb-1">
                            {title}
                        </Text>
                        {Array.isArray(images) && images?.length > 0 ? (
                            <Image.PreviewGroup>
                                <Space wrap>
                                    {images.map((item, index) => (
                                        <Image
                                            key={index}
                                            height={50}
                                            width={50}
                                            src={item?.src}
                                            style={{
                                                border: '1px solid #dedede',
                                                objectFit: 'cover',
                                                objectPosition: 'center',
                                            }}
                                        />
                                    ))}
                                </Space>
                            </Image.PreviewGroup>
                        ) : null}
                    </Col>
                </Card>
            </Col>
        );

        const renderImageCard = (title, images) => (
            <Col xs={24} md={12} className="mb-2">
                <Card size="small" className="border-dashed">
                    <h6 className="h6-title">{title}</h6>
                    {Array.isArray(images) && images.length ? (
                        <Image.PreviewGroup>
                            <Space wrap>
                                {images.map((item, index) => (
                                    <Image
                                        key={index}
                                        height={50}
                                        width={50}
                                        src={item?.src}
                                        style={{
                                            border: '1px solid #dedede',
                                            objectFit: 'cover',
                                            objectPosition: 'center',
                                        }}
                                    />
                                ))}
                            </Space>
                        </Image.PreviewGroup>
                    ) : null}
                </Card>
            </Col>
        );


        const {
            consultation_form = {},
            medical_record_book = {},
            orther_document = {},
            portrait_photo = {},
            receipt = {},
            x_ray_image = {},
        } = currentItem?.data || {};

        return (
            <>
                <Drawer
                    title={`Chi tiết media: #${currentItem?.id}`}
                    visible={visible}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '50%'}
                >
                    {visible ? (
                        <div>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Danh mục">
                                            {currentItem?.category?.name}&nbsp;
                                            {currentItem.type == 'branch' && (<>({currentItem.branch.name})</>)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                {currentItem?.customer ? (
                                    <>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Tên khách hàng">
                                                    {currentItem?.customer?.full_name}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className='no-margin' />
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Mã khách hàng">
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: currentItem?.customer?.code,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <Tag>{currentItem?.customer?.code}</Tag>
                                                    </Paragraph>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className='no-margin' />
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Loại ảnh">
                                                    {currentItem?.image_type_text}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className='no-margin' />
                                        </Col>
                                    </>
                                ) : null}
                                {currentItem?.type == 'customer_service_image' ? (
                                    <>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Dịch vụ">
                                                    {currentItem?.service?.service_name}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className='no-margin' />
                                        </Col>
                                    </>
                                ) : null}
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người tạo">
                                            {currentItem?.created_user}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {isValidDate(currentItem?.created_at)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Cập nhật lần cuối">
                                            {currentItem?.updated_at ? (
                                                <>
                                                    {isValidDate(currentItem?.updated_at)}
                                                    &nbsp;by {currentItem?.updated_user}
                                                </>
                                            ) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className='no-margin' />
                                </Col>
                                <Col xs={24} md={24} className='mb-2'>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ghi chú">
                                            {ReactHtmlParser(currentItem?.note)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                {currentItem?.type === 'customer_profile' ? (
                                    <>
                                        {renderDocumentCard(
                                            consultation_form?.name,
                                            consultation_form?.images
                                        )}
                                        {renderDocumentCard(
                                            medical_record_book?.name,
                                            medical_record_book?.images
                                        )}
                                        {renderDocumentCard(
                                            orther_document?.name,
                                            orther_document?.images
                                        )}
                                        {renderDocumentCard(
                                            portrait_photo?.name,
                                            portrait_photo?.images
                                        )}
                                        {renderDocumentCard(
                                            receipt?.name,
                                            receipt?.images
                                        )}
                                        {renderDocumentCard(
                                            x_ray_image?.name,
                                            x_ray_image?.images
                                        )}
                                    </>
                                ) : currentItem?.type === 'customer_service_image' ? (
                                    <>
                                        {renderImageCard("Ảnh before", currentItem?.data?.images_before)}
                                        {renderImageCard("Ảnh after", currentItem?.data?.images_after)}
                                    </>
                                ) : (
                                    <Col xs={24} md={24}>
                                        <Divider className="no-margin" />
                                        <div className="mb-1 mt-2">
                                            <Text strong>Ảnh</Text>
                                        </div>
                                        <Image.PreviewGroup>
                                            <Space wrap>
                                                {currentItem?.data?.map((item, index) => {
                                                    return (
                                                        <Image
                                                            key={index}
                                                            height={100}
                                                            width={100}
                                                            src={item.src}
                                                            style={{
                                                                border: '1px solid #dedede',
                                                                objectFit: 'cover',
                                                                objectPosition: 'center',
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </Space>
                                        </Image.PreviewGroup>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    ) : null}
                </Drawer>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'MediaDetail' })(MediaDetail));
